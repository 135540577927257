.HowItWork {
  background-color: white;
  /* height: 100vh; */
  color: black;
  padding: 0;
}
.HowItWork-main-div {
  /* margin-top: -1rem; */
  /* margin-bottom: -2rem; */
}

.changingBackground {
  background-color: #fafafa !important;
}
.changingPaddingBottom {
  padding-bottom: 0;
}
