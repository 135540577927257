.customerModal input {
  /* border: 1px solid black; */
  box-shadow: 0 0 5px 4px rgb(207, 204, 204);
}
.customerModal .card div {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;
}
.customerModal .card div label {
  font-size: 1.3rem;
}
.customerModal .card div input {
  width: 70%;
}
.customerModal .modal-title {
  font-size: 1.7rem;
}
.customerModal button {
  font-size: 1.2rem;
}
