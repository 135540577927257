.logo-container {
    left: 50px;
    position: absolute;
    top: 20px;
    z-index: 3;
  
    .logo {
      overflow: hidden;
      border-radius: 50%;
      border: 1px solid #333333;
      width: 60px;
      float: left;
    }
  
    .brand {
      font-size: 18px;
      color: #FFF;
      line-height: 20px;
      float: left;
      margin-left: 10px;
      margin-top: 10px;
      width: 60px;
    }
  }
  
  .section .wizard-card {
    min-height: inherit;
  }
  
  .tim-row {
    margin-bottom: 20px;
  }
  
  .tim-white-buttons {
    background-color: #777777;
  }
  
  .title {
    margin-top: 30px;
    margin-bottom: 25px;
    min-height: 32px;
    font-weight: 500;
  
    &.text-center {
      margin-bottom: 50px;
    }
  }
  
  .tim-typo {
    padding-left: 25%;
    margin-bottom: 40px;
    position: relative;
  
    .tim-note {
      bottom: 10px;
      color: #c0c1c2;
      display: block;
      font-weight: 400;
      font-size: 13px;
      line-height: 13px;
      left: 0;
      margin-left: 20px;
      position: absolute;
      width: 260px;
    }
  }
  
  .tim-row {
    padding-top: 50px;
  
    h3 {
      margin-top: 0;
    }
  }
  
  .switch {
    margin-right: 20px;
  }
  
  #navbar-full .navbar {
    border-radius: 0 !important;
    margin-bottom: 15px;
    z-index: 2;
  }
  
  .space {
    height: 130px;
    display: block;
  }
  
  .space-110 {
    height: 110px;
    display: block;
  }
  
  .space-50 {
    height: 50px;
    display: block;
  }
  
  .space-70 {
    height: 70px;
    display: block;
  }
  
  .navigation-example {
    .img-src {
      background-attachment: scroll;
    }
  
    // background-image: url('../img/bg.jpg');
    background-position: center center;
    background-size: cover;
    margin-top: 0;
    min-height: 740px;
  }
  
  #notifications {
    background-color: #FFFFFF;
    display: block;
    width: 100%;
    position: relative;
  
    .alert-danger {
      margin-bottom: 0px;
    }
  }
  
  .tim-note {
    text-transform: capitalize;
  }
  
  #buttons .btn {
    margin: 0 0px 15px;
  }
  
  .space-100 {
    height: 100px;
    display: block;
    width: 100%;
  }
  
  .be-social {
    padding-bottom: 20px;
  
    /*     border-bottom: 1px solid #aaa; */
    margin: 0 auto 40px;
  }
  
  .txt-white {
    color: #FFFFFF;
  }
  
  .txt-gray {
    color: #ddd !important;
  }
  
  .parallax {
    width: 100%;
    height: 570px;
    display: block;
    background-attachment: fixed;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
  }
  
  .logo-container {
    &.logo-documentation {
      position: relative;
      top: 0;
      left: 0;
    }
  
    .logo {
      overflow: hidden;
      border-radius: 50%;
      border: 1px solid #333333;
      width: 50px;
      float: left;
    }
  
    .brand {
      font-size: 16px;
      line-height: 18px;
      float: left;
      margin-left: 10px;
      margin-top: 7px;
      width: 70px;
      height: 40px;
      text-align: left;
    }
  }
  
  .navbar-default .logo-container .brand {
    color: #999999;
  }
  
  .navbar-transparent .logo-container .brand {
    color: #FFFFFF;
  }
  
  .logo-container {
    .brand-material {
      font-size: 18px;
      margin-top: 15px;
      height: 25px;
      width: auto;
    }
  
    .logo img {
      width: 100%;
    }
  }
  
  .navbar-small .logo-container .brand {
    color: #333333;
  }
  
  .fixed-section {
    top: 90px;
    max-height: 80vh;
    overflow: scroll;
  
    ul li {
      list-style: none;
    }
  
    li a {
      font-size: 14px;
      padding: 2px;
      display: block;
      color: #666666;
  
      &.active {
        color: #00bbff;
      }
    }
  
    &.float {
      position: fixed;
      top: 100px;
      width: 200px;
      margin-top: 0;
    }
  }
  
  .parallax .parallax-image {
    width: 100%;
    overflow: hidden;
    position: absolute;
  
    img {
      width: 100%;
    }
  }
  
  @media (max-width: 768px) {
    .parallax .parallax-image {
      width: 100%;
      height: 640px;
      overflow: hidden;
  
      img {
        height: 100%;
        width: auto;
      }
    }
  }
  
  .separator {
    content: "Separator";
    color: #FFFFFF;
    display: block;
    width: 100%;
    padding: 20px;
  }
  
  .separator-line {
    background-color: #EEE;
    height: 1px;
    width: 100%;
    display: block;
  }
  
  .separator.separator-gray {
    background-color: #EEEEEE;
  }
  
  .social-buttons-demo .btn {
    margin-right: 5px;
    margin-bottom: 7px;
  }
  
  .img-container {
    width: 100%;
    overflow: hidden;
  
    img {
      width: 100%;
    }
  }
  
  .lightbox {
    img {
      width: 100%;
    }
  
    .modal-content {
      overflow: hidden;
    }
  
    .modal-body {
      padding: 0;
    }
  }
  
  @media screen and (min-width: 991px) {
    .lightbox .modal-dialog {
      width: 960px;
    }
  }
  
  @media (max-width: 768px) {
    /* .btn, .btn-morphing{
        margin-bottom: 10px;
    } */
  
    .parallax .motto {
      top: 170px;
      margin-top: 0;
      font-size: 60px;
      width: 270px;
    }
  }
  
  /*       Loading dots  */
  
  /*      transitions */
  
  .presentation .front {
    -webkit-transition: all .2s;
    -moz-transition: all .2s;
    -o-transition: all .2s;
    transition: all .2s;
  
    &:after, .btn {
      -webkit-transition: all .2s;
      -moz-transition: all .2s;
      -o-transition: all .2s;
      transition: all .2s;
    }
  }
  
  .logo-container {
    .logo, .brand {
      -webkit-transition: all .2s;
      -moz-transition: all .2s;
      -o-transition: all .2s;
      transition: all .2s;
    }
  }
  
  #images h4 {
    margin-bottom: 30px;
  }
  
  #javascriptComponents {
    padding-bottom: 0;
  
    .btn-raised {
      margin: 10px 5px;
    }
  }
  
  /*      layer animation          */
  
  .layers-container {
    display: block;
    margin-top: 50px;
    position: relative;
  
    img {
      position: absolute;
      width: 100%;
      height: auto;
      top: 0;
      left: 0;
      text-align: center;
    }
  }
  
  .section-black {
    background-color: #333;
  }
  
  .animate {
    transition: 1.5s ease-in-out;
    -moz-transition: 1.5s ease-in-out;
    -webkit-transition: 1.5s ease-in-out;
  }
  
  .navbar-default.navbar-small .logo-container .brand {
    color: #333333;
  }
  
  .navbar-transparent.navbar-small .logo-container .brand {
    color: #FFFFFF;
  }
  
  .navbar-default.navbar-small .logo-container .brand {
    color: #333333;
  }
  
  .sharing-area {
    margin-top: 80px;
  
    .btn {
      margin: 15px 4px 0;
      color: #FFFFFF;
  
      i {
        font-size: 18px;
        position: relative;
        top: 2px;
        margin-right: 5px;
      }
    }
  
    .btn-twitter {
      background-color: #55acee;
    }
  
    .btn-facebook {
      background-color: #3b5998;
    }
  
    .btn-google-plus {
      background-color: #dd4b39;
    }
  
    .btn-github {
      background-color: #333333;
    }
  }
  
  .section-thin, .section-notifications {
    padding: 0;
  }
  
  .section-navbars {
    padding-top: 0;
  }
  
  #navbar .navbar {
    border-radius: 0;
  }
  
  .section-tabs {
    background: #EEEEEE;
  }
  
  .section-pagination {
    padding-bottom: 0;
  }
  
  .section-download h4 {
    margin-bottom: 50px;
  }
  
  .section-examples {
    a {
      text-decoration: none;
    }
  
    h5 {
      margin-top: 30px;
    }
  }
  
  .components-page .wrapper > .header, .tutorial-page .wrapper > .header {
    height: 400px;
    padding-top: 100px;
    background-size: cover;
    background-position: center center;
  }
  
  .components-page .title, .tutorial-page .title {
    color: #FFFFFF;
  }
  
  .main {
    background: #FFFFFF;
    position: relative;
    z-index: 3;
  }
  
  .main-raised {
    margin: -60px 30px 0px;
    border-radius: 6px;
    box-shadow: 0 16px 24px 2px rgba(0, 0, 0, 0.14), 0 6px 30px 5px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2);
  }
  
  .header-filter {
    position: relative;
  
    &:before, &:after {
      position: absolute;
      z-index: 1;
      width: 100%;
      height: 100%;
      display: block;
      left: 0;
      top: 0;
      content: "";
    }
  
    &::before {
      background-color: rgba(0, 0, 0, 0.4);
    }
  
    .container {
      z-index: 2;
      position: relative;
    }
  }
  
  .section {
    padding: 70px 0;
    background-position: center center;
    background-size: cover;
  }
  
  /* .navbar {
    border: 0;
    border-radius: 3px;
    box-shadow: 0 10px 20px -12px rgba(0, 0, 0, 0.42), 0 3px 20px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2);
    padding: 10px 0;
  
    -webkit-transition: all 150ms ease 0s;
   -moz-transition: all 150ms ease 0s;
   -o-transition: all 150ms ease 0s;
   -ms-transition: all 150ms ease 0s;
   transition: all 150ms ease 0s;
  } */
  
  .navbar {
    .navbar-brand {
      position: relative;
      height: 50px;
      line-height: 30px;
      color: inherit;
      padding: 10px 15px;
  
      &:hover, &:focus {
        color: inherit;
        background-color: transparent;
      }
    }
  
    &.navbar-transparent {
      background-color: transparent;
      box-shadow: none;
      color: #fff;
      padding-top: 25px;
    }
  }
  
  .navbar-fixed-top {
    border-radius: 0;
  }
  
  .navbar .navbar-nav > li > a {
    .material-icons, .fa {
      font-size: 20px;
      max-width: 20px;
    }
  
    &:hover, &:focus {
      color: inherit;
      background-color: transparent;
    }
  
    &:not(.btn) .material-icons {
      margin-top: -3px;
      top: 0px;
      position: relative;
      margin-right: 3px;
    }
  
    color: inherit;
    padding-top: 15px;
    padding-bottom: 15px;
    font-weight: 400;
    font-size: 12px;
    text-transform: uppercase;
    border-radius: 3px;
  }
  
  /* .navbar, .navbar.navbar-default {
    background-color: #9c27b0;
    color: #FFFFFF;
  } */
  
  @media (max-width: 1199px) {
    .navbar {
      .navbar-brand {
        height: 50px;
        padding: 10px 15px;
      }
  
      .navbar-nav > li > a {
        padding-top: 15px;
        padding-bottom: 15px;
      }
    }
  }
  
  :placeholder {
    color: #Fff;
  }
  
  footer {
    &.footer-documentation {
      margin-top: 0;
      bottom: 0;
      text-shadow: none;
      color: inherit;
  
      li a {
        color: inherit;
  
        &:hover, &:focus {
          color: #89229b;
        }
      }
    }
  
    ul {
      margin-bottom: 0;
      padding: 0;
      list-style: none;
  
      li {
        display: inline-block;
  
        a {
          color: inherit;
          padding: 15px;
          font-weight: 500;
          font-size: 12px;
          text-transform: uppercase;
          border-radius: 3px;
          text-decoration: none;
          position: relative;
          display: block;
  
          &:hover {
            text-decoration: none;
          }
        }
  
        .btn {
          margin: 0;
        }
      }
  
      &.links-horizontal {
        &:first-child a {
          padding-left: 0;
        }
  
        &:last-child a {
          padding-right: 0;
        }
      }
  
      &.links-vertical li {
        display: block;
  
        a {
          padding: 5px 0;
        }
      }
    }
  
    .social-buttons {
      a, .btn {
        margin-top: 5px;
        margin-bottom: 5px;
      }
    }
  
    .footer-brand {
      float: left;
      height: 50px;
      padding: 15px 15px;
      font-size: 18px;
      line-height: 20px;
      margin-left: -15px;
  
      &:hover, &:focus {
        color: #3C4858;
      }
    }
  
    .copyright {
      padding: 15px 0;
      text-align: center;
  
      .material-icons {
        font-size: 18px;
        position: relative;
        top: 3px;
      }
    }
  
    .pull-center {
      display: inline-block;
      float: none;
    }
  }
  
  @media (max-width: 768px) {
    .footer .copyright {
      display: inline-block;
      text-align: center;
      padding: 10px 0;
      float: none !important;
      width: 100%;
    }
  
    .navbar.navbar-transparent {
      background-color: rgba(0, 0, 0, 0.4);
      padding-top: 10px;
      border-radius: 0;
    }
  
    .main-raised {
      margin-left: 10px;
      margin-right: 10px;
    }
  }