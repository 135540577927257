.building-inspection-card {
    display: flex;
    margin: 40px 0;
}
.building-inspection-form-input {
    display: flex;
}
.building-inspection-button {
    width: 30%;
}
.building-inspection-inner-card{
    box-shadow: -8px 8px 10px -1px #b5d7ef, 0px -8px 10px -1px #e8e9eb !important;
}
.building-pest-room-price{
    font-size: 3rem;
    color: #406d8d;
}
.building-pest-room-button{
    box-shadow: -6px 3px 0px 1px #269c4e !important;
    font-size: 1rem !important;

}

@media (max-width: 1200px) {
    .building-inspection-card {
        display: block;
    }
    .building-inspection-inner-card{
        margin: 2rem 6rem !important;
    }
}
@media (max-width: 500px) {
    .building-inspection-form-input {
        display: block;
        margin: 0 !important;
    }
    .building-inspection-form-inner {
        margin: 0 !important;
        width: 100% !important;
    }
    .building-inspection-form-input {
        width: 100% !important;
    }
    .building-inspection-button {
        display: block;
        width: 50%;
    }
}

.act:link{
    background-color: #269c4e;
}