.navbar {
  padding: 0 !important;
}
.sidebar-main {
  z-index: 10 !important;
}
.nav-main-logo {
  top: 10px !important;
}
.nav-dropdown {
  opacity: 0;
  position: relative;
  z-index: -1;
}
.header-items {
  margin-top: 13px;
}
.app-header .main-container {
  box-shadow: 0 -4px 8px 9px rgb(124 122 122 / 20%) !important;
}
.main-container a,
.nav-item {
  align-self: center;
  color: #008eb5;
  margin: 0 5px;
  justify-content: center;
}
.main-container a:hover,
.nav-item:hover {
  color: #1b6933;
  transform: translate(scaleX(0, 1));
}

.header-logo {
  display: none;
}
@media (max-width: 1203px) and (min-width: 992px) {
  .main-container {
    padding: 0.8rem;
  }
  .navbar-top-list {
    opacity: 0;
    position: relative;
    z-index: -2;
  }
  .nav-container-component-group {
    margin-top: 0;
  }
  .nav-main-logo {
    top: 20px !important;
  }
  .nav-dropdown {
    opacity: 1;
    z-index: 1;
    position: absolute !important;
    right: 10px;
    top: 20px;
  }

  .app-header.header .dropdown-menu {
    position: absolute !important;
    margin: 53px -9px !important;
  }
  .nav-component .nav-item {
    /* display: none !important; */
  }
}
@media (max-width: 993px) {
  .main-container {
    padding: 0.8rem;
  }
  .navbar-top-list {
    opacity: 0;
    position: relative;
    z-index: -2;
  }
  .nav-container-component-group {
    margin-top: 0;
  }
  .nav-main-logo {
    top: 20px !important;
  }
  .nav-dropdown {
    opacity: 1;
    z-index: 1;
    position: absolute !important;
    right: 10px;
    top: 20px;
  }
  .nav-component .nav-item {
    /* display: none !important; */
  }
  .app-wrapper {
    margin-top: 5rem !important ;
  }
  .header-logo {
    display: block;
  }
}
@media (max-width: 767px) {
  .nav-dropdown .dropdown-menu {
    position: absolute !important;
  }
}

@media (min-width: 300px) and (max-width: 520px) {
  .main-container {
    padding: 0 !important;
  }
}
@media (max-width: 480px) {
  .nav-dropdown {
    top: 7px;
  }
}
