.services {
  background-color: rgb(241 237 237 / 90%);
  padding: 3rem 0;
}
.service-span {
  border-bottom: 5px solid rgb(0, 142, 181);
  padding: 10px 60px;
  width: 10%;
  display: block;
  margin: auto;
  position: relative;
  top: -39px;
}
.heading-description {
  text-align: center;
  width: 50%;
  margin: -1rem auto 2rem auto;
  font-size: 20px;
}
.services-container {
  display: grid;
  grid-template-columns: repeat(4, auto);
  gap: 1rem;
  padding: 0 3rem;

  /* text-align: start;
  justify-content: center; */
  
}
.services-list {
  height: 39rem;

  /* margin: 1rem 2rem; */
  border-radius: 3px;
  background-color: hsl(0, 0%, 100%);
  overflow: hidden;
  box-shadow: 2px 2px 10px 2px rgb(173, 173, 173);
}
.services-list img {
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
  transition: transform 0.4s ease;
  width: 100%;
  height: 20rem;
  object-fit: cover;
}
.services-list:hover.services-list img {
  transform: scale(1.06);
  transform-origin: end;
}
.services-list-img {
  overflow: hidden;
  position: relative;
  z-index: 2;
}
.services-list h3 {
  padding: 2rem 5rem 0rem 2rem;
  font-weight: 600;
  color: var(--blueButton);
}
.services-list p {
  padding: 0rem 2rem 0rem 2rem;
  color: #262525;
}
.services-list ul {
  padding: 0rem 1rem 1rem 1rem;
}
.services-list ul li {
  color: black;
  display: flex;
  margin-bottom: 7px;
  font-size: 0.9rem;
}
.services-list .service-list-icon {
  font-weight: bold;
  font-size: 40px;
  color: rgb(43, 89, 103);
  background-color: white;
  padding: 10px;
  box-shadow: 2px 2px 10px 2px lightslategrey;
  position: relative;
  z-index: 3;
  border-radius: 30px;
  left: 20px;
  top: -10px;
}
.services-list:hover.services-list .service-list-icon {
  background-color: rgb(0, 142, 181);
  color: white;
  transform: scale(2);
  border: none;
  -webkit-transition: background-color 400ms linear;
  -ms-transition: background-color 400ms linear;
  transition: background-color 400ms linear;
  animation-name: service-icon;
  animation-duration: 1s;
  animation-timing-function: ease;
}
@keyframes service-icon {
  0% {
    margin-left: 0px;
  }
  50% {
    margin-left: 50px;
  }
}
@media (max-width: 1350px) {
  .services-list h3 {
    padding: 1rem 1rem 0rem 1rem;
  }
  .services-list p {
    padding: 0rem 1rem;
    color: #8f8f8f;
  }
  .services-list ul {
    padding: 0rem 1rem 1rem 1rem;
  }
}
@media (max-width: 1100px) {
  .services-container {
    display: grid;
    grid-template-columns: repeat(2, auto);
  }
  .services-list {
    width: 100%;
    margin: 1rem 0;
    /* height: 37rem; */
  }
  .services-list img {
    width: 100%;
  }
  .services-list ul {
    /* padding: 0rem 5rem 5rem 2rem; */
    margin-bottom: 1rem;
  }
}
@media (max-width: 650px) {
  .services-container {
    display: block;
    /* width: 80%; */
    padding: 0 1rem;
    gap: 0;
    margin: auto;
  }
  .heading-description {
    width: 80%;
  }
}
