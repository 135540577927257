.footer {
  display: flex;
  background-color: rgb(41, 41, 41) !important;
  padding: 4rem 4rem 4rem 6rem !important;
}
.footer-container {
  width: 33%;
  line-height: 50px;
  font-size: 20px;
}
.footer-container ul {
  color: white;
}
.footer-container ul li span {
  cursor: pointer;
  position: relative;
}
.footer-container ul li span:after {
  content: "";
  position: absolute;
  width: 100%;
  transform: scaleX(0);
  height: 2px;
  bottom: 0;
  left: 0;
  background-color: #0087ca;
  transform-origin: bottom right;
  transition: transform 0.4s ease-out;
}
.footer-container ul li span:hover:after {
  transform: scaleX(1);
  transform-origin: bottom left;
}
.footer-container ul li i {
  padding: 0.5rem;
  border: 2px solid #0087ca;
  border-radius: 30px;
  margin: 1rem;
  color: #0087ca;
  cursor: pointer;
}
.footer-container ul li i:hover {
  border: 2px solid white;
  color: #ffff;
}
.footer-page p {
  background-color: rgb(41, 41, 41);
  color: white;
  text-align: center;
  padding: 0.5rem;
  border-top: 1px solid white ;
}
@media (max-width: 1100px) {
  .footer {
    display: block;
  }
  .footer-container {
    width: 100%;
  }
  .footer-container ul .footer-social-icons {
    margin: 0 2rem;
  }
}
@media (max-width: 500px) {
  .footer {
    padding: 1rem !important;
  }
}