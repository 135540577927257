/* .button {
 transition: 0.2s;
} */
/* .button:hover {
 transform: translate(0, -10px);
 opacity: 0.9;
 -webkit-box-shadow: 0 8px 6px -6px rgb(73, 72, 72);
 -moz-box-shadow: 0 8px 6px -6px rgb(92, 90, 90);
 box-shadow: 0 8px 6px -6px rgb(131, 130, 130);
} */
.view-application-card {
  position: relative;
  /* width: 1160px; */
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  /* transform-style: preserve-3d; */
  /* perspective: 500px; */
  margin: auto;
}
.view-application-card .box {
  color: black;
  position: relative;
  width: 200px;
  height: 200px;
  box-shadow: 0 0 1rem 0 rgba(0, 0, 0, 0.8);
  overflow: hidden;
  transition: 0.5s;
  /* transform-style: preserve-3d; */
  overflow: hidden;
  margin-right: 15px;
  margin-top: 45px;
  font-size: 1.5rem;
  /* font-size: 2rem; */
  /* transition: all 0.3s ease; */

  background: inherit;
}
.view-application-card .box:before {
  content: "";
  position: absolute;
  background: inherit;
  z-index: -1;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(255, 255, 255, 0.75);
  color: black;
  /* box-shadow: inset 0 0 2000px rgba(248, 248, 248, 0.5); */
  /* background-color: white; */
  filter: blur(10px);

  margin: -20px;
}
.view-application-card:hover .box {
  /* transform: rotateY(25deg); */
  color: black;
}
/* .view-application-card .box:hover ~ .box {
   transform: rotateY(-25deg); 
} */
.view-application-card .box:hover {
  transform: rotateY(0deg) scale(1.25);
  z-index: 1;
  box-shadow: 0 25px 40px rgba(0, 0, 0, 0.5);
}
@media (min-width: 768px) and (max-width: 1000px) {
  .user-dashboard-header {
    font-size: 2.8rem !important;
  }
  .user-dashboard-start-application {
    font-size: 1.2rem !important;
  }
  .user-dashboard-view-application {
    font-size: 1.2rem !important;
  }
}
@media (min-width: 500px) and (max-width: 767px) {
  .user-dashboard-header {
    font-size: 2.2rem !important;
  }
  .user-dashboard-start-application {
    font-size: 1.2rem !important;
  }
  .user-dashboard-view-application {
    font-size: 1.2rem !important;
  }
}
@media (min-width: 300px) and (max-width: 499px) {
  .user-dashboard-header {
    font-size: 2rem !important;
    margin-top: 4rem;
    width: 100%;
  }
  .user-dashboard-start-application {
    font-size: 1.2rem !important;
  }
  .user-dashboard-view-application {
    font-size: 1.2rem !important;
  }
}
