/*Color variables*/
/*Gradient variables*/
/*white variables*/
/*black variables*/
/*shadow variables*/
a {
    color: #008eb5;
}

a:hover {
    color: #008eb5;
}

.bg-primary {
    background: #008eb5 !important;
}

a.bg-primary:hover,
a.bg-primary:focus {
    background-color: #008eb5 !important;
}

button.bg-primary:hover,
button.bg-primary:focus {
    background-color: #008eb5 !important;
}

/*--- gradient-backgrounds --*/
.bg-primary-gradient {
    background: linear-gradient(to bottom right, var(--primary-bg-color) 0%, var(--primary06) 100%) !important;
}

a.bg-primary-gradient:hover,
a.bg-primary-gradient:focus {
    background-color: var(--primary-bg-color) !important;
}

button.bg-primary-gradient:hover,
button.bg-primary-gradient:focus {
    background-color: var(--primary-bg-color) !important;
}

.border-primary {
    border-color: #8580c1 !important;
}

.text-primary {
    color: var(--primary-bg-color) !important;
}

a.text-primary:hover,
a.text-primary:focus {
    color: #4e12b6 !important;
}

.table-primary {
    background-color: #d2cdf9;
}

.table-primary>th,
.table-primary>td {
    background-color: #d2cdf9;
}

.table-hover .table-primary:hover {
    background-color: #b7cded;
}

.table-hover .table-primary:hover>td,
.table-hover .table-primary:hover>th {
    background-color: #b7cded;
}

.select2-container--default.select2-container--focus .select2-selection--multiple {
    border-color: var(--primary-bg-color);
}

.select2-container--default .select2-selection--multiple .select2-selection__choice {
    background-color: var(--primary-bg-color) !important;
    border: 1px solid var(--primary-bg-color) !important;
}

.btn-primary {
    color: #fff !important;
    position: relative;
    z-index: 2;
    background: #008eb5 !important;
    border-color: #008eb5 !important;
}

.btn-primary:hover {
    color: #fff;
    background: #008eb5;
    border-color: #008eb5;
    opacity: 0.8;
}

.btn-primary:focus,
.btn-primary.focus {
    box-shadow: 0 0 0 2px rgba(98, 89, 202, 0.9);
}

.btn-primary.disabled,
.btn-primary:disabled {
    color: #fff;
    background-color: var(--primary-bg-color);
    border-color: var(--primary-bg-color);
}

.btn-primary:not(:disabled):not(.disabled):active,
.btn-primary:not(:disabled):not(.disabled).active {
    color: #fff;
    background-color: var(--primary-bg-color);
    border-color: var(--primary-bg-color);
}

.show>.btn-primary.dropdown-toggle {
    color: #fff;
    background-color: #5a51c7;
    border-color: #5a51c7;
}

.btn-primary:not(:disabled):not(.disabled):active:focus,
.btn-primary:not(:disabled):not(.disabled).active:focus {
    box-shadow: 0 0 0 2px rgba(98, 89, 202, 0.9);
}

.show>.btn-primary.dropdown-toggle:focus {
    box-shadow: 0 0 0 2px rgba(98, 89, 202, 0.9);
}

.btn-primary-light {
    color: var(--primary-bg-color);
    background: #d6d2fb;
    border-color: #d6d2fb;
}

.btn-primary-light:hover {
    color: #fff;
    background-color: var(--primary-bg-color);
    border-color: var(--primary-bg-color);
}

.btn-primary-light:focus,
.btn-primary-light.focus {
    box-shadow: 0 0 0 2px rgba(98, 89, 202, 0.9);
}

.btn-primary-gradient {
    color: #fff;
    background: linear-gradient(to bottom right, var(--primary-bg-color) 0%, var(--primary06) 100%) !important;
    border-color: var(--primary-bg-color) !important;
    box-shadow: 0 5px 10px rgba(98, 89, 202, 0.3);
}

.btn-primary-gradient:hover {
    color: #fff;
    background-color: var(--primary-bg-color);
    border-color: var(--primary-bg-color);
}

.btn-primary-gradient:focus,
.btn-primary-gradient.focus {
    box-shadow: 0 0 0 2px rgba(98, 89, 202, 0.9);
}

.btn-primary-gradient.disabled,
.btn-primary-gradient:disabled {
    color: #fff;
    background-color: var(--primary-bg-color);
    border-color: var(--primary-bg-color);
}

.btn-primary-gradient:not(:disabled):not(.disabled):active,
.btn-primary-gradient:not(:disabled):not(.disabled).active {
    color: #fff;
    background-color: var(--primary-bg-color);
    border-color: var(--primary-bg-color);
}

.show>.btn-primary-gradient.dropdown-toggle {
    color: #fff;
    background-color: var(--primary-bg-color);
    border-color: var(--primary-bg-color);
}

.btn-primary-gradient:not(:disabled):not(.disabled):active:focus,
.btn-primary-gradient:not(:disabled):not(.disabled).active:focus {
    box-shadow: 0 0 0 2px rgba(98, 89, 202, 0.9);
}

.show>.btn-primary-gradient.dropdown-toggle:focus {
    box-shadow: 0 0 0 2px rgba(98, 89, 202, 0.9);
}

.btn-outline-primary {
    color: var(--primary-bg-color);
    background: transparent;
    background-image: none;
    border-color: var(--primary-bg-color);
}

.btn-outline-primary:hover {
    color: #fff;
    background: #5a51c7;
    border-color: #5a51c7;
}

.btn-outline-primary:focus,
.btn-outline-primary.focus {
    box-shadow: 0 0 0 2px rgba(98, 89, 202, 0.1);
}

.btn-outline-primary.disabled,
.btn-outline-primary:disabled {
    color: var(--primary-bg-color);
    background-color: transparent;
}

.btn-outline-primary:not(:disabled):not(.disabled):active,
.btn-outline-primary:not(:disabled):not(.disabled).active {
    color: #fff;
    background-color: var(--primary-bg-color);
    border-color: var(--primary-bg-color);
}

.show>.btn-outline-primary.dropdown-toggle {
    color: #fff;
    background-color: var(--primary-bg-color);
    border-color: var(--primary-bg-color);
}

.btn-outline-primary:not(:disabled):not(.disabled):active:focus,
.btn-outline-primary:not(:disabled):not(.disabled).active:focus {
    box-shadow: 0 0 0 2px rgba(98, 89, 202, 0.1);
}

.show>.btn-outline-primary.dropdown-toggle:focus {
    box-shadow: 0 0 0 2px rgba(98, 89, 202, 0.1);
}

.btn-link {
    color: var(--primary-bg-color);
}

.btn-link:hover {
    color: #5a51c7;
}

.drop-icon-wrap .drop-icon-item:hover {
    color: var(--primary-bg-color);
}

.dropdown-item:hover,
.dropdown-item:focus,
.dropdown-item.active,
.dropdown-item:active {
    color: var(--primary-bg-color);
}

.timeline__item:after {
    border: 6px solid var(--primary-bg-color);
}

.custom-control-input:checked~.custom-control-label::before {
    color: #fff;
    background-color: var(--primary-bg-color);
    border-color: var(--primary-bg-color);
}

.custom-checkbox .custom-control-input:checked~.custom-control-label::before,
.custom-checkbox .custom-control-input:indeterminate~.custom-control-label::before,
.custom-checkbox .custom-control-input:disabled:checked~.custom-control-label::before {
    background-color: var(--primary-bg-color);
}

.custom-radio .custom-control-input:checked~.custom-control-label::before {
    background-color: var(--primary-bg-color);
    border-color: var(--primary-bg-color);
}

.custom-radio .custom-control-input:disabled:checked~.custom-control-label::before {
    background-color: var(--primary-bg-color);
}

.form-select:focus,
.form-file-input:focus~.form-file-label::after {
    border-color: none;
}

.form-file-label::after {
    background-color: var(--primary-bg-color);
}

.custom-range::-webkit-slider-thumb,
.custom-range::-moz-range-thumb,
.custom-range::-ms-thumb {
    background-color: var(--primary-bg-color);
}

.nav-pills .nav-link.active,
.nav-pills .show>.nav-link {
    background-color: var(--primary-bg-color);
}

.page-link:hover {
    color: #5a51c7;
}

.page-item.active .page-link {
    color: #fff;
    background-color: var(--primary-bg-color);
    border-color: var(--primary-bg-color);
}

.range.range-primary input[type=range]::-webkit-slider-thumb,
.range.range-primary input[type=range]::-moz-slider-thumb {
    background-color: var(--primary-bg-color);
}

.range.range-primary output {
    background-color: var(--primary-bg-color);
}

.range.range-primary input[type=range] {
    outline-color: var(--primary-bg-color);
}

.panel.price>.panel-heading {
    background: var(--primary-bg-color);
}

.nav-tabs .nav-item1 .nav-link:hover:not(.disabled),
.nav-tabs .nav-item1 .nav-link.active {
    background: var(--primary-bg-color);
}

.pop-primary {
    color: var(--primary-bg-color);
}

.heading-primary {
    background-color: var(--primary-bg-color);
    color: #ffffff;
    padding: 5px;
}

.breadcrumb-item.active {
    color: #f5f3f9;
}

.breadcrumb-item1 a {
    color: #495584;
}

.breadcrumb-item2 a {
    color: #495584;
}

.panel-title1 a {
    background: #F2F1F9 !important;
}

.btn.dropdown-toggle.btn-primary~.dropdown-menu .dropdown-plus-title {
    border-color: rgb(98, 89, 202) !important;
}

.panel-default>.panel-heading {
    color: var(--primary-bg-color);
}

.alert-primary {
    color: var(--primary-bg-color);
    background-color: rgba(98, 89, 202, 0.1);
    border-color: rgba(98, 89, 202, 0.01);
}

.alert-primary hr {
    border-top-color: var(--primary-bg-color);
}

.alert-primary .alert-link {
    color: var(--primary-bg-color);
}

.list-group-item-primary {
    color: var(--primary-bg-color);
    background-color: #cbdbf2;
}

.list-group-item-primary.list-group-item-action:hover,
.list-group-item-primary.list-group-item-action:focus {
    color: var(--primary-bg-color);
    background-color: #b7cded;
}

.list-group-item-primary.list-group-item-action.active {
    color: #fff;
    background-color: var(--primary-bg-color);
    border-color: var(--primary-bg-color);
}

.header-bg {
    background: var(--primary-bg-color);
    /* fallback for old browsers */
    background: -webkit- linear-gradient(to bottom right, var(--primary-bg-color) 0%, var(--primary06) 100%) !important;
    /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(to bottom right, var(--primary-bg-color) 0%, var(--primary06) 100%) !important;
}

.footer a:not(.btn) {
    color: var(--primary-bg-color);
}

.nav-tabs .nav-link:hover:not(.disabled),
.nav-tabs .nav-link.active {
    background: var(--primary-bg-color);
}

@media (max-width: 992px) {
    .nav-item.with-sub .sub-item {
        border-top: 2px solid var(--primary-bg-color);
    }
}

.expanel-primary>.expanel-heading {
    color: #fff !important;
    background-color: var(--primary-bg-color) !important;
    border-color: var(--primary-bg-color) !important;
}

.login-img {
    background: var(--primary-bg-color);
}

.avatar {
    background: #e3e1f5 no-repeat center/cover;
}

.spinner,
.spinner-lg,
.double-bounce1,
.double-bounce2,
.cube1,
.cube2 {
    background-color: var(--primary-bg-color);
}

.lds-heart div {
    background: var(--primary-bg-color);
}

.lds-heart div:after,
.lds-heart div:before {
    background: var(--primary-bg-color);
}

.lds-ring div {
    border: 6px solid var(--primary-bg-color);
    border-color: var(--primary-bg-color) transparent transparent transparent;
}

.lds-hourglass:after {
    border: 26px solid var(--primary-bg-color);
    border-color: var(--primary-bg-color) transparent;
}

a.chip:hover {
    background-color: var(--primary-bg-color);
}

.tag-primary {
    background-color: var(--primary-bg-color);
    color: #fff;
}

.selectgroup-input:checked+.selectgroup-button {
    border-color: var(--primary-bg-color);
    z-index: 1;
    color: var(--primary-bg-color);
    background: #f6f4fb;
}

.selectgroup-input:focus+.selectgroup-button {
    border-color: var(--primary-bg-color);
    z-index: 2;
    color: var(--primary-bg-color);
    box-shadow: 0 0 0 2px rgba(98, 89, 202, 0.25);
}

.custom-switch-input:checked~.custom-switch-indicator {
    background: var(--primary-bg-color);
}

.ui-datepicker .ui-datepicker-title {
    color: var(--primary-bg-color);
}

.timeline--horizontal .timeline-divider {
    background: var(--primary-bg-color) !important;
}

#back-to-top {
    background-image: linear-gradient(to bottom right, var(--primary-bg-color) 0%, var(--primary06) 100%);
}

#back-to-top:hover {
    color: var(--primary-bg-color) !important;
    border: 2px solid var(--primary-bg-color);
}

.tabs-menu1 ul li .active {
    border-bottom: 3px solid var(--primary-bg-color);
}

.tab-content i,
.tabs-menu2 ul li .active {
    color: var(--primary-bg-color);
}

.tab-content .btn i {
    color: #fff;
}

.rating-stars .rating-stars-container .rating-star.is--active .fa-heart,
.rating-stars .rating-stars-container .rating-star.is--hover .fa-heart {
    color: var(--primary-bg-color);
}

.message-feed:not(.right) .mf-content {
    background: var(--primary-bg-color);
    color: #fff;
}

.message-feed:not(.right) .mf-content:before {
    border-right-color: var(--primary-bg-color);
}

.msb-reply button {
    background: var(--primary-bg-color);
}

/* --------Added--------- css*/
.wizard-card .moving-tab {
    margin-top: 5px;
    background: linear-gradient(to bottom right, var(--primary-bg-color) 0%, var(--primary06) 100%);
}

.cal1 .clndr .clndr-table .header-days {
    background: #fff;
}

.cal1 .clndr .clndr-table tr .day.today.event,
.cal1 .clndr .clndr-table tr .day.my-today.event {
    background: var(--primary-bg-color);
}

.cal1 .clndr .clndr-controls .clndr-control-button .clndr-previous-button {
    color: #fff;
    background: var(--primary-bg-color);
    border-radius: 5px;
}

.cal1 .clndr .clndr-controls .clndr-control-button .clndr-next-button {
    color: #fff;
    background: var(--primary-bg-color);
    border-radius: 5px;
}

.cal1 .clndr .clndr-controls .clndr-control-button .clndr-next-button:hover {
    background: var(--primary-bg-color);
}

.cal1 .clndr .clndr-controls .clndr-control-button .clndr-previous-button:hover {
    background: var(--primary-bg-color);
}

.fc button {
    background: var(--primary-bg-color);
}

.fc-event {
    border: 1px solid var(--primary-bg-color);
    background-color: var(--primary-bg-color);
}

.fc-event-dot {
    background-color: var(--primary-bg-color);
}

/* ----Added css----*/
div.conv-form-wrapper div.options div.option {
    color: var(--primary-bg-color);
    border: 1px solid var(--primary-bg-color);
}

form.convFormDynamic button.submit {
    color: var(--primary-bg-color);
    border: 1px solid var(--primary-bg-color) !important;
}

div.conv-form-wrapper div.options div.option.selected {
    background: var(--primary-bg-color);
    color: #fff;
}

div.conv-form-wrapper div.options div.option.selected:hover {
    background: var(--primary-bg-color);
}

form.convFormDynamic button.submit:hover {
    background: var(--primary-bg-color) !important;
    color: #fff;
}

div.conv-form-wrapper div#messages div.message.from {
    background: var(--primary-bg-color);
}

.addui-slider .addui-slider-track .addui-slider-handle:after,
.addui-slider .addui-slider-track .addui-slider-range {
    background: var(--primary-bg-color);
}

.timeline__item:after {
    border: 6px solid var(--primary-bg-color);
}

.accordionjs .acc_section.acc_active>.acc_head {
    background: #F2F1F9;
    color: var(--primary-bg-color) !important;
}

.tab_wrapper>ul li.active {
    border-color: var(--primary-bg-color);
    background: var(--primary-bg-color);
}

.tab_wrapper.right_side>ul li.active:after {
    background: var(--primary-bg-color);
}

.cal1 .clndr .clndr-table tr .day.today,
.cal1 .clndr .clndr-table tr .day.my-today {
    background: #fff;
    color: #343a40;
}

.cal1 .clndr .clndr-table tr .day.today:hover,
.cal1 .clndr .clndr-table tr .day.my-today:hover {
    background: #5a51c7;
    color: #fff;
}

.weather-card .top {
    background: var(--primary-bg-color);
}

.primary .pricing-divider {
    background: var(--primary-bg-color) !important;
}

.product-grid6 .title a:hover {
    color: var(--primary-bg-color);
}

.product-grid6 .icons li a {
    color: #6e84a3;
    border: 1px solid #eaedf1;
}

.product-grid6 .icons li a:hover {
    color: #fff;
    background-image: linear-gradient(to bottom right, var(--primary-bg-color) 0%, var(--primary06) 100%);
}

.product-grid6 .icons li a:after,
.product-grid6 .icons li a:before {
    background-image: linear-gradient(to bottom right, var(--primary-bg-color) 0%, var(--primary06) 100%);
}

.card-counter.primary {
    background-image: linear-gradient(to bottom right, var(--primary-bg-color) 0%, var(--primary06) 100%);
    color: #FFF;
}

#user-profile .profile-details a>i,
#user-profile .profile-details i {
    color: var(--primary-bg-color);
}

@media (max-width: 767.98px) and (min-width: 576px) {
    .search-show .search-element {
        background-image: linear-gradient(to bottom right, var(--primary-bg-color) 0%, var(--primary06) 100%);
    }
}

@media (max-width: 575.98px) {
    .search-show .search-element {
        background-image: linear-gradient(to bottom right, var(--primary-bg-color) 0%, var(--primary06) 100%);
    }
}

.text-primary-gradient {
    background: linear-gradient(to bottom right, var(--primary-bg-color) 0%, var(--primary06) 100%);
}

.chat .msg_head,
.msb-reply button {
    background: var(--primary-bg-color);
}

.top-footer p:hover {
    color: var(--primary-bg-color);
}

.top-footer a address:hover,
.top-footer a:hover {
    color: var(--primary-bg-color);
}

.footer-payments a:hover {
    color: var(--primary-bg-color);
}

.footer-social-list a {
    color: var(--primary-bg-color);
}

.footer-social-list a:hover {
    color: var(--primary-bg-color);
}

.sw-theme-dots .nav-tabs .nav-link:hover:not(.disabled) {
    color: var(--primary-bg-color);
}

.custom-switch-input:checked~.custom-switch-indicator {
    background: var(--primary-bg-color);
}

.label-primary {
    background: var(--primary-bg-color);
    color: #fff;
}

.bg-primary-transparent {
    background-color: rgba(98, 89, 202, 0.1);
}

.text-primary-shadow {
    text-shadow: 0 5px 10px rgba(98, 89, 202, 0.3);
}

.chart-dropshadow-primary {
    -webkit-filter: drop-shadow(-6px 12px 4px rgba(98, 89, 202, 0.2));
    filter: drop-shadow(-6px 12px 4px rgba(98, 89, 202, 0.2));
}

.fc-toolbar .fc-state-active,
.fc-toolbar .ui-state-active {
    background: #544bb9;
}

/*-- Sidemenu --*/
.side-menu__item.active {
    color: #ffffff;
}

.side-menu__item.active .side-menu__icon {
    color: #ffffff !important;
}

/*-- Horizontal-menu --*/
.horizontalMenu>.horizontalMenu-list>li>ul.sub-menu>li>a:hover,
.horizontalMenu>.horizontalMenu-list>li>ul.sub-menu>li>ul.sub-menu>li>a:hover {
    color: var(--primary-bg-color);
}

.onoffswitch-checkbox:checked+.onoffswitch-label {
    background-color: var(--primary-bg-color);
    border-color: var(--primary-bg-color);
}

.onoffswitch-checkbox:checked+.onoffswitch-label:before {
    border-color: var(--primary-bg-color);
}

.onoffswitch2-checkbox:checked+.onoffswitch2-label {
    background-color: var(--primary-bg-color);
}

.onoffswitch2-checkbox:checked+.onoffswitch-label2,
.onoffswitch2-checkbox:checked+.onoffswitch2-label:before {
    border-color: var(--primary-bg-color);
}

/*--Dark-mode---*/
.dark-mode .breadcrumb-item.active,
.dark-mode .dropdown-item:hover {
    color: #827ae0;
}

.dark-mode .alert-primary {
    color: #827ae0;
}

.dropdown-item:focus,
.dark-mode .dropdown-item.active,
.dropdown-item:active {
    color: #8061ce;
}

.dark-mode .horizontalMenu>.horizontalMenu-list>li>ul.sub-menu>li>a:hover,
.dark-mode .horizontalMenu>.horizontalMenu-list>li>ul.sub-menu>li>ul.sub-menu>li>a:hover {
    color: #827ae0;
}

.dark-mode .side-menu__item.active,
.dark-mode .side-menu__item:hover,
.dark-mode .side-menu__item:focus,
.dark-mode .side-menu__item.active .side-menu__icon {
    color: #dedefd !important;
}

.dark-mode .side-menu__item.active {
    color: #ffffff !important;
}

.dark-mode .side-menu__item:hover,
.dark-mode .side-menu__item:focus {
    color: #827ae0;
}

.dark-mode.dark-menu .side-menu__item.active .side-menu__icon,
.dark-mode.dark-menu .side-menu__item:hover .side-menu__icon,
.dark-mode.dark-menu .side-menu__item:focus .side-menu__icon {
    color: #827ae0;
}

.dark-mode .footer a:not(.btn) {
    color: #827ae0;
}

.dark-mode .text-primary {
    color: #827ae0 !important;
}

.dark-mode a.text-primary:hover,
.dark-mode a.text-primary:focus {
    color: #746ae8 !important;
}

.dark-mode .panel-default>.panel-heading,
.dark-mode .panel-tabs a {
    color: #dedefd;
}

.dark-mode .sidebar-icon {
    color: #827ae0 !important;
}

.dark-mode .side-menu__item.active {
    color: #ffffff;
}

.dark-mode .side-menu__item.active .side-menu__icon {
    color: #ffffff;
}

/*Color-Header*/
.light-menu.dark-mode .side-menu__item.active .side-menu__icon,
.light-menu.dark-mode .side-menu__item:hover .side-menu__icon,
.light-menu.dark-mode .side-menu__item:focus .side-menu__icon,
.light-menu.dark-mode .side-menu__item.active,
.light-menu.dark-mode .side-menu__item:hover,
.light-menu.dark-mode .side-menu__item:focus {
    color: #8061ce;
}

.light-menu .side-menu__item.active .side-menu__icon {
    color: #495584;
}

.light-menu .side-menu__item:hover .side-menu__icon,
.light-menu .side-menu__item:focus .side-menu__icon,
.light-menu .side-menu__item.active,
.light-menu .side-menu__item:hover,
.light-menu .side-menu__item:focus {
    color: var(--primary-bg-color) !important;
}

.default-body .app-sidebar {
    background: var(--primary-bg-color) !important;
}

/*Color-Header*/
.horizontal-main2.horizontal-main {
    background: var(--primary-bg-color);
}

@media only screen and (max-width: 991px) {
    .dark-mode .horizontalMenu>.horizontalMenu-list {
        background: #30304d;
    }
}

.dark-menu .side-menu__item.active .side-menu__icon,
.dark-menu .side-menu__item:hover .side-menu__icon,
.dark-menu .side-menu__item:focus .side-menu__icon,
.dark-menu .side-menu__item.active,
.dark-menu .side-menu__item:hover {
    color: #8c63f3;
}

.color-menu .app-sidebar {
    background: var(--primary-bg-color) !important;
}

.color-menu .side-header {
    background: var(--primary-bg-color);
    border-bottom: 1px solid #7d76c5;
}

.color-menu .side-menu__item.active .side-menu__icon,
.color-menu .side-menu__item:hover .side-menu__icon,
.color-menu .side-menu__item:focus .side-menu__icon,
.color-menu .side-menu__item.active,
.color-menu .side-menu__item:hover,
.color-menu .side-menu__item:focus {
    color: #fff !important;
}

.color-menu .side-menu h3 {
    color: #b3aee8 !important;
}

.color-menu .side-menu .side-menu__icon,
.color-menu .side-menu .side-menu__item {
    color: #cccef5 !important;
}

.color-menu .side-menu__item:hover,
.color-menu .side-menu__item:focus {
    color: #fff !important;
    background: #7772dc !important;
}

.color-menu .side-menu__item:hover .side-menu__icon,
.color-menu .side-menu__item:hover .side-menu__label,
.color-menu .side-menu__item:focus .side-menu__icon,
.color-menu .side-menu__item:focus .side-menu__label {
    color: #ffffff !important;
}

.color-menu.sidebar-mini .slide a {
    color: #cccef5 !important;
}

.color-menu.sidebar-mini .slide-item {
    color: #cccef5;
}

.color-menu.sidebar-mini .slide-menu li .slide-item:before {
    color: #cccef5;
}

.color-menu .side-menu__item {
    color: #cccef5;
}

.color-menu .slide-menu a.active {
    color: white !important;
}

.gradient-menu .side-menu__item.active .side-menu__icon,
.gradient-menu .side-menu__item:hover .side-menu__icon,
.gradient-menu .side-menu__item:focus .side-menu__icon,
.gradient-menu .side-menu__item.active,
.gradient-menu .side-menu__item:hover,
.gradient-menu .side-menu__item:focus {
    color: #ccc9ec !important;
}

.gradient-menu .side-menu__item:hover .side-menu__icon,
.gradient-menu .side-menu__item:hover .side-menu__label,
.gradient-menu .side-menu__item:focus .side-menu__icon,
.gradient-menu .side-menu__item:focus .side-menu__label {
    color: #ccc9ec;
}

.color-menu.sidemenu-bgimage .side-header:before,
.color-menu.sidemenu-bgimage .app-sidebar:before {
    background: rgba(98, 89, 202, 0.94);
}

@media (max-width: 767px) {
    .color-menu .mobile-header {
        background: var(--primary-bg-color) !important;
    }
}

