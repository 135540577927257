.card-blog-body {
  display: flex;
  height: 100%;
}
.buy-blog-body .buy-blog-image {
  width: 100%;
  height: 30rem;
  object-fit: cover;
}

.admin-card-blog-description {
  height: 60%;
  overflow-y: scroll;

  text-align: justify;
  font-size: 0.9rem;
  padding: 0.3rem;
}
.admin-card-blog-title {
  font-size: 1.1rem;
  font-weight: 400;
  height: 30%;
  overflow-y: scroll;
  text-align: justify;
  padding: 0.3rem;
}
.buy-blog-body .buy-blog-image img {
  height: 100%;
  /* width: inherit; */
  object-fit: cover;
  width: 100%;
}
.card-blog-details {
  width: 57%;
  margin-left: 10px;
  margin-right: 10px;
}
.blog-delete-and-edit {
  display: flex;
}
.blog-delete-and-edit > span:first-child {
  margin-right: 0.7rem;
}
