.privacy-policy-main-heading {
  color: var(--blueButton);
  text-align: center;
  font-size: 3rem;
}
.privacy-policy-second-main-heading {
  color: var(--blueButton);
  text-align: center;
}
.privacy-policy-bold-heading {
  font-weight: bold;
  font-size: 1.4rem;
}
.privacy-policy-main-div {
  padding: 2rem 4rem;
}
.privacy-policy-body {
  padding: 0 4rem;
}
.inline-privacy-policy-paragraph {
  font-size: 1.02rem;
  margin-top: 0.5rem;
  font-weight: 500;
}
.privacy-policy-points {
  padding-left: 2rem;
}
.privacy-policy-key-points li::before {
  content: "\2022";
  color: black;
  font-weight: bold;
  display: inline-block;
  width: 1em;
  margin-left: 1em;
}
.privacy-policy-key-points li {
  font-size: 1rem;
}
.privacy-policy-section-points {
  padding-left: 1rem;
  font-size: 1rem;
}
@media (min-width: 300px) and (max-width: 700px) {
  .privacy-policy-body {
    padding: 0 0.3rem;
  }
  .privacy-policy-points {
    padding-left: 0rem;
  }
  .privacy-policy-key-points li {
    font-size: 1rem;
    margin-bottom: 0.3rem;
  }
  .privacy-policy-section-points li {
    margin-bottom: 0.7rem;
  }
}
@media (max-width: 500px) {
  .privacy-policy-main-heading {
    font-size: 2rem;
    margin-top: 1rem;
  }
}
