.solicitor-card-outer {
    display: flex;
    gap: 5%;
    justify-content: center;
}
.solicitor-card-inner {
    width: 40% !important;
}
.solicitor-card-outer .solicitor-card-inner{
box-shadow: -8px 8px 10px -1px #b5d7ef, 0px -8px 10px -1px #e8e9eb
}
@media (max-width:1000px) {
    .solicitor-card-outer {
        display: block;
    }
    .solicitor-card-inner {
        width: 80% !important;
    }
}
@media (max-width:767px) {
    .solicitor-card-inner {
        width: 100% !important;
    }
}
@media (max-width:450px) {
    .solicitor-card-outer {
        margin: 0 !important;
    }
}