body.rtl {
    text-align: right;
    direction: rtl;
}

.rtl {
    caption {
        text-align: left;
    }

    .list-unstyled,
    .list-inline {
        padding-left: 0;
    }

    .list-inline-item:not(:last-child) {
        margin-right: 0.5rem;
        margin-left: inherit;
    }

    html {
        direction: rtl;
    }

    /* #accordion rotate icon option */

    #accordion .panel-heading1 a:before {
        right: 5px;
        left: inherit;
    }

    .alert-dismissible .btn-close {
        left: 0;
        right: inherit;
    }

    .alert-icon {
        padding-left: 3rem;
        padding-right: inherit;

        > i {
            left: 1rem;
            right: inherit;
        }
    }

    .alert-avatar {
        padding-right: 3.75rem;
        padding-left: inherit;

        .avatar {
            right: 0.75rem;
            left: inherit;
        }
    }

    /*----- Avatars -----*/

    .avatar-status {
        left: -2px;
        right: inherit;
    }

    .avatar-list .avatar:not(:last-child) {
        margin-left: 0.5rem;
        margin-right: inherit;
    }

    .avatar-list-stacked .avatar {
        margin-right: -0.8em !important;
        margin-left: inherit;
    }

    .avatar-group .avatar + .avatar {
        margin-left: -0.8rem;
        margin-right: inherit;
    }

    /*-----Badges-----*/

    .btn .badge {
        left: -3px;
        right: inherit;
    }

    .badgetext {
        float: left;
    }

    blockquote {
        margin: 0 0 1rem;
        padding-right: 2rem;
        padding-left: inherit;
        border-right: 2px solid $border;

        cite {
            text-align: right;
        }
    }

    /*------ Breadcrumb ------*/

    .breadcrumb {
        right: 0;
        left: inherit;
    }

    .fileinput-button {
        float: left;
        margin-right: 4px;
        margin-left: inherit;

        input {
            direction: rtl;
            right: 0;
            left: inherit;
        }
    }

    /*--------- Buttons--------------*/

    .btn-group {
        .btn + {
            .btn,
            .btn-group {
                margin-left: -1px;
                margin-right: inherit;
            }
        }

        .btn-group + {
            .btn,
            .btn-group {
                margin-left: -1px;
                margin-right: inherit;
            }
        }
    }

    .btn-group-vertical {
        .btn + {
            .btn,
            .btn-group {
                margin-left: -1px;
                margin-right: inherit;
            }
        }

        .btn-group + {
            .btn,
            .btn-group {
                margin-left: -1px;
                margin-right: inherit;
            }
        }
    }

    .btn-group > {
        .btn {
            &:first-child {
                margin-left: 0;
                margin-right: inherit;
            }

            &:not(:last-child):not(.dropdown-toggle) {
                border-top-left-radius: 0;
                border-bottom-left-radius: 0;
                border-top-right-radius: 5px;
                border-bottom-right-radius: 5px;
            }
        }

        .btn-group:not(:last-child) > .btn {
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
        }

        .btn:not(:first-child),
        .btn-group:not(:first-child) > .btn {
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
            border-top-left-radius: 5px;
            border-bottom-left-radius: 5px;
        }
    }

    .dropdown-toggle-split::after,
    .dropup .dropdown-toggle-split::after,
    .dropright .dropdown-toggle-split::after {
        margin-left: 0;
        margin-right: inherit;
    }

    .dropleft .dropdown-toggle-split::before {
        margin-right: 0;
        margin-left: inherit;
    }

    .btn-group-vertical > {
        .btn + {
            .btn,
            .btn-group {
                margin-left: 0;
                margin-right: inherit;
            }
        }

        .btn-group + {
            .btn,
            .btn-group {
                margin-left: 0;
                margin-right: inherit;
            }
        }
    }

    .btn-list > {
        .btn:not(:last-child),
        .dropdown:not(:last-child) {
            margin-left: 0.5rem;
            margin-right: inherit;
        }
    }

    .btn-list {
        .btn i {
            float: right;
        }
        .btn.btn-icon i {
            float: inherit;
        }
    }

    .btn-loading {
        &:after {
            border-right-color: transparent !important;
            left: calc(50% - (1.4em / 2));
            right: inherit;
        }

        &.btn-sm:after {
            left: calc(50% - (1em / 2));
            right: inherit;
        }
    }

    .btn-group-sm > .btn-loading.btn:after {
        left: calc(50% - (1em / 2));
        right: inherit;
    }

    .btn-loading.btn-secondary:after {
        border-color: #495057;
    }

    .btn-social {
        &.btn-lg {
            padding-left: 61px;
            padding-right: inherit;
        }

        &.btn-sm {
            padding-left: 38px;
            padding-right: inherit;
        }

        &.btn-xs {
            padding-left: 30px;
            padding-right: inherit;
        }
    }

    .btn-social-icon {
        padding-left: 44px;
        padding-right: inherit;
        text-align: left;

        > :first-child {
            left: 0;
            right: inherit;
            border-right: 1px solid rgba(0, 0, 0, 0.2);
        }

        &.btn-lg {
            padding-left: 61px;
            padding-right: inherit;
        }

        &.btn-sm {
            padding-left: 38px;
            padding-right: inherit;
        }

        &.btn-xs {
            padding-left: 30px;
            padding-right: inherit;
        }
    }

    .btn-animation {
        .loading {
            margin-left: 20px;
            margin-right: inherit;
        }

        .btn-loaders:after {
            border-right-color: transparent !important;
            left: 10px !important;
            right: inherit !important;
        }
    }

    .card-link + .card-link {
        margin-left: 1.5rem;
        margin-right: inherit;
    }

    @media (min-width: 576px) {
        .card-group > .card {
            + .card {
                margin-left: 0;
                margin-right: inherit;
                border-left: 0;
            }

            &:first-child {
                border-top-right-radius: 0;
                border-bottom-right-radius: 0;

                .card-img-top,
                .card-header {
                    border-top-right-radius: 0;
                }

                .card-img-bottom,
                .card-footer {
                    border-bottom-right-radius: 0;
                }
            }

            &:last-child {
                border-top-left-radius: 0;
                border-bottom-left-radius: 0;

                .card-img-top,
                .card-header {
                    border-top-left-radius: 0;
                }

                .card-img-bottom,
                .card-footer {
                    border-bottom-left-radius: 0;
                }
            }

            &:only-child {
                .card-img-top,
                .card-header {
                    border-top-left-radius: 3px;
                    border-top-right-radius: 3px;
                }

                .card-img-bottom,
                .card-footer {
                    border-bottom-right-radius: 3px;
                    border-bottom-left-radius: 3px;
                }
            }
        }
    }

    .card-blog-overlay:before,
    .card-blog-overlay1:before,
    .card-blog-overlay2:before {
        right: 0;
        left: inherit;
    }

    .card-table tr {
        td:first-child,
        th:first-child {
            padding-right: 1.5rem !important;
            padding-left: inherit;
        }

        td:last-child,
        th:last-child {
            padding-left: 1.5rem;
            padding-right: inherit;
        }
    }

    .card-link + .card-link {
        margin-left: 1rem;
        margin-right: inherit;
    }

    .card-options a:not(.btn) {
        margin-left: 0.5rem;
        margin-right: inherit;
    }

    .card-img-absolute {
        margin-right: -42px;
        margin-left: inherit;
    }

    .card-image {
        margin-left: -126px;
        margin-right: inherit;
    }

    .card-status-left {
        right: auto;
        left: inherit;
    }

    .item-card .cardprice {
        right: 15px;
        left: inherit;
    }

    .card-padding {
        padding: 14px 2px 2px 11px;
    }

    .card-counter {
        .count-numbers,
        .count-name {
            right: 35px;
            left: inherit;
        }
    }

    .card-aside-img a {
        left: 0;
        right: inherit;
    }

    .card-link + .card-link {
        margin-left: 1.5rem;
        margin-right: inherit;
    }

    @media (min-width: 576px) {
        .card-group > .card {
            &:first-child {
                border-top-right-radius: 0;
                border-bottom-right-radius: 0;

                .card-img-top,
                .card-header {
                    border-top-right-radius: 0;
                }

                .card-img-bottom,
                .card-footer {
                    border-bottom-right-radius: 0;
                }
            }

            &:last-child {
                border-top-left-radius: 0;
                border-bottom-left-radius: 0;

                .card-img-top,
                .card-header {
                    border-top-left-radius: 0;
                }

                .card-img-bottom,
                .card-footer {
                    border-bottom-left-radius: 0;
                }
            }

            &:only-child {
                border-radius: 3px;
            }
        }
    }

    .card-blog-overlay:before,
    .card-blog-overlay1:before,
    .card-blog-overlay2:before {
        right: 0;
        left: inherit;
    }

    .card-table tr {
        td:first-child,
        th:first-child {
            padding-left: 1.5rem;
            padding-right: inherit;
        }

        td:last-child,
        th:last-child {
            padding-right: 1.5rem;
            // padding-left: inherit;
        }
    }

    .card-link + .card-link {
        margin-left: 1rem;
        margin-right: inherit;
    }

    .card-options {
        margin-right: auto;
        margin-left: 0.5rem;

        a:not(.btn) {
            margin-left: 0.5rem;
            margin-right: inherit;
        }
    }

    .card-image {
        margin-left: -126px;
        margin-right: inherit;
    }

    .card-status-left {
        right: auto;
        left: inherit;
    }

    .item-card .cardprice {
        right: 15px;
        left: inherit;
    }

    .card-padding {
        padding: 14px 2px 2px 11px;
    }

    .card-counter {
        .count-numbers,
        .count-name {
            right: 35px;
            left: inherit;
        }
    }

    .card-aside-img a {
        left: 0;
        right: inherit;
    }

    // CAROUSEL STYLES//
    .active.carousel-item-start,
    .carousel-item-prev:not(.carousel-item-end) {
        transform: translateX(100%) !important;
    }
    .active.carousel-item-end {
        transform: translateX(-100%) !important;
    }
    .carousel-item-next:not(.carousel-item-start) {
        transform: translateX(-100%) !important;
        transform: translateX(-100%) !important;
    }

    .carousel-control-prev {
        left: 0;
        right: inherit;
        left: 0;
        right: initial !important;
    }

    .carousel-indicators {
        padding-left: 0;
        padding-right: inherit;

        li {
            &::before,
            &::after {
                left: 0;
                right: inherit;
            }
        }
    }

    .carousel-indicators1 {
        padding-left: 0;
        padding-right: inherit;

        li {
            &::before,
            &::after {
                left: 0;
                right: inherit;
            }
        }
    }

    .carousel-indicators2 {
        right: 10px;
        left: auto;
        padding-left: 0;
        padding-right: inherit;

        li {
            &::before,
            &::after {
                left: 0;
                right: inherit;
            }
        }
    }

    .carousel-indicators3 {
        padding-left: 0;
        padding-right: inherit;

        li {
            &::before,
            &::after {
                left: 0;
                right: inherit;
            }
        }
    }

    .carousel-indicators4 {
        right: 10px;
        left: auto;
        padding-left: 0;
        padding-right: inherit;

        li {
            &::before,
            &::after {
                left: 0;
                right: inherit;
            }
        }
    }

    .carousel-indicators5 {
        left: 10px;
        right: auto;
        padding-left: 0;
        padding-right: inherit;

        li {
            &::before,
            &::after {
                left: 0;
                right: inherit;
            }
        }
    }

    .carousel-control-prev {
        left: 0;
        right: inherit;
    }

    .carousel-control-next {
        right: 0;
        left: inherit;
    }

    .carousel-indicators {
        padding-left: 0;
        padding-right: inherit;

        li {
            &::before,
            &::after {
                left: 0;
                right: inherit;
            }
        }
    }

    .carousel-indicators1 {
        padding-left: 0;
        padding-right: inherit;

        li {
            &::before,
            &::after {
                left: 0;
                right: inherit;
            }
        }
    }

    .carousel-indicators2 {
        right: 10px;
        left: auto;
        padding-left: 0;
        padding-right: inherit;

        li {
            &::before,
            &::after {
                left: 0;
                right: inherit;
            }
        }
    }

    .carousel-indicators3 {
        left: 10px;
        right: auto;
        padding-left: 0;
        padding-right: inherit;

        li {
            &::before,
            &::after {
                left: 0;
                right: inherit;
            }
        }
    }

    .carousel-indicators4 {
        right: 10px;
        left: auto;
        padding-left: 0;
        padding-right: inherit;

        li {
            &::before,
            &::after {
                left: 0;
                right: inherit;
            }
        }
    }

    .carousel-indicators5 {
        left: 10px;
        right: auto;
        padding-left: 0;
        padding-right: inherit;

        li {
            &::before,
            &::after {
                left: 0;
                right: inherit;
            }
        }
    }

    .dropdown-toggle {
        &::after {
            margin-left: 0.255em;
            margin-right: inherit;
        }

        &:empty::after {
            margin-left: 0;
            margin-right: inherit;
        }
    }

    .dropdown-menu {
        right: 0;
        float: right;
        text-align: right;
    }

    .dropup .dropdown-toggle {
        &::after {
            margin-left: 0.255em;
            margin-right: inherit;
        }

        &:empty::after {
            margin-left: 0;
            margin-right: inherit;
        }
    }

    .dropright {
        .dropdown-menu {
            right: auto;
            left: 100%;
            margin-left: 0.125rem;
            margin-right: inherit;
        }

        .dropdown-toggle {
            &::after {
                margin-left: 0.255em;
                margin-right: inherit;
            }

            &:empty::after {
                margin-left: 0;
                margin-right: inherit;
            }
        }
    }

    .dropleft {
        .dropdown-menu {
            right: 100%;
            left: auto;
            margin-right: 0.125rem;
        }

        .dropdown-toggle {
            &::after {
                margin-left: 0.255em;
                margin-right: inherit;
            }

            &::before {
                margin-right: 0.255em;
                margin-left: inherit;
                border-right: 0.3em solid;
            }

            &:empty::after {
                margin-left: 0;
                margin-right: inherit;
            }
        }
    }

    .dropdown-menu {
        &[data-popper-placement^="top"],
        &[data-popper-placement^="right"],
        &[data-popper-placement^="bottom"],
        &[data-popper-placement^="left"] {
            right: auto;
            left: inherit;
        }

        right: 0;
        float: left;
    }

    .dropdown-media-list .media-body {
        margin-left: 15px;
        margin-right: inherit;
    }

    @media (min-width: 992px) {
        .dropdown-menu-arrow:before {
            left: 14px;
            right: inherit;
            border-left: 1px solid $border;
        }
    }

    .dropdown-menu-arrow.dropdown-menu-end {
        &:before,
        &:after {
            right: auto;
            left: 17px;
        }
    }

    .dropdown-toggle:empty:after {
        margin-left: 0;
        margin-right: inherit;
    }

    .form-check {
        padding-right: 1.25rem;
        padding-left: inherit;
    }

    .form-check-input {
        margin-right: -1.25rem;
        margin-left: inherit;
    }

    .form-check-inline {
        padding-left: 0;
        padding-right: inherit;
        margin-right: 0.75rem;
        margin-left: inherit;

        .form-check-input {
            margin-right: 0.3125rem;
            margin-left: 0;
        }
    }

    .header .form-inline {
        margin-right: 7px;
        margin-left: inherit;
    }

    @media (min-width: 576px) {
        .form-inline {
            .form-check {
                padding-left: 0;
                padding-right: inherit;
            }

            .form-check-input {
                margin-right: 0.25rem;
                margin-left: 0;
            }
        }
    }

    ul.labels-info li a i {
        padding-right: 10px;
        padding-left: inherit;
    }

    * {
        html .fileinput-button {
            line-height: 24px;
            margin: 1px -3px 0 0;
        }

        + html .fileinput-button {
            margin: 1px 0 0;
            padding: 2px 15px;
        }
    }

    @media (max-width: 767px) {
        .border-right-1 {
            border-right: 0 !important;
        }
    }

    .input-group > {
        .form-control + {
            .form-control,
            .form-select,
            .form-file {
                margin-left: -1px;
                margin-right: inherit;
            }
        }

        .form-select + {
            .form-control,
            .form-select,
            .form-file {
                margin-left: -1px;
                margin-right: inherit;
            }
        }

        .form-file + {
            .form-control,
            .form-select,
            .form-file {
                margin-left: -1px;
                margin-right: inherit;
            }
        }

        .form-control:not(:last-child),
        .form-select:not(:last-child),
        .form-control:not(:first-child),
        .form-select:not(:first-child) {
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
        }

        .form-file {
            &:not(:last-child) .form-file-label {
                border-top-right-radius: 0;
                border-bottom-right-radius: 0;

                &::after {
                    border-top-right-radius: 0;
                    border-bottom-right-radius: 0;
                }
            }

            &:not(:first-child) .form-file-label {
                border-top-left-radius: 0;
                border-bottom-left-radius: 0;

                &::after {
                    border-top-left-radius: 0;
                    border-bottom-left-radius: 0;
                }
            }
        }
    }

    .input-group-text {
        border-right: 0;
        border-right: 0;
        border-left: 0;
    }

    .input-group-addon {
        padding: 10px 0px 6px 12px;
        border-right: 0;
    }

    .input-group > {
        .form-control + {
            .form-control,
            .form-select,
            .form-file {
                margin-left: -1px;
                margin-right: inherit;
            }
        }

        .form-select + {
            .form-control,
            .form-select,
            .form-file {
                margin-left: -1px;
                margin-right: inherit;
            }
        }

        .form-file + {
            .form-control,
            .form-select,
            .form-file {
                margin-left: -1px;
                margin-right: inherit;
            }
        }

        .form-control:not(:last-child),
        .form-select:not(:last-child),
        .form-control:not(:first-child),
        .form-select:not(:first-child) {
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
        }

        .form-file {
            &:not(:last-child) .form-file-label {
                border-top-right-radius: 0;
                border-bottom-right-radius: 0;

                &::after {
                    border-top-right-radius: 0;
                    border-bottom-right-radius: 0;
                }
            }

            &:not(:first-child) .form-file-label {
                border-top-left-radius: 0;
                border-bottom-left-radius: 0;

                &::after {
                    border-top-left-radius: 0;
                    border-bottom-left-radius: 0;
                }
            }
        }
    }

    .input-group-addon {
        padding: 10px 0px 6px 12px;
        border-right: 0;
    }

    .modal,
    .modal-open,
    .modal-backdrop.fade.show {
        padding-right: 0 !important;
        padding-left: inherit;
    }

    .modal-open .modal {
        padding-right: 0px !important;
        padding-left: inherit;
    }

    .modal-header .btn-close {
        padding: 1rem;
        margin: -1rem auto -1rem -1rem;
    }

    .modal-footer > {
        :not(:first-child) {
            margin-left: 0.25rem;
        }

        :not(:last-child) {
            margin-right: 0.25rem;
            margin-left: inherit;
        }
    }

    /*------ Navigation -------*/

    .nav,
    .nav1 {
        padding-right: 0;
    }

    .nav-tabs .nav-item1 {
        &.nav-link {
            border-top-left-radius: 3px;
            border-top-right-radius: 3px;
        }

        i {
            margin-right: 0.25rem;
            margin-right: 10px;
            margin-left: inherit;
        }
    }

    .nav-unread.badge {
        left: 26px;
        right: inherit;
    }

    .nav-tabs .nav-item i {
        margin-right: 10px;
        margin-left: inherit;
    }

    .hor-menu .nav-badge {
        right: 14px;
        left: inherit;
    }

    @media (max-width: 992px) {
        .hor-menu .nav-badge {
            right: auto !important;
            left: inherit;
        }
    }

    @media (min-width: 1600px) {
        body.aside-opened .page {
            margin-right: 22rem;
            margin-left: inherit;
        }
    }

    .pagination {
        padding-left: 0;
        padding-right: inherit;
    }

    .page-link {
        margin-left: -1px;
        margin-right: inherit;
    }

    .page-item {
        &:first-child .page-link {
            margin-left: 0;
            margin-right: inherit;
            border-top-right-radius: 3px;
            border-bottom-right-radius: 3px;
            border-top-left-radius: inherit;
            border-bottom-left-radius: inherit;
        }

        &:last-child .page-link {
            border-top-right-radius: 3px;
            border-bottom-right-radius: 3px;
        }
    }

    .pagination-lg .page-item,
    .pagination-sm .page-item {
        &:first-child .page-link {
            border-top-left-radius: 3px;
            border-bottom-left-radius: 3px;
        }

        &:last-child .page-link {
            border-top-right-radius: 3px;
            border-bottom-right-radius: 3px;
        }
    }

    .page-subtitle {
        margin-left: 2rem;
        margin-right: inherit;
    }

    .page-options {
        margin-right: auto;
        margin-left: inherit;
    }

    .page-header .breadcrumb {
        padding-left: 0 !important;
        padding-right: inherit;
    }

    .pagination-pager {
        .page-prev {
            margin-right: auto;
            margin-left: inherit;
        }

        .page-next {
            margin-left: auto;
            margin-right: inherit;
        }
    }

    .page-total-text {
        margin-right: 1rem;
        margin-left: inherit;
    }

    .panel-group1 > .panel1:first-child .panel-heading1 {
        border-radius: 4px 4px 0 0;
    }

    .panel1:last-child .panel-heading1 {
        border-radius: 0 0 4px 4px;
    }

    /* #bs-collapse icon scale option */

    .panel-title a.accordion-toggle {
        &:before,
        &.collapsed:before {
            padding: 0 10px 0 0;
            float: right;
        }
    }

    .panel-heading1 a {
        &.collapsed:before,
        &:before {
            left: 10px;
            right: inherit;
        }
    }

    #bs-collapse .panel-heading1 a:after {
        right: 5px;
        left: inherit;
    }

    .popover {
        left: 0;
        right: inherit;
        text-align: left;
    }

    .bs-popover-end,
    .bs-popover-auto[data-popper-placement^="right"] {
        margin-left: 0.5rem;
        margin-right: inherit;
    }

    .bs-popover-end .popover-arrow,
    .bs-popover-auto[data-popper-placement^="right"] .popover-arrow {
        left: calc((0.5rem + 1px) * -1);
        right: initial !important;
    }

    .bs-popover-end .popover-arrow::before,
    .bs-popover-auto[data-popper-placement^="right"] .popover-arrow::before,
    .bs-popover-end .popover-arrow::after,
    .bs-popover-auto[data-popper-placement^="right"] .popover-arrow::after {
        border-width: 0.25rem 0.5rem 0.25rem 0;
    }

    .bs-popover-end .popover-arrow::before,
    .bs-popover-auto[data-popper-placement^="right"] .popover-arrow::before {
        left: 0;
        right: inherit;
        border-right-color: #dee3eb;
    }

    .bs-popover-end .popover-arrow::after,
    .bs-popover-auto[data-popper-placement^="right"] .popover-arrow::after {
        left: 1px;
        right: inherit;
        border-right-color: #fff;
    }

    .bs-popover-bottom .popover-arrow::before,
    .bs-popover-auto[data-popper-placement^="bottom"] .popover-arrow::before,
    .bs-popover-bottom .popover-arrow::after,
    .bs-popover-auto[data-popper-placement^="bottom"] .popover-arrow::after {
        border-width: 0 0.25rem 0.5rem 0.25rem;
    }

    .bs-popover-bottom .popover-header::before,
    .bs-popover-auto[data-popper-placement^="bottom"] .popover-header::before {
        left: 50%;
        right: inherit;
        margin-left: -0.25rem;
        margin-right: inherit;
    }

    .bs-popover-start,
    .bs-popover-auto[data-popper-placement^="left"] {
        margin-right: 0.5rem;
        margin-left: inherit;
    }

    .bs-popover-start .popover-arrow,
    .bs-popover-auto[data-popper-placement^="left"] .popover-arrow {
        right: calc((0.5rem + 1px) * -1);
        left: inherit;
    }

    .bs-popover-start .popover-arrow::before,
    .bs-popover-auto[data-popper-placement^="left"] .popover-arrow::before,
    .bs-popover-start .popover-arrow::after,
    .bs-popover-auto[data-popper-placement^="left"] .popover-arrow::after {
        border-width: 0.25rem 0 0.25rem 0.5rem;
    }

    .bs-popover-start .popover-arrow::before,
    .bs-popover-auto[data-popper-placement^="left"] .popover-arrow::before {
        right: 0;
        left: inherit;
        border-left-color: #dee3eb;
    }

    .bs-popover-start .popover-arrow::after,
    .bs-popover-auto[data-popper-placement^="left"] .popover-arrow::after {
        right: 1px;
        left: inherit;
        border-left-color: #fff;
    }

    .progress-bar-indeterminate {
        &:after,
        &:before {
            left: 0;
            right: inherit;
        }
    }

    .table-calendar-link:before {
        left: 0.25rem;
        right: inherit;
    }

    .table-header:after {
        margin-left: 0.5rem;
        margin-right: inherit;
    }

    @media (min-width: 768px) {
        .table-responsive {
            &.export-table .dt-buttons.btn-group {
                right: 172px;
                left: inherit;
            }

            &.deleted-table .data-table-btn {
                right: 200px;
                left: inherit;
            }
        }
    }

    @media (max-width: 1328px) {
        .table-responsive.export-table .dt-buttons.btn-group {
            right: 0px;
            left: inherit;
        }
    }

    /*---- Tags-----*/

    .tag-addon:last-child {
        border-top-left-radius: 3px;
        border-bottom-left-radius: 3px;
    }

    .tooltip {
        // text-align: left;

        &.bs-tether-element-attached-left,
        &.tooltip-end {
            margin-left: 3px;
            margin-right: inherit;
        }

        &.bs-tether-element-attached-bottom .tooltip-inner::before,
        &.tooltip-top .tooltip-inner::before {
            left: 50%;
            right: inherit;
            margin-left: -5px;
            margin-right: inherit;
        }

        &.bs-tether-element-attached-left .tooltip-inner::before,
        &.tooltip-end .tooltip-inner::before {
            left: 0;
            right: inherit;
            border-width: 5px 5px 5px 0;
        }

        &.bs-tether-element-attached-top .tooltip-inner::before,
        &.tooltip-bottom .tooltip-inner::before {
            left: 50%;
            right: inherit;
            margin-left: -5px;
            margin-right: inherit;
        }

        &.bs-tether-element-attached-right,
        &.tooltip-start {
            margin-left: -3px;
            margin-right: inherit;
        }

        &.bs-tether-element-attached-right .tooltip-inner::before,
        &.tooltip-start .tooltip-inner::before {
            right: 0;
            left: inherit;
            border-width: 5px 0 5px 5px;
            border-left-color: #000;
        }
    }
    .bs-tooltip-auto[data-popper-placement^="right"] .tooltip-arrow,
    .bs-tooltip-end .tooltip-arrow {
        left: 0;
        right: inherit;
    }

    // .bs-tooltip-end .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^="right"] .tooltip-arrow {
    //     right: 0;
    //     left: initial !important;
    // }

    // .bs-tooltip-end .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^="right"] .tooltip-arrow::before {
    //     left: 0;
    //     right: inherit;
    //     border-width: 0.4rem 0 0.4rem 0.4rem;
    //     border-left-color: #000;
    // }

    // .bs-tooltip-bottom .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^="bottom"] .tooltip-arrow::before {
    //     border-width: 0 0.4rem 0.4rem;
    // }

    // .bs-tooltip-start .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^="left"] .tooltip-arrow {
    //     left: 0;
    //     right: inherit;
    // }

    // .bs-tooltip-start .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^="left"] .tooltip-arrow::before {
    //     right: 0;
    //     left: inherit;
    //     border-width: 0.4rem 0.4rem 0.4rem 0;
    //     border-right-color: #000;
    // }

    // .bs-popover-top .popover-arrow::before, .bs-popover-auto[data-popper-placement^="top"] .popover-arrow::before, .bs-popover-top .popover-arrow::after, .bs-popover-auto[data-popper-placement^="top"] .popover-arrow::after {
    //     border-width: 0.5rem 0.25rem 0;
    // }

    .bs-popover-end .popover-arrow,
    .bs-popover-auto[data-popper-placement^="right"] .popover-arrow {
        right: calc((0.5rem + 1px) * -1) !important;
        left: auto;
    }

    .bs-popover-end .popover-arrow::before,
    .bs-popover-auto[data-popper-placement^="right"] .popover-arrow::before,
    .bs-popover-end .popover-arrow::after,
    .bs-popover-auto[data-popper-placement^="right"] .popover-arrow::after {
        border-width: 0.25rem 0.5rem 0.25rem 0;
    }

    .bs-popover-end .popover-arrow::before,
    .bs-popover-auto[data-popper-placement^="right"] .popover-arrow::before {
        left: 0;
        right: inherit;
        border-right-color: #dee3eb;
    }

    .bs-popover-end .popover-arrow::after,
    .bs-popover-auto[data-popper-placement^="right"] .popover-arrow::after {
        left: 1px;
        right: inherit;
        border-right-color: #fff;
    }

    // .bs-popover-bottom .popover-arrow::before, .bs-popover-auto[data-popper-placement^="bottom"] .popover-arrow::before, .bs-popover-bottom .popover-arrow::after, .bs-popover-auto[data-popper-placement^="bottom"] .popover-arrow::after {
    //     border-width: 0 0.25rem 0.5rem 0.25rem;
    // }

    .bs-popover-start .popover-arrow,
    .bs-popover-auto[data-popper-placement^="left"] .popover-arrow {
        right: calc((0.5rem + 1px) * -1);
        left: inherit;
    }

    .bs-popover-start .popover-arrow::before,
    .bs-popover-auto[data-popper-placement^="left"] .popover-arrow::before,
    .bs-popover-start .popover-arrow::after,
    .bs-popover-auto[data-popper-placement^="left"] .popover-arrow::after {
        border-width: 0.25rem 0 0.25rem 0.5rem;
    }

    .bs-popover-start .popover-arrow::before,
    .bs-popover-auto[data-popper-placement^="left"] .popover-arrow::before {
        right: 0;
        left: inherit;
        border-left-color: #dee3eb;
    }

    .bs-popover-start .popover-arrow::after,
    .bs-popover-auto[data-popper-placement^="left"] .popover-arrow::after {
        right: 1px;
        left: inherit;
        border-left-color: #fff;
    }

    .tooltip-static-demo {
        .bs-tooltip-top .tooltip-arrow,
        .bs-tooltip-auto[data-popper-placement^="top"] .tooltip-arrow,
        .bs-tooltip-bottom .tooltip-arrow,
        .bs-tooltip-auto[data-popper-placement^="bottom"] .tooltip-arrow {
            left: 50%;
            right: inherit;
            margin-left: -2px;
            margin-right: inherit;
        }

        // .bs-tooltip-start, .bs-tooltip-auto[data-popper-placement^="left"], .bs-tooltip-end, .bs-tooltip-auto[data-popper-placement^="right"] {
        // margin-top: 5px;
        // }
    }

    .popover-static-demo {
        .bs-popover-top .popover-arrow,
        .bs-popover-auto[data-popper-placement^="top"] .popover-arrow,
        .bs-popover-bottom .popover-arrow,
        .bs-popover-auto[data-popper-placement^="bottom"] .popover-arrow {
            left: 50%;
            right: inherit;
            margin-left: -5px;
            margin-right: inherit;
        }
    }

    // .tooltip-primary {
    //     &.bs-tooltip-start .tooltip-arrow::before, &.bs-tooltip-auto[data-popper-placement^="left"] .tooltip-arrow::before {
    //     border-left-color: $primary-1;
    //     }

    //     &.bs-tooltip-end .tooltip-arrow::before, &.bs-tooltip-auto[data-popper-placement^="right"] .tooltip-arrow::before {
    //     border-right-color: $primary-1;
    //     }
    // }

    // .tooltip-secondary {
    //     &.bs-tooltip-start .tooltip-arrow::before, &.bs-tooltip-auto[data-popper-placement^="left"] .tooltip-arrow::before {
    //     border-left-color: #fb6b25 !important;
    //     }

    //     &.bs-tooltip-end .tooltip-arrow::before, &.bs-tooltip-auto[data-popper-placement^="right"] .tooltip-arrow::before {
    //     border-right-color: #fb6b25 !important;
    //     }
    // }

    // .tooltip-success {
    //     &.bs-tooltip-start .tooltip-arrow::before, &.bs-tooltip-auto[data-popper-placement^="left"] .tooltip-arrow::before {
    //     border-left-color: #09ad95 !important;
    //     }

    //     &.bs-tooltip-end .tooltip-arrow::before, &.bs-tooltip-auto[data-popper-placement^="right"] .tooltip-arrow::before {
    //     border-right-color: #09ad95 !important;
    //     }
    // }

    // .tooltip-danger {
    //     &.bs-tooltip-start .tooltip-arrow::before, &.bs-tooltip-auto[data-popper-placement^="left"] .tooltip-arrow::before {
    //     border-right-color: #f82649 !important;
    //     }

    //     &.bs-tooltip-end .tooltip-arrow::before, &.bs-tooltip-auto[data-popper-placement^="right"] .tooltip-arrow::before {
    //     border-left-color: #f82649 !important;
    //     }
    // }

    // .tooltip-info {
    //     &.bs-tooltip-start .tooltip-arrow::before, &.bs-tooltip-auto[data-popper-placement^="left"] .tooltip-arrow::before {
    //     border-right-color: #45aaf2 !important;
    //     }

    //     &.bs-tooltip-end .tooltip-arrow::before, &.bs-tooltip-auto[data-popper-placement^="right"] .tooltip-arrow::before {
    //     border-left-color: #45aaf2 !important;
    //     }
    // }

    .popover {
        left: 0;
        right: inherit;
        text-align: left;

        .popover-arrow {
            margin-left: calc(0.25rem + -5px);
            margin-right: inherit;
        }
    }

    .bs-popover-end,
    .bs-popover-auto[data-popper-placement^="right"] {
        margin-left: 0.5rem;
        margin-right: inherit;
    }

    .bs-popover-end > .popover-arrow,
    .bs-popover-auto[data-popper-placement^="right"] > .popover-arrow {
        left: calc((0.5rem + 1px) * -1);
        right: inherit;
    }

    .bs-popover-end > .popover-arrow::before,
    .bs-popover-auto[data-popper-placement^="right"] > .popover-arrow::before {
        right: 0;
        border-width: 0.5rem 0 0.5rem 0.5rem;
        border-left-color: #d3dfea;
    }

    .bs-popover-end > .popover-arrow::after,
    .bs-popover-auto[data-popper-placement^="right"] > .popover-arrow::after {
        right: 1px;
        border-width: 0.5rem 0 0.5rem 0.5rem;
        border-left-color: #fff;
        left: auto;
    }

    .bs-popover-bottom > .popover-arrow::before,
    .bs-popover-auto[data-popper-placement^="bottom"] > .popover-arrow::before,
    .bs-popover-bottom > .popover-arrow::after,
    .bs-popover-auto[data-popper-placement^="bottom"] > .popover-arrow::after {
        border-width: 0 0.5rem 0.5rem 0.5rem;
    }

    .bs-popover-bottom .popover-header::before,
    .bs-popover-auto[data-popper-placement^="bottom"] .popover-header::before {
        left: 50%;
        right: inherit;
        margin-left: -0.5rem;
        margin-right: inherit;
    }

    .bs-popover-start,
    .bs-popover-auto[data-popper-placement^="left"] {
        margin-right: 0.5rem;
        margin-left: inherit;
    }

    .bs-popover-start > .popover-arrow,
    .bs-popover-auto[data-popper-placement^="left"] > .popover-arrow {
        left: calc((0.5rem + 1px) * -1);
        right: auto;
    }

    .bs-popover-start > .popover-arrow::before,
    .bs-popover-auto[data-popper-placement^="left"] > .popover-arrow::before {
        left: 0;
        right: inherit;
        border-width: 0.5rem 0.5rem 0.5rem 0;
        border-right-color: #d3dfea;
    }

    .bs-popover-start > .popover-arrow::after,
    .bs-popover-auto[data-popper-placement^="left"] > .popover-arrow::after {
        left: 1px;
        border-width: 0.5rem 0.5rem 0.5rem 0;
        border-right-color: #fff;
        right: auto;
    }

    .popover-head-primary {
        &.bs-popover-start .popover-arrow,
        &.bs-popover-auto[data-popper-placement^="left"] .popover-arrow {
            right: -7px;
            left: inherit;
        }

        &.bs-popover-end .popover-arrow,
        &.bs-popover-auto[data-popper-placement^="right"] .popover-arrow {
            left: -7px;
            right: inherit;
        }
    }

    .popover-head-secondary {
        &.bs-popover-start .popover-arrow,
        &.bs-popover-auto[data-popper-placement^="left"] .popover-arrow {
            right: -7px;
            left: inherit;
        }

        &.bs-popover-end .popover-arrow,
        &.bs-popover-auto[data-popper-placement^="right"] .popover-arrow {
            left: -7px;
            right: inherit;
        }
    }

    .popover-primary {
        &.bs-popover-start .popover-arrow::after,
        &.bs-popover-auto[data-popper-placement^="left"] .popover-arrow::after {
            border-left-color: $primary-1 !important;
        }

        &.bs-popover-end .popover-arrow::after,
        &.bs-popover-auto[data-popper-placement^="right"] .popover-arrow::after {
            border-right-color: $primary-1 !important;
        }
    }

    .popover-secondary {
        &.bs-popover-start .popover-arrow::after,
        &.bs-popover-auto[data-popper-placement^="left"] .popover-arrow::after {
            border-left-color: #fb6b25 !important;
        }

        &.bs-popover-end .popover-arrow::after,
        &.bs-popover-auto[data-popper-placement^="right"] .popover-arrow::after {
            border-right-color: #fb6b25 !important;
        }
    }

    .offset-1 {
        margin-left: 8.33333333%;
        margin-right: inherit;
    }

    .offset-2 {
        margin-left: 16.66666667%;
        margin-right: inherit;
    }

    .offset-3 {
        margin-left: 25%;
        margin-right: inherit;
    }

    .offset-4 {
        margin-left: 33.33333333%;
        margin-right: inherit;
    }

    .offset-5 {
        margin-left: 41.66666667%;
        margin-right: inherit;
    }

    .offset-6 {
        margin-left: 50%;
        margin-right: inherit;
    }

    .offset-7 {
        margin-left: 58.33333333%;
        margin-right: inherit;
    }

    .offset-8 {
        margin-left: 66.66666667%;
        margin-right: inherit;
    }

    .offset-9 {
        margin-left: 75%;
        margin-right: inherit;
    }

    .offset-10 {
        margin-left: 83.33333333%;
        margin-right: inherit;
    }

    .offset-11 {
        margin-left: 91.66666667%;
        margin-right: inherit;
    }

    @media (min-width: 576px) {
        .offset-sm-0 {
            margin-left: 0;
            margin-right: inherit;
        }

        .offset-sm-1 {
            margin-left: 8.33333333%;
            margin-right: inherit;
        }

        .offset-sm-2 {
            margin-left: 16.66666667%;
            margin-right: inherit;
        }

        .offset-sm-3 {
            margin-left: 25%;
            margin-right: inherit;
        }

        .offset-sm-4 {
            margin-left: 33.33333333%;
            margin-right: inherit;
        }

        .offset-sm-5 {
            margin-left: 41.66666667%;
            margin-right: inherit;
        }

        .offset-sm-6 {
            margin-left: 50%;
            margin-right: inherit;
        }

        .offset-sm-7 {
            margin-left: 58.33333333%;
            margin-right: inherit;
        }

        .offset-sm-8 {
            margin-left: 66.66666667%;
            margin-right: inherit;
        }

        .offset-sm-9 {
            margin-left: 75%;
            margin-right: inherit;
        }

        .offset-sm-10 {
            margin-left: 83.33333333%;
            margin-right: inherit;
        }

        .offset-sm-11 {
            margin-left: 91.66666667%;
            margin-right: inherit;
        }
    }

    @media (min-width: 768px) {
        .offset-md-0 {
            margin-left: 0;
            margin-right: inherit;
        }

        .offset-md-1 {
            margin-left: 8.33333333%;
            margin-right: inherit;
        }

        .offset-md-2 {
            margin-left: 16.66666667%;
            margin-right: inherit;
        }

        .offset-md-3 {
            margin-left: 25%;
            margin-right: inherit;
        }

        .offset-md-4 {
            margin-left: 33.33333333%;
            margin-right: inherit;
        }

        .offset-md-5 {
            margin-left: 41.66666667%;
            margin-right: inherit;
        }

        .offset-md-6 {
            margin-left: 50%;
            margin-right: inherit;
        }

        .offset-md-7 {
            margin-left: 58.33333333%;
            margin-right: inherit;
        }

        .offset-md-8 {
            margin-left: 66.66666667%;
            margin-right: inherit;
        }

        .offset-md-9 {
            margin-left: 75%;
            margin-right: inherit;
        }

        .offset-md-10 {
            margin-left: 83.33333333%;
            margin-right: inherit;
        }

        .offset-md-11 {
            margin-left: 91.66666667%;
            margin-right: inherit;
        }
    }

    @media (min-width: 992px) {
        .offset-lg-0 {
            margin-left: 0;
            margin-right: inherit;
        }

        .offset-lg-1 {
            margin-left: 8.33333333%;
            margin-right: inherit;
        }

        .offset-lg-2 {
            margin-left: 16.66666667%;
            margin-right: inherit;
        }

        .offset-lg-3 {
            margin-left: 25%;
            margin-right: inherit;
        }

        .offset-lg-4 {
            margin-left: 33.33333333%;
            margin-right: inherit;
        }

        .offset-lg-5 {
            margin-left: 41.66666667%;
            margin-right: inherit;
        }

        .offset-lg-6 {
            margin-left: 50%;
            margin-right: inherit;
        }

        .offset-lg-7 {
            margin-left: 58.33333333%;
            margin-right: inherit;
        }

        .offset-lg-8 {
            margin-left: 66.66666667%;
            margin-right: inherit;
        }

        .offset-lg-9 {
            margin-left: 75%;
            margin-right: inherit;
        }

        .offset-lg-10 {
            margin-left: 83.33333333%;
            margin-right: inherit;
        }

        .offset-lg-11 {
            margin-left: 91.66666667%;
            margin-right: inherit;
        }
    }

    @media (min-width: 1280px) {
        .offset-xl-0 {
            margin-left: 0;
            margin-right: inherit;
        }

        .offset-xl-1 {
            margin-left: 8.33333333%;
            margin-right: inherit;
        }

        .offset-xl-2 {
            margin-left: 16.66666667%;
            margin-right: inherit;
        }

        .offset-xl-3 {
            margin-left: 25%;
            margin-right: inherit;
        }

        .offset-xl-4 {
            margin-left: 33.33333333%;
            margin-right: inherit;
        }

        .offset-xl-5 {
            margin-left: 41.66666667%;
            margin-right: inherit;
        }

        .offset-xl-6 {
            margin-left: 50%;
            margin-right: inherit;
        }

        .offset-xl-7 {
            margin-left: 58.33333333%;
            margin-right: inherit;
        }

        .offset-xl-8 {
            margin-left: 66.66666667%;
            margin-right: inherit;
        }

        .offset-xl-9 {
            margin-left: 75%;
            margin-right: inherit;
        }

        .offset-xl-10 {
            margin-left: 83.33333333%;
            margin-right: inherit;
        }

        .offset-xl-11 {
            margin-left: 91.66666667%;
            margin-right: inherit;
        }
    }

    /*----- Custom control -----*/

    .custom-control {
        padding-right: 1.5rem;
        padding-left: inherit;
    }

    .custom-control-inline {
        margin-right: 1rem;
        margin-left: inherit;
    }

    .custom-control-label {
        &::before,
        &::after {
            right: 0;
            left: inherit;
        }
    }

    .form-select {
        &[multiple],
        &[size]:not([size="1"]) {
            padding-right: 0.75rem;
            padding-left: inherit;
        }
    }

    div.dataTables_wrapper div.dataTables_filter input {
        margin-right: 0.5em;
        margin-left: inherit;
    }

    .form-file-label::after {
        left: 0;
        right: auto;
        border-left: 1px solid $border;
        border-radius: 3px 0 0 3px;
    }

    .form-range::-ms-fill-upper {
        margin-right: 15px;
        margin-left: inherit;
    }

    .custom-switch {
        padding-left: 0;
        padding-right: inherit;
    }

    .custom-switch-input:checked ~ .custom-switch-indicator:before {
        left: calc(1rem + 1px);
        right: inherit;
    }

    .custom-switch-description {
        margin-right: 0.5rem;
        margin-left: inherit;
    }

    .custom-switch-indicator:before {
        left: 1px;
        right: inherit;
    }

    .notifyimg {
        float: left;
        margin-right: 10px;
        margin-left: inherit;
    }

    .aside {
        right: 0;
        left: inherit;
        border-left: 1px solid $border;
    }

    @media (max-width: 992px) {
        .hor-mobile-header .animated-arrow.hor-toggle {
            margin: 20px 18px 0 0px;
        }
    }

    @media (max-width: 480px) {
        .header .nav-link .badge {
            right: 6px;
            left: inherit;
        }

        #user-profile .tabs-menu2 ul li a {
            padding: 10px 8px 11px 8px;
        }

        .tabs-menu ul li,
        .tabs-menu1 ul li {
            margin: 15px 15px 0 15px;
        }

        .error-page .display-1 {
            margin-right: 2px;
            margin-left: inherit;
        }
    }

    @media (max-width: 360px) {
        .breadcrumb {
            padding-right: 0;
            padding-left: inherit;
        }

        #user-profile .tabs-menu2 ul li a {
            padding: 10px 6px 11px 6px;
        }
    }

    .statistics-box {
        padding-left: 80px;
        padding-right: inherit;
        text-align: right;

        .ico {
            left: 0px;
            right: inherit;
        }
    }

    @-webkit-keyframes indeterminate {
        0% {
            left: -35%;
            right: 100%;
        }

        100%,
        60% {
            left: 100%;
            right: -90%;
        }
    }

    @keyframes indeterminate {
        0% {
            left: -35%;
            right: 100%;
        }

        100%,
        60% {
            left: 100%;
            right: -90%;
        }
    }

    @-webkit-keyframes indeterminate-short {
        0% {
            left: -200%;
            right: 100%;
        }

        100%,
        60% {
            left: 107%;
            right: -8%;
        }
    }

    @keyframes indeterminate-short {
        0% {
            left: -200%;
            right: 100%;
        }

        100%,
        60% {
            left: 107%;
            right: -8%;
        }
    }

    .status-icon {
        margin-right: 0.375rem;
        margin-left: inherit;
    }

    .chips .chip {
        margin: 0 0.5rem 0.5rem 0;
    }

    .chip .avatar {
        float: left;
        margin: 0 0.5rem 0 -0.75rem;
    }

    .example {
        border-radius: 3px 3px 0 0;
    }

    .colorinput-color:before {
        left: 0.25rem;
        right: inherit;
    }

    #back-to-top {
        left: 20px;
        right: inherit;
    }

    .action-header {
        padding: 15px 13px 15px 17px;
    }

    .ah-actions {
        float: right;
    }

    #ms-menu-trigger {
        left: 0;
        right: inherit;
        padding-right: 10px;
        padding-left: inherit;
    }

    .job-box-filter {
        label input.form-control {
            margin-left: 5px;
            margin-right: inherit;
        }

        a.filtsec {
            margin-right: 15px;
            margin-left: inherit;

            i {
                margin-right: 5px;
                margin-left: inherit;
            }
        }

        h4 i {
            margin-right: 10px;
            margin-left: inherit;
        }
    }

    .activity {
        border-left: 1px solid rgba(0, 0, 0, 0.125);
        margin-left: 16px;
        margin-right: inherit;

        .img-activity {
            left: -12px;
            right: inherit;
        }

        .item-activity {
            margin-left: 40px;
            margin-right: inherit;
        }

        .img-activity {
            left: -22px;
            right: inherit;
        }
    }

    .user-profile .box {
        left: 0;
        right: inherit;
    }

    .userprofile {
        float: left;
    }

    .settingbtn {
        right: 0px;
        left: inherit;
    }

    .userprofile.small {
        .userpic {
            margin: 0 10px 0 0;
            float: left;
        }

        .textcontainer {
            float: left;
        }

        .username {
            float: left;

            + p {
                float: left;
            }
        }
    }

    #user-profile {
        h2 {
            padding-right: 15px;
            padding-left: inherit;
        }

        .profile-stars > i {
            margin-left: -2px;
            margin-right: inherit;
        }

        .profile-details ul {
            margin-left: 40px;
            margin-right: inherit;
        }

        .profile-header .edit-profile {
            right: 0;
            left: inherit;
        }

        .profile-user-info .profile-user-details .profile-user-details-label {
            float: left;
            left: 0;
            right: inherit;
            text-align: right;
        }

        .profile-image {
            right: 30%;
            left: inherit;
        }
    }

    @media only screen and (max-width: 767px) {
        #user-profile .profile-user-info .profile-user-details .profile-user-details-value {
            margin-left: 0;
            margin-right: inherit;
        }
    }

    .main-box h2 {
        margin: 0 0 15px -20px;
        padding: 5px 0 5px 20px;
        border-left: 10px solid #c2c2c2;

        /*7e8c8d*/
    }

    .conversation-inner {
        padding: 0 0 5px 0;
        margin-right: 10px;
        margin-left: inherit;
    }

    .conversation-user {
        float: left;
    }

    .conversation-body {
        margin-left: 60px;
        margin-right: inherit;

        &:before {
            left: -12px;
            right: inherit;
        }
    }

    .conversation-item.item-right {
        .conversation-body {
            margin-left: 0;
            margin-right: 60px;

            &:before {
                left: auto;
                right: -12px;
            }
        }

        .conversation-user {
            float: right;
        }
    }

    .conversation-body > .time {
        right: 10px;
        left: inherit;
    }

    @media (max-width: 575.98px) {
        .header .form-inline .search-element .form-control {
            float: left;
        }
    }

    #total-customers {
        left: -11px;
        right: inherit;
    }

    #resolved-complaints {
        left: -4px;
        right: inherit;
    }

    .socailicons i {
        right: -29px;
        left: inherit;
    }

    .settings {
        right: 4px;
        left: inherit;
    }

    .setting-1 {
        right: -45px;
        left: inherit;
    }

    .usertab-list li,
    .memberblock,
    .member {
        float: left;
    }

    .hover15 .card::before {
        left: 50%;
        right: inherit;
    }

    #action_menu_btn {
        right: 36px;
        left: inherit;
    }

    .action_menu {
        right: 15px;
        left: inherit;
    }

    @media only screen and (max-width: 1279px) and (min-width: 992px) {
        #orders {
            left: -6px;
            right: inherit;
        }
    }

    @media screen and (max-width: 998px) and (min-width: 768px) {
        .note-popover .popover-content,
        .card-header.note-toolbar {
            padding: 14px 0 5px 5px !important;
        }
    }

    .material-switch > {
        label::after {
            right: -4px;
            left: inherit;
        }

        input[type="checkbox"]:checked + label::after {
            right: 20px;
            left: inherit;
        }
    }

    .hor-menu .desktop-logo {
        margin-right: 0;
        margin-left: inherit;
    }

    .clipboard-icon {
        right: 10px;
        left: inherit;
    }

    *::-ms-backdrop,
    .custom-control-label::before,
    *::-ms-backdrop,
    .custom-control-label::after {
        left: -25px;
        right: inherit;
    }

    @media (min-width: 992px) {
        .main-header-center .btn {
            left: 12px;
            right: inherit;
            padding-left: 13px;
            padding-right: inherit;
        }
    }

    .header {
        .btn {
            margin-right: 171px;
            margin-left: inherit;
        }

        .main-header-center {
            padding-right: 30px;
            padding-left: inherit;
        }
    }

    .pulse-danger,
    .pulse {
        right: 8px;
        left: inherit;

        &:before {
            right: -4px;
            left: inherit;
        }
    }

    .activity-details {
        margin-right: 15px;
        margin-left: inherit;
    }

    .activity-blog:before {
        right: 23px;
        left: inherit;
        border-right: 1px solid #e0e9f1;
        border-left: inherit;
    }

    @media (min-width: 992px) {
        .sidebar-mini.sidenav-toggled {
            .side-menu {
                padding: 0 0px 40px 00px;
            }

            .side-badge,
            .slide-menu open.slide-menu hide .side-badge {
                left: 22px;
                right: inherit;
            }
        }
    }

    @media (max-width: 991px) {
        .sidenav-toggled .app-header.header {
            padding-left: 0px !important;
            padding-right: inherit;
        }

        .side-menu {
            padding: 60px 0px 0 15px !important;
        }

        .app-content .side-app {
            padding: 0px 15px 0 15px !important;
        }
    }

    @media (max-width: 991px) {
        .dropdown-menu.header-search:before {
            left: 15px !important;
            right: inherit;
        }
    }

    .profile-status {
        right: 103px;
        left: inherit;
    }

    .task-list {
        li .task-icon {
            right: 10px;
            left: inherit;
        }

        &:before {
            right: 15px;
            left: inherit;
            border-right: 1px solid #e0e9f1;
            border-left: inherit;
        }
    }

    @media (max-width: 360px) {
        .dropdown-menu.header-search:before {
            left: 200px !important;
            right: inherit;
        }
    }

    .mail-inbox .icons {
        margin-left: 8px;
    }

    .mail-option {
        .btn-group,
        .chk-all,
        .btn-group,
        .chk-all {
            margin-right: 5px;
            margin-left: inherit;
        }
    }
    .acc-card {
        .acc-header {
            .acc-angle {
                .fe-chevron-right:before {
                    content: "\e92e";
                }
            }
        }
    }
    ul.inbox-pagination {
        float: left;

        li {
            float: left;
        }
    }

    .inbox-pagination {
        li span {
            margin-right: 5px;
            margin-left: inherit;
        }

        a.np-btn {
            margin-left: 5px;
            margin-right: inherit;
        }
    }

    .acc-body {
        border-radius: 0 0 5px 5px;
    }

    .panel-heading1 a.active {
        border-radius: 5px 5px 0 0;
    }

    .card-pay .tabs-menu li {
        a {
            border-left: 1px solid $border;
        }

        &:last-child a {
            border-right: 0;
        }
    }

    .form-wizard-title {
        margin-right: 10px;
        margin-left: inherit;
    }

    .error-bg {
        border-top-left-radius: 6px;
        border-bottom-left-radius: 6px;
    }

    .sidebar .tabs-menu ul li a {
        padding: 15px 13px 15px 11px;
    }

    .product-grid6 {
        .price span {
            margin-left: 7px;
            margin-right: inherit;
        }

        .icons li a {
            &:after,
            &:before {
                left: 50%;
                right: inherit;
            }
        }
    }

    [type="email"],
    [type="number"],
    [type="tel"],
    [type="url"] {
        direction: rtl;
    }

    .lg-toolbar .lg-icon {
        float: left !important;
    }

    .symbol-input100 {
        padding-right: 22px !important;
        padding-left: inherit;
    }

    .input100 {
        padding: 0 54px 0 30px !important;
    }

    .datepicker {
        right: auto !important;
        left: inherit;
    }

    .ms-choice > {
        div {
            left: 10px !important;
            right: auto !important;
        }

        span {
            right: 0 !important;
            left: auto !important;
            padding-right: 8px !important;
            padding-left: inherit;
        }
    }

    .iti__flag-box,
    .iti__country-name {
        margin-left: 6px !important;
        margin-right: inherit;
    }

    .iti__country-list {
        text-align: right !important;
    }

    .tab_wrapper .content_wrapper .accordian_header .arrow {
        float: left !important;
    }

    @media (min-width: 992px) {
        .dropdown-menu.header-search:before {
            right: 28px;
            left: inherit;
            border-right: 1px solid $border;
        }
    }

    .sw-btn-group-extra {
        &.btn-group > .btn:first-child {
            border-right: 1px solid rgba(255, 255, 255, 0.13) !important;
        }

        .btn + .btn {
            margin-left: 0;
            margin-right: inherit;
        }
    }

    .imagecheck-figure:before {
        left: 0.25rem;
        right: inherit;
    }

    .demo-gallery {
        > ul > li a {
            float: left;

            .demo-gallery-poster {
                left: 0;
                right: 0;

                > img {
                    left: 50%;
                    right: inherit;
                    margin-left: -10px;
                    margin-right: inherit;
                }
            }
        }

        .justified-gallery > a .demo-gallery-poster > img {
            left: 50%;
            right: inherit;
            margin-left: -10px;
            margin-right: inherit;
        }

        .video .demo-gallery-poster img {
            margin-left: -24px;
            margin-right: inherit;
        }
    }

    .img-float {
        right: 1rem;
        left: inherit;
    }

    .gallery a img,
    .img-card:before {
        float: left;
    }

    .label-size {
        margin-left: 9px;
        margin-right: inherit;
    }

    .list-group {
        padding-left: 0;
        padding-right: initial;
    }

    .list-inline-dots .list-inline-item + .list-inline-item:before {
        margin-left: -2px;
        margin-right: 3px;
    }

    .list-style-1 {
        list-style: inherit;
        padding-right: inherit;

        ul {
            padding-right: 30px;
            padding-left: inherit;
        }
    }

    .list-style2,
    .list-style3,
    .list-style4,
    .list-style5,
    .list-style6 {
        padding-right: inherit;

        ul {
            padding-right: 30px;
            padding-left: inherit;
        }
    }

    /*------ Media object ------*/

    .media-right,
    .media > .pull-right {
        padding-left: 10px;
        padding-right: inherit;
    }

    .media-left,
    .media > .pull-left {
        padding-right: 10px;
        padding-left: inherit;
    }

    .list-media {
        .media-img {
            float: left;
            margin-right: 20px;
            margin-left: inherit;
        }

        .info {
            padding-left: 55px;
            padding-right: inherit;

            .text-right {
                right: 0;
                left: inherit;
            }
        }
    }

    .btn-close {
        float: left;
    }

    @media (max-width: 768px) {
        .dropdown-menu.dropdown-menu-right {
            left: 0 !important;
            right: inherit;
        }
    }

    .pricing1 .card-category {
        border-radius: 7px 7px 0 0;
    }

    .col-sm-4 .pricing-table .table td {
        text-align: left;
    }

    .pricing-divider-img {
        left: 0;
        right: inherit;
    }

    .price-detail-wrap .rating-stars {
        text-align: left !important;
    }

    .br-theme-fontawesome-stars .br-widget a {
        margin-right: 2px;
        margin-left: inherit;
    }

    .box-example-1to10 .br-wrapper {
        margin: 0px 0 0 -105px;
        left: 50%;
        right: inherit;
    }

    .box-example-movie .br-wrapper {
        margin: 0px 0 0 -125px;
        left: 50%;
        right: inherit;
    }

    .box-example-square .br-wrapper {
        margin: 0px 0 0 -95px;
        left: 50%;
        right: inherit;
    }

    .box-example-pill .br-wrapper {
        margin: 0px 0 0 -116px;
        left: 50%;
        right: inherit;
    }

    .box-example-horizontal .br-wrapper {
        margin: 0px 0 0 -60px;
        left: 50%;
        right: inherit;
    }

    .tabs-menu ul li a {
        padding: 10px 12px 11px 13px;
    }

    .timeline:before {
        left: 4px;
        right: inherit;
    }

    .timeline-item {
        padding-left: 2rem;
        padding-right: inherit;

        &:first-child:before,
        &:last-child:before {
            left: 0.25rem;
            right: inherit;
        }
    }

    .timeline-badge {
        left: 1px;
        right: inherit;
    }

    .timeline-time {
        margin-left: auto;
        margin-right: inherit;
    }

    .timeline__item--right .timeline__content:before {
        border-right: 12px solid rgba(238, 232, 239, 0.9);
    }

    ul.timeline {
        &:before {
            left: 29px;
            right: inherit;
        }

        > li {
            padding-left: 20px;
            padding-right: inherit;

            &:before {
                left: 20px;
                right: inherit;
            }
        }
    }

    .cbp_tmtimeline {
        &:before {
            left: 20%;
            right: inherit;
            margin-left: -6px;
            margin-right: inherit;
        }

        > li {
            &:nth-child(odd) .cbp_tmlabel:after {
                border-right-color: #fff;
            }

            .cbp_tmtime {
                padding-right: 70px;
                padding-left: inherit;

                span {
                    text-align: right;
                }
            }

            .cbp_tmlabel {
                margin: 0 0 30px 25%;

                &:after {
                    right: 100%;
                    left: inherit;
                    border-right-color: #fff;
                }

                h2 {
                    padding: 0 0 5px 0;
                }
            }

            .cbp_tmicon {
                left: 20%;
                right: inherit;
                margin: 0 0 0 -25px;
            }
        }
    }

    @media screen and (max-width: 992px) and (min-width: 768px) {
        .cbp_tmtimeline {
            > li {
                .cbp_tmtime {
                    padding-right: 60px;
                    padding-left: inherit;
                }

                .cbp_tmicon {
                    left: 23%;
                    right: inherit;
                }
            }

            &:before {
                left: 23%;
                right: inherit;
            }

            > li .cbp_tmlabel {
                margin: 0 0 15px 30%;
            }
        }
    }

    @media screen and (max-width: 47.2em) {
        .cbp_tmtimeline > li {
            .cbp_tmtime {
                padding: 0 0 20px 0;

                span {
                    text-align: left;
                }
            }

            .cbp_tmlabel {
                margin: 0 0 30px 0;

                &:after {
                    right: auto;
                    left: 20px;
                    border-right-color: transparent;
                }
            }

            .cbp_tmicon {
                float: right;
                left: auto;
                right: inherit;
                margin: -64px 5px 0 0px;
            }

            &:nth-child(odd) .cbp_tmlabel:after {
                border-right-color: transparent;
            }
        }
    }

    .vtimeline {
        &:before {
            left: 50%;
            right: inherit;
            margin-left: -1.5px;
            margin-right: inherit;
        }

        .timeline-wrapper {
            padding-left: 90px;
            padding-right: inherit;

            .timeline-panel {
                margin-right: 15%;
                margin-left: inherit;

                &:after {
                    left: -14px;
                    right: inherit;
                    border-right: 14px solid #f2f3f9;
                    border-left: 0 solid #f2f3f9;
                }
            }

            .timeline-badge {
                left: calc(50% - 7px);
                right: inherit;
            }

            &.timeline-inverted {
                padding-left: 0;
                padding-right: 90px;

                .timeline-panel {
                    margin-right: auto;
                    margin-left: 15%;

                    &:after {
                        border-right-width: 0;
                        border-left-width: 14px;
                        right: -14px;
                        left: auto;
                    }
                }
            }
        }
    }

    @media (max-width: 767px) {
        .vtimeline {
            .timeline-wrapper {
                padding-left: 0 !important;
                padding-right: inherit;

                .timeline-badge {
                    right: 0 !important;
                    left: inherit;
                }

                .timeline-panel {
                    margin-right: 10% !important;
                    margin-left: inherit !important;
                }

                &.timeline-inverted {
                    padding-right: 0 !important;
                    padding-left: inherit;

                    .timeline-panel {
                        margin-left: 0 !important;
                        margin-right: inherit;
                    }
                }
            }

            &:before {
                right: 6px !important;
                left: inherit;
            }
        }
        .vtimeline .timeline-wrapper .timeline-panel:after {
            border-left: 14px solid #cad4e4 !important;
            border-right: 0 solid #cad4e4 !important;
            right: -14px !important;
            left: auto !important;
        }
    }

    /* ######## LAYOUT-STYLES ######## */

    .footer .social ul li {
        float: right;
    }

    .footer-payments li:not(:last-child) {
        margin-left: 1.5rem;
        margin-right: inherit;
    }

    .main-footer {
        padding: 20px 30px 20px 280px;
    }

    .footer-social-list {
        padding-left: 0;
        padding-right: inherit;

        li:not(:last-child) {
            margin-left: 1.5rem;
            margin-right: inherit;
        }
    }

    .app-header .header-brand,
    .app-header1 .header-brand {
        margin-right: 0;
        margin-left: inherit;

        .header-brand-img {
            margin-right: 0;
            margin-left: inherit;
        }
    }

    // .header-brand-img {
    //     margin-left: .5rem;
    //     margin-right: inherit;
    // }

    @media (max-width: 991px) {
        .header .form-inline {
            margin-right: -2px;
            margin-left: inherit;
        }
    }

    @media (max-width: 991px) {
        .header-brand-img {
            margin-right: 0;
            margin-left: 1.5rem;
        }

        .mobile-header.hor-mobile-header .header-brand-img {
            margin-left: 2.5rem;
            margin-right: inherit;
        }
    }

    @media (max-width: 992px) and (min-width: 768px) {
        .hor-header .header-brand-img {
            &.desktop-logo,
            &.light-logo {
                margin-left: 3rem;
                margin-right: inherit;
            }
        }

        .animated-arrow {
            padding: 25px 0px 16px 35px !important;
            margin: 11px 0 0 15px;
        }
    }

    @media (max-width: 568px) {
        .mobile-header .header-brand-img {
            margin-left: 0rem;
            margin-right: inherit;
        }
    }

    .header {
        .form-inline {
            .form-control {
                padding-left: 20px;
                padding-right: 40px;
            }

            .btn {
                margin-right: -56px;
                margin-left: inherit;
            }
        }

        .bg-image,
        .bg-image-2,
        .bg-image-3 {
            left: 0;
            right: inherit;
        }
    }

    @media (max-width: 767.98px) and (min-width: 576px) {
        .search-element .form-control {
            float: left;
        }
    }

    .header-1 {
        .nav-link .badge {
            right: 6px;
            left: inherit;
        }

        .form-inline .btn {
            border-bottom-right-radius: 3px;
            border-top-right-radius: 3px;
            margin-right: -50px;
            margin-left: inherit;
        }

        .form-control.header-search {
            color: #0d0c22;
        }
    }

    @media only screen and (max-width: 991px) {
        .header-1 {
            .hor-toggle.animated-arrow {
                padding: 83px 35px 16px 20px !important;
                margin: 11px 0 0 0px !important;
            }

            .animated-arrow {
                margin: 0px 0 0 -11px;
            }
        }

        .header-2 .animated-arrow {
            margin: 0px 0 0 -11px;
        }

        .header-1 .navbar .nav-link.nav-link-lg i {
            padding: 0px 42px 0px 10px;
        }
    }

    .header-2 {
        .nav-link .badge {
            right: 8px;
            left: inherit;
        }

        .form-inline .btn {
            border-bottom-right-radius: 3px;
            border-top-right-radius: 3px;
            margin-left: -45px;
        }
    }

    /*--header-2--*/
    @media only screen and (max-width: 991px) {
        .header-2 {
            .animated-arrow {
                cursor: pointer;
                padding: 8px 0px 3px 33px;
                margin: 11px 0 0 -11px;
            }

            .nav-link .badge {
                right: 6px;
                left: inherit;
            }

            .navbar-nav .dropdown-menu {
                float: right;
            }

            .dropdown-menu.dropdown-menu-right {
                left: 0px;
                right: inherit;
            }
        }
    }

    /*--header-3--*/

    .header-3 {
        .nav-link .badge {
            right: 10px;
            left: inherit;
        }

        .form-inline {
            .btn {
                border-bottom-right-radius: 3px;
                border-top-right-radius: 3px;
                margin-left: -56px;
                margin-right: inherit;
            }

            .form-control::-webkit-input-placeholder {
                color: #76839a;
            }
        }
    }

    @media only screen and (max-width: 991px) {
        .header-3 {
            .animated-arrow {
                cursor: pointer;
                padding: 32px 33px 3px 0px;
                margin: 11px 0 0 -11px;
            }

            .navbar-nav .dropdown-menu {
                float: right;
            }

            .dropdown-menu.dropdown-menu-right {
                left: 0px;
                right: inherit;
            }
        }
    }

    .header-style .header-brand {
        margin-left: 0 !important;
        margin-right: inherit;
    }

    .hor-header .header-brand-img {
        margin-right: 0;
        margin-left: inherit;
    }

    /*Logo-center header */
    @media (min-width: 991px) {
        .hor-topheader .header-brand2 {
            margin-left: auto;
            margin-right: inherit;
        }
    }

    @media (max-width: 992px) and (min-width: 768px) {
        .hor-topheader .header-brand-img.main-logo {
            margin-left: 3rem;
            margin-right: inherit;
        }
    }

    .hor-topheader .dropdown-menu-arrow.dropdown-menu-right {
        &:before,
        &:after {
            right: auto;
            left: 17px;
        }
    }

    .header-2 .navbar-nav.navbar-right.me-auto {
        margin-left: auto !important;
        margin-right: inherit !important;
    }

    .desktoplogo {
        float: left;
    }

    .horizontalMenu > .horizontalMenu-list {
        text-align: left;

        > li {
            float: right;

            > a {
                border-left: 1px solid $border;
            }

            &:last-child > a {
                border-left: 0px;
            }

            > a {
                i {
                    margin-left: 4px;
                    margin-right: inherit;
                }

                .arrow:after {
                    float: right;
                    right: 11px;
                    left: inherit;
                    margin: 0 0 0 14px;
                    text-align: right;
                }
            }

            &.rightmenu {
                float: right;

                a {
                    padding: 0px 30px 0px 20px;
                    border-right: none;
                }

                > .topmenusearch {
                    float: right;
                    margin: 16px 0px 0px 0px;

                    input {
                        float: right;
                        right: 0;
                        left: inherit;
                    }

                    .btnstyle {
                        right: 0;
                        left: inherit;
                    }
                }
            }

            > {
                ul.sub-menu > li > {
                    a {
                        border-right: 0 none;
                        text-align: right;
                        border-right: 0px solid;

                        > i {
                            margin-right: 9px;
                            margin-left: inherit;
                        }
                    }

                    ul.sub-menu {
                        right: 103%;
                        left: inherit;

                        > li > {
                            a {
                                border-right: 0 none;
                                text-align: right;
                                border-right: 0px solid;

                                > i {
                                    margin-right: 9px;
                                    margin-left: inherit;
                                }
                            }

                            ul.sub-menu {
                                right: 100%;
                                left: inherit;

                                > li > a {
                                    text-align: right;
                                    border-right: 0px solid;

                                    > i {
                                        margin-right: 9px;
                                        margin-left: inherit;
                                    }
                                }
                            }
                        }
                    }
                }

                .horizontal-megamenu {
                    left: 0px;
                    right: inherit;
                    text-align: left;

                    .title {
                        padding: 5px 5px 5px 0px;
                        margin: 0px 0px 4px 0px;
                        text-align: left;
                    }

                    .link-list li {
                        text-align: right;
                        padding: 5px 10px 5px 10px;

                        a {
                            text-align: right;
                            border-right: 0 none;
                        }
                    }

                    li i {
                        margin-right: 5px;
                        margin-left: inherit;
                        margin-right: 5px;
                        margin-left: inherit;
                    }

                    .menu_form input {
                        &[type="submit"] {
                            float: right;
                            margin-right: 15px;
                            margin-left: inherit;
                        }

                        &[type="button"] {
                            float: right;
                        }
                    }
                }
            }
        }
    }

    /*==============================================================================
                                    Start Mobile CSS
    ===============================================================================*/
    /* ================== Mobile Menu Change Brake Point ================== */
    @media only screen and (max-width: 991px) {
        .horizontalMenu {
            left: 0;
            right: inherit;

            > .horizontalMenu-list > li {
                border-right: none;
                clear: right;

                > {
                    a {
                        padding: 8px 17px 8px 32px;
                        text-align: right;
                        border-right: solid 0px;
                    }

                    ul.sub-menu > li {
                        span + a {
                            padding-left: 30px;
                            padding-right: inherit;
                        }

                        > ul.sub-menu {
                            left: 100%;
                            right: inherit;

                            > li {
                                > a {
                                    padding: 10px 26px 10px 0px;
                                }

                                span + a {
                                    padding-left: 30px;
                                    padding-right: inherit;
                                }

                                > ul.sub-menu {
                                    left: 100%;
                                    right: inherit;

                                    > li {
                                        > a {
                                            padding: 10px 0px 10px 34px;
                                        }

                                        span + a {
                                            padding-left: 30px;
                                            padding-right: inherit;
                                        }
                                    }
                                }
                            }
                        }
                    }

                    .horizontal-megamenu {
                        .title {
                            padding: 10px 8px 10px 0px;
                        }

                        .menu_form {
                            padding: 5px 0px 62px 0px;
                        }
                    }
                }
            }
        }

        /* ================== Mobile Slide Down Links CSS ================== */
        /* ================== Mobile Mega Menus CSS  ================== */
        /* ================== Mobile Header CSS ================== */

        .horizontal-header {
            left: 0;
            right: inherit;
        }

        .callusbtn {
            right: 5px;
            left: inherit;
        }

        /* Mobile Toggle Menu icon (X ICON) */

        .animated-arrow {
            right: 0;
            left: inherit;
            padding: 16px 0px 16px 35px;
            margin: 11px 15px 0 0px;
        }

        /* ================== Mobile Overlay/Drawer CSS ================== */

        .horizontal-overlapbg {
            left: 0;
            right: inherit;
        }

        .active .horizontalMenu > .horizontalMenu-list {
            margin-right: 0;
            margin-left: inherit;
        }

        /* ================== Mobile Sub Menu Expander Arrows  ================== */

        .horizontalMenu > .horizontalMenu-list > li > {
            .horizontalMenu-click {
                right: 0;
                left: inherit;

                > i {
                    margin-left: 10px;
                    margin-right: inherit;
                    float: left;
                }
            }

            ul.sub-menu > li {
                .horizontalMenu-click02,
                .horizontalMenu-click03,
                .horizontalMenu-click04 {
                    right: 0;
                    left: inherit;

                    > i {
                        float: right;
                    }
                }
            }
        }

        /*End Media Query*/
    }

    .hor-menu .horizontalMenu > .horizontalMenu-list > li {
        &:first-child {
            border-right: 1px solid $border;
        }

        &:last-child {
            border-left: 1px solid $border;
        }
    }

    .horizontalMenu > .horizontalMenu-list > li > ul.sub-menu .sub-menu-sub:after {
        left: 14px;
        right: inherit;
    }

    .sub-menu li a:before,
    .mega-menubg li a:before {
        margin-left: 5px;
        margin-right: inherit;
        float: right;
    }

    .icons-list {
        margin: 0 -1px -1px 0;
    }

    .input-icon-addon {
        left: 0;
        right: inherit;

        &.search-icon {
            right: auto !important;
            left: 0 !important;
        }

        &:last-child {
            left: auto;
            right: 0;
        }
    }

    .icon-absolute {
        right: 10px;
        left: inherit;
    }

    .item-card2-desc ul li {
        margin-right: 1.5rem;
        margin-left: inherit;

        &:last-child {
            margin-right: 0;
            margin-left: inherit;
        }
    }

    /*--item-card2--*/

    .item-card2-icons {
        right: 15px;
        left: inherit;
    }

    .item-list .list-group-item i {
        margin-right: 5px;
        margin-left: inherit;
    }

    .double-bounce1,
    .double-bounce2,
    .cube1,
    .cube2 {
        left: 0;
        right: inherit;
    }

    .lds-heart div {
        left: 19px;
        right: inherit;

        &:before {
            left: -17px;
            right: inherit;
        }
    }

    .ui-datepicker {
        .ui-datepicker-header {
            .ui-datepicker-next:before {
                right: 5px;
                left: inherit;
            }

            .ui-datepicker-prev:before {
                left: 5px;
                right: inherit;
            }
        }

        .ui-datepicker-calendar td:last-child {
            border-right: 0;
        }
    }

    .ui-datepicker-multi {
        .ui-datepicker-group {
            padding-right: 15px;
            padding-left: inherit;
            float: left;

            .ui-datepicker-prev::before {
                left: 10px;
                right: inherit;
            }

            .ui-datepicker-next::before {
                right: 10px;
                left: inherit;
            }
        }

        .ui-datepicker-group-last {
            padding-right: 0;
            padding-left: inherit;
        }
    }

    .jvectormap-zoomin,
    .jvectormap-zoomout,
    .jvectormap-goback {
        left: 10px;
        right: inherit;
    }

    .jvectormap-legend-cnt-h,
    .jvectormap-legend-cnt-v {
        right: 0;
        left: inherit;
    }

    .jvectormap-legend-cnt-h .jvectormap-legend {
        float: left;
        margin: 0 10px 10px 0;

        .jvectormap-legend-tick {
            float: left;
        }
    }

    .jvectormap-legend-cnt-v {
        .jvectormap-legend {
            margin: 10px 10px 0 0;
        }

        .jvectormap-legend-tick-text {
            padding-left: 3px;
            padding-right: inherit;
        }
    }

    .selectgroup-item {
        + .selectgroup-item {
            margin-left: -1px;
        }

        &:not(:first-child) .selectgroup-button {
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
        }

        &:not(:last-child) .selectgroup-button {
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
        }
    }

    .selectgroup-input {
        left: 0;
        right: inherit;
    }

    .selectize-dropdown-header-close {
        right: 8px;
        left: inherit;
    }

    .selectize-dropdown.plugin-optgroup_columns .optgroup {
        border-right: 1px solid #f2f2f2;
        float: left;

        &:last-child {
            border-right: 0 none;
        }
    }

    .selectize-control {
        &.plugin-remove_button {
            [data-value] {
                padding-right: 24px !important;
                padding-left: inherit !important;

                .remove {
                    right: 0;
                    left: inherit;
                    border-left: 1px solid #d0d0d0;
                }

                &.active .remove {
                    border-left-color: #cacaca;
                }
            }

            .disabled [data-value] .remove {
                border-left-color: #fff;
            }

            .remove-single {
                right: 28px;
                left: inherit;
            }
        }

        &.multi .selectize-input.has-items {
            padding: 7px 0.75rem 4px 7px;
        }

        &.single .selectize-input:after {
            right: 12px;
            left: inherit;
        }
    }

    .selectize-dropdown .image {
        margin: -1px 0.5rem -1px -4px;
        float: left;
    }

    .selectize-input .image {
        margin: -1px 0.5rem -1px -4px;
        float: left;
        margin: -3px 0.75rem -3px -5px;
    }

    /* ######## TEMP-STYLES ######## */
    @media (max-width: 320px) {
        .fc-toolbar .fc-right {
            float: right;
        }
    }

    .richText .richText-toolbar ul li a {
        border-right: rgba(156, 162, 161, 0.2) solid 1px;
    }

    .cal1 .clndr .clndr-table {
        .header-days .header-day {
            border-left: 1px solid rgba(255, 255, 255, 0.1);
        }

        tr {
            .empty,
            .adjacent-month,
            .my-empty,
            .my-adjacent-month {
                border-left: 1px solid $border;
            }

            .day {
                border-right: 1px solid $border;

                &:last-child {
                    border-right: 1px solid $border;
                }
            }
        }
    }

    #AreaChart5 {
        left: -7px;
        right: inherit;
    }

    ul li .legend-dots {
        margin-right: 0.5rem;
        margin-left: inherit;
    }

    .circle-icon {
        margin-right: 13px;
        margin-left: inherit;
        float: left;
    }

    .square-icon {
        float: right;
        border-radius: 7px 0px 0px 7px;
    }

    .main-nav-line-chat {
        padding-left: 20px;
        padding-right: inherit;

        .nav-link + .nav-link {
            margin-left: 0px;
            margin-right: inherit;
        }
    }

    @media (min-width: 992px) {
        .main-chat-contacts-wrapper {
            padding: 10px 15px 10px 0;
        }
    }

    .main-chat-contacts {
        padding-left: 20px;
        padding-right: inherit;
    }

    .main-chat-list {
        .main-img-user span {
            left: -2px;
            right: inherit;
        }

        .media-body {
            margin-right: 15px;
            margin-left: inherit;
        }
    }

    .main-chat-header {
        .nav {
            margin-right: auto;
            margin-left: inherit;
        }

        .nav-link + .nav-link {
            margin-right: 15px;
            margin-left: inherit;
        }
    }

    .main-chat-msg-name {
        margin-right: 15px;
        margin-left: inherit;
    }

    @media (min-width: 576px) {
        .main-chat-body .media.flex-row-reverse .media-body {
            margin-right: 55px;
            margin-left: inherit;
        }
    }

    .main-chat-body .media-body {
        margin-right: 20px;
        margin-left: inherit;
    }

    @media (min-width: 576px) {
        .main-chat-body .media-body {
            margin-left: 55px;
        }
    }

    .main-chat-footer .nav-link + .nav-link {
        margin-right: 10px;
        margin-left: inherit;
    }

    @media (min-width: 576px) {
        .main-chat-footer .form-control {
            margin-left: 20px;
            margin-right: inherit;
        }
    }

    .main-content-left-contacts {
        .main-content-breadcrumb,
        .main-content-title {
            padding-left: 20px;
            padding-right: inherit;
        }
    }

    @media (min-width: 992px) {
        .main-content-left-contacts {
            .main-content-breadcrumb,
            .main-content-title {
                padding-left: 0;
                padding-right: inherit;
            }
        }
    }

    .main-chat-list .main-img-user span {
        left: -2px;
        right: inherit;
    }

    .main-chat-body .media.flex-row-reverse .media-body {
        margin-right: 0;
        margin-left: 20px;
    }

    /* ###### Chat  ###### */

    .chat-left .main-msg-wrapper:first-child:before {
        left: auto;
        right: -12px;
        transform: rotate(90deg);
    }

    .chat-right .main-msg-wrapper:first-child:before {
        right: auto;
        left: -12px;
        transform: rotate(-90deg);
    }

    .authentication {
        input {
            padding-left: 32px !important;
            padding-right: inherit !important;
        }

        .flex-c-m .login100-social-item i {
            margin-left: 0;
            margin-right: inherit;
        }
    }

    .container-login100 .box ul li {
        float: right;
    }

    .range output {
        border-bottom-left-radius: 0;
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
        border-top-left-radius: 0;
    }

    .ribbon1 {
        left: 10px;
        right: inherit;

        &:after {
            border-left: 20px solid transparent;
            border-right: 24px solid transparent;
        }

        span {
            border-top-right-radius: 8px;

            &:before {
                left: -6px;
                right: inherit;
            }

            &:after {
                left: -8px;
                right: inherit;
                border-radius: 8px 8px 0 0;
            }
        }
    }

    .ribbon {
        left: -5px;
        right: inherit;
        text-align: right;

        span {
            left: -21px;
            right: inherit;
            -webkit-transform: rotate(-45deg);
            transform: rotate(-45deg);

            &::before {
                left: 0px;
                right: inherit;
                border-left: 3px solid #f8463f;
                border-right: 3px solid transparent;
            }

            &::after {
                right: 0px;
                left: inherit;
                border-left: 3px solid transparent;
                border-right: 3px solid #f8463f;
            }
        }
    }

    .wizard {
        > .steps {
            > ul li {
                .title,
                + li {
                    margin-left: 5px;
                    margin-right: inherit;
                }
            }

            a {
                .number,
                &:hover .number,
                &:active .number {
                    margin-left: 5px;
                    margin-right: inherit;
                }
            }
        }

        &.vertical > .steps ul li + li {
            margin-left: 10px;
            margin-right: inherit;
        }
    }

    @media (min-width: 576px) {
        .wizard > .steps > ul li .title {
            margin-right: 10px;
            margin-left: inherit;
        }
    }

    @media (min-width: 576px) {
        .wizard > .steps > ul li + li {
            margin-left: 20px;
            margin-right: inherit;
        }
    }

    @media (min-width: 992px) {
        .wizard > .steps > ul li + li {
            margin-right: 30px;
            margin-left: inherit !important;
        }
    }

    @media (min-width: 576px) {
        .wizard.vertical > .steps {
            float: right;
        }
    }

    @media (min-width: 576px) {
        .wizard.vertical > .steps ul li + li {
            margin-right: 0;
            margin-left: inherit !important;
        }
    }

    @media (min-width: 576px) {
        .wizard.vertical > .content {
            float: right;
        }
    }

    @media (min-width: 576px) {
        .wizard.vertical > .actions {
            float: left;
            border-right: 1px solid #e9edf4;
        }
        .wizard.vertical > .content {
            border-right: 1px solid #e9edf4;
            border-left: inherit;
        }
    }

    .wizard-style-2 > .steps > ul a {
        .title,
        &:hover .title,
        &:active .title {
            margin-right: 20px;
            margin-left: inherit !important;
        }
    }

    /*--- WIZARD ELEMENTS ---*/

    .weather-card {
        .top {
            .wrapper .mynav {
                .lnr-chevron-left {
                    float: left;
                }

                .lnr-cog {
                    float: right;
                }
            }

            &:after {
                left: 0;
                right: inherit;
            }
        }

        .bottom .wrapper .forecast li .condition {
            float: right;

            .temp .deg {
                margin-left: 3px;
                margin-right: inherit;
            }
        }
    }

    @media (max-width: 360px) {
        .wideget-user-desc .user-wrap {
            margin-right: 13px;
            margin-left: inherit !important;
        }

        .wideget-user-info .wideget-user-warap .wideget-user-warap-r {
            margin-left: 5rem !important;
            margin-right: inherit;
        }
    }

    .widgets-cards .wrp.icon-circle {
        margin-right: 15px;
        margin-left: inherit !important;
    }

    .widget-line-list li {
        padding: 5px 20px 0 15px;
    }

    .widget-card-1 i {
        right: 11px;
        left: inherit;
    }

    .wideget-user-desc .wideget-user-img img {
        margin-left: 2rem;
        margin-right: inherit;
    }

    .wideget-user-info .wideget-user-warap .wideget-user-warap-r {
        margin-left: 10rem;
        margin-right: inherit;
    }

    .widget-drop .dropdown-menu {
        left: 0px;
        right: inherit;
    }

    #widgetChart1,
    #widgetChart2,
    #widgetChart3 {
        left: -17px;
        right: inherit;
    }

    .embed-responsive {
        .embed-responsive-item,
        iframe,
        embed,
        object,
        video {
            left: 0;
            right: inherit;
        }
    }

    .primary .pricing-divider,
    .secondary .pricing-divider,
    .info .pricing-divider,
    .success .pricing-divider {
        padding: 1em 0 4em;
    }

    .border-end {
        border-left: 1px solid $border !important;
        border-right: inherit !important;
    }

    .border-start {
        border-right: 1px solid $border !important;
        border-left: inherit !important;
    }

    .border-start-0 {
        border-right: 0 !important;
        border-left: 1px solid $border !important;
    }
    .border-end-0 {
        border-left: 0 !important;
        border-right: 1px solid $border !important;
    }

    .br-tl-4 {
        border-top-left-radius: 4px !important;
    }

    .br-bl-4 {
        border-bottom-left-radius: 4px !important;
    }

    .br-tr-4 {
        border-top-right-radius: 4px !important;
    }

    .br-br-4 {
        border-bottom-right-radius: 4px !important;
    }

    .br-tl-7 {
        border-top-left-radius: 7px !important;
    }

    .br-bl-7 {
        border-bottom-left-radius: 7px !important;
    }

    .br-tr-7 {
        border-top-right-radius: 7px !important;
    }

    .br-br-7 {
        border-bottom-right-radius: 7px !important;
    }

    .br-tl-0 {
        border-top-left-radius: 0px !important;
    }

    .br-bl-0 {
        border-bottom-left-radius: 0px !important;
    }

    .br-tr-0 {
        border-top-right-radius: 0px !important;
    }

    .br-br-0 {
        border-bottom-right-radius: 0px !important;
    }

    .border-end-1 {
        border-right: 1px solid #d5dce3;
    }

    .rounded-end {
        border-top-right-radius: 3px !important;
        border-bottom-right-radius: 3px !important;
    }

    .rounded-start {
        border-top-left-radius: 3px !important;
        border-bottom-left-radius: 3px !important;
    }

    .float-start {
        float: right !important;
    }

    .float-end {
        float: left !important;
    }

    @media (min-width: 576px) {
        .float-sm-start {
            float: right !important;
        }

        .float-sm-end {
            float: left !important;
        }
    }

    @media (min-width: 768px) {
        .float-md-start {
            float: right !important;
        }

        .float-md-end {
            float: left !important;
        }
    }

    @media (min-width: 992px) {
        .float-lg-start {
            float: right !important;
        }

        .float-lg-end {
            float: left !important;
        }
    }

    @media (min-width: 1280px) {
        .float-xl-start {
            float: right !important;
        }

        .float-xl-end {
            float: left !important;
        }
    }

    /*--------- Margin--------------*/

    .me-0 {
        margin-left: 0 !important;
        margin-right: inherit !important;
    }

    .mx-0 {
        margin-left: 0 !important;
        margin-right: 0 !important;
    }

    .ms-0 {
        margin-right: 0 !important;
        margin-left: inherit !important;
    }

    .me-1 {
        margin-left: 0.25rem !important;
        margin-right: inherit !important;
    }

    .mx-1 {
        margin-left: 0.25rem !important;
        margin-right: 0.25rem !important;
    }

    .ms-1 {
        margin-right: 0.25rem !important;
        margin-left: inherit !important;
    }

    .me-2 {
        margin-left: 0.5rem !important;
        margin-right: inherit !important;
    }

    .mx-2 {
        margin-left: 0.5rem !important;
        margin-right: 0.5rem !important;
    }

    .ms-2 {
        margin-right: 0.5rem !important;
        margin-left: inherit !important;
    }

    .me-3 {
        margin-left: 0.75rem !important;
        margin-right: initial !important;
    }

    .mx-3 {
        margin-left: 0.75rem !important;
        margin-right: 0.75rem !important;
    }

    .ms-3 {
        margin-right: 0.75rem !important;
        margin-left: inherit !important;
    }

    .me-4 {
        margin-left: 1rem !important;
        margin-right: initial !important;
    }

    .mx-4 {
        margin-left: 1rem !important;
        margin-right: 1rem !important;
    }

    .ms-4 {
        margin-right: 1rem !important;
        margin-left: inherit !important;
    }

    .me-5 {
        margin-left: 1.5rem !important;
        margin-right: inherit !important;
    }
    .mx-5 {
        margin-left: 1.5rem !important;
        margin-right: 1.5rem !important;
    }

    .ms-5 {
        margin-right: 1.5rem !important;
        margin-left: inherit !important;
    }

    .me-6 {
        margin-left: 2rem !important;
        margin-right: inherit !important;
    }

    .mx-6 {
        margin-left: 2rem !important;
        margin-right: 2rem !important;
    }

    .ms-6 {
        margin-right: 2rem !important;
        margin-left: inherit !important;
    }

    .me-7 {
        margin-left: 3rem !important;
        margin-right: inherit !important;
    }

    .mx-7 {
        margin-left: 3rem !important;
        margin-right: 3rem !important;
    }

    .ms-7 {
        margin-right: 3rem !important;
        margin-left: inherit !important;
    }

    .mx-7 {
        margin-right: 3rem !important;
        margin-left: 3rem !important;
    }

    .me-8 {
        margin-left: 4rem !important;
        margin-right: inherit !important;
    }

    .mx-8 {
        margin-left: 4rem !important;
        margin-right: 4rem !important;
    }

    .ms-8 {
        margin-right: 4rem !important;
        margin-left: inherit !important;
    }

    .mx-8 {
        margin-right: 4rem !important;
        margin-left: 4rem !important;
    }

    .me-9 {
        margin-left: 6rem !important;
        margin-right: inherit !important;
    }

    .mx-9 {
        margin-left: 6rem !important;
        margin-right: 6rem !important;
    }

    .ms-9 {
        margin-right: 6rem !important;
        margin-left: inherit !important;
    }

    .me-auto {
        margin-left: auto !important;
        margin-right: inherit !important;
    }

    .mx-auto {
        margin-left: auto !important;
        margin-right: auto !important;
    }

    .ms-auto {
        margin-right: auto !important;
        margin-left: inherit !important;
    }

    @media (min-width: 576px) {
        .me-sm-0 {
            margin-left: 0 !important;
            margin-right: inherit !important;
        }
        .mx-sm-0 {
            margin-left: 0 !important;
            margin-right: 0 !important;
        }
        .ms-sm-0 {
            margin-right: 0 !important;
            margin-left: inherit !important;
        }
        .me-sm-1 {
            margin-left: 0.25rem !important;
            margin-right: inherit !important;
        }
        .mx-sm-1 {
            margin-left: 0.25rem !important;
            margin-right: 0.25rem !important;
        }
        .ms-sm-1 {
            margin-right: 0.25rem !important;
            margin-left: inherit !important;
        }
        .me-sm-2 {
            margin-left: 0.5rem !important;
            margin-right: inherit !important;
        }
        .mx-sm-2 {
            margin-left: 0.5rem !important;
            margin-right: 0.5rem !important;
        }
        .ms-sm-2 {
            margin-right: 0.5rem !important;
            margin-left: inherit !important;
        }
        .me-sm-3 {
            margin-left: 0.75rem !important;
            margin-right: inherit !important;
        }
        .mx-sm-3 {
            margin-left: 0.75rem !important;
            margin-right: 0.75rem !important;
        }
        .ms-sm-3,
        .mx-sm-3 {
            margin-right: 0.75rem !important;
            margin-left: inherit !important;
        }
        .me-sm-4 {
            margin-left: 1rem !important;
            margin-right: inherit !important;
        }
        .mx-sm-4 {
            margin-left: 1rem !important;
            margin-right: 1rem !important;
        }
        .ms-sm-4 {
            margin-right: 1rem !important;
            margin-left: inherit !important;
        }
        .me-sm-5 {
            margin-left: 1.5rem !important;
            margin-right: inherit !important;
        }
        .mx-sm-5 {
            margin-left: 1.5rem !important;
            margin-right: 1.5rem !important;
        }
        .ms-sm-5 {
            margin-right: 1.5rem !important;
            margin-left: inherit !important;
        }
        .me-sm-6 {
            margin-left: 2rem !important;
            margin-right: inherit !important;
        }
        .mx-sm-6 {
            margin-left: 2rem !important;
            margin-right: 2rem !important;
        }
        .ms-sm-6 {
            margin-right: 2rem !important;
            margin-left: inherit !important;
        }
        .me-sm-7 {
            margin-left: 3rem !important;
            margin-right: inherit !important;
        }
        .mx-sm-7 {
            margin-left: 3rem !important;
            margin-right: 3rem !important;
        }
        .ms-sm-7 {
            margin-right: 3rem !important;
            margin-left: inherit !important;
        }
        .me-sm-8 {
            margin-left: 4rem !important;
            margin-right: inherit !important;
        }
        .mx-sm-8 {
            margin-left: 4rem !important;
            margin-right: 4rem !important;
        }
        .ms-sm-8 {
            margin-right: 4rem !important;
            margin-left: inherit !important;
        }
        .me-sm-9 {
            margin-left: 6rem !important;
            margin-right: inherit !important;
        }
        .mx-sm-9 {
            margin-left: 6rem !important;
            margin-right: 6rem !important;
        }
        .ms-sm-9 {
            margin-right: 6rem !important;
            margin-left: inherit !important;
        }
        .me-sm-auto {
            margin-left: auto !important;
            margin-right: inherit !important;
        }
        .mx-sm-auto {
            margin-left: auto !important;
            margin-right: auto !important;
        }
        .ms-sm-auto {
            margin-right: auto !important;
            margin-left: inherit !important;
        }
    }

    @media (min-width: 768px) {
        .me-md-0 {
            margin-left: 0 !important;
            margin-right: inherit !important;
        }
        .mx-md-0 {
            margin-left: 0 !important;
            margin-right: 0 !important;
        }
        .ms-md-0 {
            margin-right: 0 !important;
            margin-left: inherit !important;
        }
        .me-md-1 {
            margin-left: 0.25rem !important;
            margin-right: inherit !important;
        }
        .mx-md-1 {
            margin-left: 0.25rem !important;
            margin-right: 0.25rem !important;
        }
        .ms-md-1 {
            margin-right: 0.25rem !important;
            margin-left: inherit !important;
        }
        .me-md-2 {
            margin-left: 0.5rem !important;
            margin-right: inherit !important;
        }
        .mx-md-2 {
            margin-left: 0.5rem !important;
            margin-right: 0.5rem !important;
        }
        .ms-md-2 {
            margin-right: 0.5rem !important;
            margin-left: inherit !important;
        }
        .me-md-3 {
            margin-left: 0.75rem !important;
            margin-right: inherit !important;
        }
        .mx-md-3 {
            margin-left: 0.75rem !important;
            margin-right: 0.75rem !important;
        }
        .ms-md-3 {
            margin-right: 0.75rem !important;
            margin-left: inherit !important;
        }
        .me-md-4 {
            margin-left: 1rem !important;
            margin-right: inherit !important;
        }
        .mx-md-4 {
            margin-left: 1rem !important;
            margin-right: 1rem !important;
        }
        .ms-md-4 {
            margin-right: 1rem !important;
            margin-left: inherit !important;
        }
        .me-md-5 {
            margin-left: 1.5rem !important;
            margin-right: inherit !important;
        }
        .mx-md-5 {
            margin-left: 1.5rem !important;
            margin-right: 1.5rem !important;
        }
        .ms-md-5 {
            margin-right: 1.5rem !important;
            margin-left: inherit !important;
        }
        .me-md-6 {
            margin-left: 2rem !important;
            margin-right: inherit !important;
        }
        .mx-md-6 {
            margin-left: 2rem !important;
            margin-right: 2rem !important;
        }
        .ms-md-6 {
            margin-right: 2rem !important;
            margin-left: inherit !important;
        }
        .me-md-7 {
            margin-left: 3rem !important;
            margin-right: inherit !important;
        }
        .mx-md-7 {
            margin-left: 3rem !important;
            margin-right: 3rem !important;
        }
        .ms-md-7 {
            margin-right: 3rem !important;
            margin-left: inherit !important;
        }
        .me-md-8 {
            margin-left: 4rem !important;
            margin-right: inherit !important;
        }
        .mx-md-8 {
            margin-left: 4rem !important;
            margin-right: 4rem !important;
        }
        .ms-md-8 {
            margin-right: 4rem !important;
            margin-left: inherit !important;
        }
        .me-md-9 {
            margin-left: 6rem !important;
            margin-right: inherit !important;
        }
        .mx-md-9 {
            margin-left: 6rem !important;
            margin-right: 6rem !important;
        }
        .ms-md-9 {
            margin-right: 6rem !important;
            margin-left: inherit !important;
        }
        .me-md-auto {
            margin-left: auto !important;
            margin-right: inherit !important;
        }
        .mx-md-auto {
            margin-left: auto !important;
            margin-right: auto !important;
        }
        .ms-md-auto {
            margin-right: auto !important;
            margin-left: inherit !important;
        }
    }

    @media (min-width: 992px) {
        .me-lg-0 {
            margin-left: 0 !important;
            margin-right: inherit !important;
        }
        .mx-lg-0 {
            margin-left: 0 !important;
            margin-right: 0 !important;
        }
        .ms-lg-0 {
            margin-right: 0 !important;
            margin-left: inherit !important;
        }
        .me-lg-1 {
            margin-left: 0.25rem !important;
            margin-right: inherit !important;
        }
        .mx-lg-1 {
            margin-left: 0.25rem !important;
            margin-right: 0.25rem !important;
        }
        .ms-lg-1 {
            margin-right: 0.25rem !important;
            margin-left: inherit !important;
        }
        .me-lg-2 {
            margin-left: 0.5rem !important;
            margin-right: inherit !important;
        }
        .mx-lg-2 {
            margin-left: 0.5rem !important;
            margin-right: 0.5rem !important;
        }
        .ms-lg-2 {
            margin-right: 0.5rem !important;
            margin-left: inherit !important;
        }
        .me-lg-3 {
            margin-left: 0.75rem !important;
            margin-right: inherit !important;
        }
        .mx-lg-3 {
            margin-left: 0.75rem !important;
            margin-right: 0.75rem !important;
        }
        .ms-lg-3 {
            margin-right: 0.75rem !important;
            margin-left: inherit !important;
        }
        .me-lg-4 {
            margin-left: 1rem !important;
            margin-right: inherit !important;
        }
        .mx-lg-4 {
            margin-left: 1rem !important;
            margin-right: 1rem !important;
        }
        .ms-lg-4 {
            margin-right: 1rem !important;
            margin-left: inherit !important;
        }
        .me-lg-5 {
            margin-left: 1.5rem !important;
            margin-right: inherit !important;
        }
        .mx-lg-5 {
            margin-left: 1.5rem !important;
            margin-right: 1.5rem !important;
        }
        .ms-lg-5 {
            margin-right: 1.5rem !important;
            margin-left: inherit !important;
        }
        .me-lg-6 {
            margin-left: 2rem !important;
            margin-right: inherit !important;
        }
        .mx-lg-6 {
            margin-left: 2rem !important;
            margin-right: 2rem !important;
        }
        .ms-lg-6 {
            margin-right: 2rem !important;
            margin-left: inherit !important;
        }
        .me-lg-7 {
            margin-left: 3rem !important;
            margin-right: inherit !important;
        }
        .mx-lg-7 {
            margin-left: 3rem !important;
            margin-right: 3rem !important;
        }
        .ms-lg-7 {
            margin-right: 3rem !important;
            margin-left: 3rem !important;
        }
        .me-lg-8 {
            margin-left: 4rem !important;
            margin-right: inherit !important;
        }
        .ms-lg-8 {
            margin-right: 4rem !important;
            margin-left: inherit !important;
        }
        .mx-lg-8 {
            margin-right: 4rem !important;
            margin-left: 4rem !important;
        }
        .me-lg-9 {
            margin-left: 6rem !important;
            margin-right: inherit !important;
        }
        .ms-lg-9 {
            margin-right: 6rem !important;
            margin-left: inherit !important;
        }
        .mx-lg-9 {
            margin-right: 6rem !important;
            margin-left: 6rem !important;
        }
        .me-lg-auto {
            margin-left: auto !important;
            margin-right: inherit !important;
        }
        .ms-lg-auto {
            margin-right: auto !important;
            margin-left: inherit !important;
        }
        .mx-lg-auto {
            margin-right: auto !important;
            margin-left: auto !important;
        }
    }

    @media (min-width: 1280px) {
        .me-xl-0 {
            margin-left: 0 !important;
            margin-right: inherit !important;
        }
        .ms-xl-0 {
            margin-right: 0 !important;
            margin-left: inherit !important;
        }
        .mx-xl-0 {
            margin-right: 0 !important;
            margin-left: 0 !important;
        }
        .me-xl-1 {
            margin-left: 0.25rem !important;
            margin-right: inherit !important;
        }
        .ms-xl-1 {
            margin-right: 0.25rem !important;
            margin-left: inherit !important;
        }
        .mx-xl-1 {
            margin-right: 0.25rem !important;
            margin-left: 0.25rem !important;
        }
        .me-xl-2 {
            margin-left: 0.5rem !important;
            margin-right: inherit !important;
        }
        .ms-xl-2 {
            margin-right: 0.5rem !important;
            margin-left: inherit !important;
        }
        .mx-xl-2 {
            margin-right: 0.5rem !important;
            margin-left: 0.5rem !important;
        }
        .me-xl-3 {
            margin-left: 0.75rem !important;
            margin-right: inherit !important;
        }
        .ms-xl-3 {
            margin-right: 0.75rem !important;
            margin-left: inherit !important;
        }
        .mx-xl-3 {
            margin-right: 0.75rem !important;
            margin-left: 0.75rem !important;
        }
        .me-xl-4 {
            margin-left: 1rem !important;
            margin-right: inherit !important;
        }
        .ms-xl-4 {
            margin-right: 1rem !important;
            margin-left: inherit !important;
        }
        .mx-xl-4 {
            margin-right: 1rem !important;
            margin-left: 1rem !important;
        }
        .me-xl-5 {
            margin-left: 1.5rem !important;
            margin-right: inherit !important;
        }
        .ms-xl-5 {
            margin-right: 1.5rem !important;
            margin-left: inherit !important;
        }
        .mx-xl-5 {
            margin-right: 1.5rem !important;
            margin-left: 1.5rem !important;
        }
        .me-xl-6 {
            margin-left: 2rem !important;
            margin-right: inherit !important;
        }
        .ms-xl-6 {
            margin-right: 2rem !important;
            margin-left: inherit !important;
        }
        .mx-xl-6 {
            margin-right: 2rem !important;
            margin-left: 2rem !important;
        }
        .me-xl-7 {
            margin-left: 3rem !important;
            margin-right: inherit !important;
        }
        .ms-xl-7 {
            margin-right: 3rem !important;
            margin-left: inherit !important;
        }
        .mx-xl-7 {
            margin-right: 3rem !important;
            margin-left: 3rem !important;
        }
        .me-xl-8 {
            margin-left: 4rem !important;
            margin-right: inherit !important;
        }
        .ms-xl-8 {
            margin-right: 4rem !important;
            margin-left: inherit !important;
        }
        .mx-xl-8 {
            margin-right: 4rem !important;
            margin-left: 4rem !important;
        }
        .me-xl-9 {
            margin-left: 6rem !important;
            margin-right: inherit !important;
        }
        .ms-xl-9 {
            margin-right: 6rem !important;
            margin-left: inherit !important;
        }
        .mx-xl-9 {
            margin-right: 6rem !important;
            margin-left: 6rem !important;
        }
        .me-xl-auto {
            margin-left: auto !important;
            margin-right: auto !important;
        }
        .ms-xl-auto {
            margin-right: auto !important;
            margin-left: inherit !important;
        }
        .mx-xl-auto {
            margin-right: auto !important;
            margin-left: auto !important;
        }
    }

    /*--------- Paddings--------------*/

    .pe-0 {
        padding-left: 0 !important;
        padding-right: inherit !important;
    }
    .px-0 {
        padding-left: 0 !important;
        padding-right: 0 !important;
    }
    .ps-0 {
        padding-right: 0 !important;
        padding-left: inherit !important;
    }
    .pe-1 {
        padding-left: 0.25rem !important;
        padding-right: inherit !important;
    }
    .px-1 {
        padding-left: 0.25rem !important;
        padding-right: 0.25rem !important;
    }
    .ps-1 {
        padding-right: 0.25rem !important;
        padding-left: inherit !important;
    }
    .pe-2 {
        padding-left: 0.5rem !important;
        padding-right: inherit !important;
    }
    .px-2 {
        padding-left: 0.5rem !important;
        padding-right: 0.5rem !important;
    }
    .ps-2 {
        padding-right: 0.5rem !important;
        padding-left: inherit !important;
    }
    .pe-3 {
        padding-left: 0.75rem !important;
        padding-right: inherit !important;
    }
    .px-3 {
        padding-left: 0.75rem !important;
        padding-right: 0.75rem !important;
    }
    .ps-3 {
        padding-right: 0.75rem !important;
        padding-left: inherit !important;
    }
    .pe-4 {
        padding-left: 1rem !important;
        padding-right: inherit !important;
    }
    .px-4 {
        padding-left: 1rem !important;
        padding-right: 1rem !important;
    }
    .ps-4 {
        padding-right: 1rem !important;
        padding-left: inherit !important;
    }
    .pe-5 {
        padding-left: 1.5rem !important;
        padding-right: inherit !important;
    }
    .px-5 {
        padding-left: 1.5rem !important;
        padding-right: 1.5rem !important;
    }
    .ps-5 {
        padding-right: 1.5rem !important;
        padding-left: inherit !important;
    }
    .px-5 {
        padding-right: 1.5rem !important;
        padding-left: 1.5rem !important;
    }
    .pe-6 {
        padding-left: 2rem !important;
        padding-right: inherit !important;
    }
    .px-6 {
        padding-left: 2rem !important;
        padding-right: 2rem !important;
    }
    .ps-6 {
        padding-right: 2rem !important;
        padding-left: inherit !important;
    }
    .pe-7 {
        padding-left: 3rem !important;
        padding-right: inherit !important;
    }
    .px-7 {
        padding-left: 3rem !important;
        padding-right: 3rem !important;
    }
    .ps-7 {
        padding-right: 3rem !important;
        padding-left: inherit !important;
    }
    .pe-8 {
        padding-left: 4rem !important;
        padding-right: inherit !important;
    }
    .px-8 {
        padding-left: 4rem !important;
        padding-right: 4rem !important;
    }
    .ps-8 {
        padding-right: 4rem !important;
        padding-left: 4rem !important;
    }
    .pe-9 {
        padding-left: 6rem !important;
        padding-right: inherit !important;
    }
    .px-9 {
        padding-left: 6rem !important;
        padding-right: 6rem !important;
    }
    .ps-9 {
        padding-right: 6rem !important;
        padding-left: inherit !important;
    }
    @media (min-width: 576px) {
        .pe-sm-0 {
            padding-left: 0 !important;
            padding-right: 0 !important;
        }
        .px-sm-0 {
            padding-left: 0 !important;
            padding-right: inherit !important;
        }
        .ps-sm-0 {
            padding-right: 0 !important;
            padding-left: inherit !important;
        }
        .pe-sm-1 {
            padding-left: 0.25rem !important;
            padding-right: inherit !important;
        }
        .px-sm-1 {
            padding-left: 0.25rem !important;
            padding-right: 0.25rem !important;
        }
        .ps-sm-1 {
            padding-right: 0.25rem !important;
            padding-left: inherit !important;
        }
        .pe-sm-2 {
            padding-left: 0.5rem !important;
            padding-right: inherit !important;
        }
        .px-sm-2 {
            padding-left: 0.5rem !important;
            padding-right: 0.5rem !important;
        }
        .ps-sm-2 {
            padding-right: 0.5rem !important;
            padding-left: inherit !important;
        }
        .pe-sm-3 {
            padding-left: 0.75rem !important;
            padding-right: inherit !important;
        }
        .px-sm-3 {
            padding-left: 0.75rem !important;
            padding-right: 0.75rem !important;
        }
        .ps-sm-3 {
            padding-right: 0.75rem !important;
            padding-left: inherit !important;
        }
        .pe-sm-4 {
            padding-left: 1rem !important;
            padding-right: inherit !important;
        }
        .px-sm-4 {
            padding-left: 1rem !important;
            padding-right: 1rem !important;
        }
        .ps-sm-4 {
            padding-right: 1rem !important;
            padding-left: inherit !important;
        }
        .pe-sm-5 {
            padding-left: 1.5rem !important;
            padding-right: inherit !important;
        }
        .px-sm-5 {
            padding-left: 1.5rem !important;
            padding-right: 1.5rem !important;
        }
        .ps-sm-5 {
            padding-right: 1.5rem !important;
            padding-left: inherit !important;
        }
        .pe-sm-6 {
            padding-left: 2rem !important;
            padding-right: inherit !important;
        }
        .px-sm-6 {
            padding-left: 2rem !important;
            padding-right: 2rem !important;
        }
        .ps-sm-6 {
            padding-right: 2rem !important;
            padding-left: inherit !important;
        }
        .pe-sm-7 {
            padding-left: 3rem !important;
            padding-right: inherit !important;
        }
        .px-sm-7 {
            padding-left: 3rem !important;
            padding-right: 3rem !important;
        }
        .ps-sm-7 {
            padding-right: 3rem !important;
            padding-left: inherit !important;
        }
        .pe-sm-8 {
            padding-left: 4rem !important;
            padding-right: inherit !important;
        }
        .px-sm-8 {
            padding-left: 4rem !important;
            padding-right: 4rem !important;
        }
        .ps-sm-8 {
            padding-right: 4rem !important;
            padding-left: inherit !important;
        }
        .pe-sm-9 {
            padding-left: 6rem !important;
            padding-right: inherit !important;
        }
        .px-sm-9 {
            padding-left: 6rem !important;
            padding-right: 6rem !important;
        }
        .ps-sm-9 {
            padding-right: 6rem !important;
            padding-left: inherit !important;
        }
    }
    @media (min-width: 768px) {
        .pe-md-0 {
            padding-left: 0 !important;
            padding-right: inherit !important;
        }
        .px-md-0 {
            padding-left: 0 !important;
            padding-right: 0 !important;
        }
        .ps-md-0 {
            padding-right: 0 !important;
            padding-left: inherit !important;
        }
        .pe-md-1 {
            padding-left: 0.25rem !important;
            padding-right: inherit !important;
        }
        .px-md-1 {
            padding-left: 0.25rem !important;
            padding-right: 0.25rem !important;
        }
        .ps-md-1 {
            padding-right: 0.25rem !important;
            padding-left: inherit !important;
        }
        .pe-md-2 {
            padding-left: 0.5rem !important;
            padding-right: inherit !important;
        }
        .px-md-2 {
            padding-left: 0.5rem !important;
            padding-right: 0.5rem !important;
        }
        .ps-md-2 {
            padding-right: 0.5rem !important;
            padding-left: inherit !important;
        }
        .pe-md-3 {
            padding-left: 0.75rem !important;
            padding-right: inherit !important;
        }
        .ps-md-3 {
            padding-right: 0.75rem !important;
            padding-left: inherit !important;
        }
        .px-md-3 {
            padding-right: 0.75rem !important;
            padding-left: 0.75rem !important;
        }
        .pe-md-4 {
            padding-left: 1rem !important;
            padding-right: inherit !important;
        }
        .px-md-4 {
            padding-left: 1rem !important;
            padding-right: 1rem !important;
        }
        .ps-md-4 {
            padding-right: 1rem !important;
            padding-left: inherit !important;
        }
        .pe-md-5 {
            padding-left: 1.5rem !important;
            padding-right: 1.5rem !important;
        }
        .ps-md-5 {
            padding-right: 1.5rem !important;
            padding-left: inherit !important;
        }
        .px-md-5 {
            padding-right: 1.5rem !important;
            padding-left: 1.5rem !important;
        }
        .pe-md-6 {
            padding-left: 2rem !important;
            padding-right: inherit !important;
        }
        .px-md-6 {
            padding-left: 2rem !important;
            padding-right: 2rem !important;
        }
        .ps-md-6 {
            padding-right: 2rem !important;
            padding-left: inherit !important;
        }
        .pe-md-7 {
            padding-left: 3rem !important;
            padding-right: inherit !important;
        }
        .px-md-7 {
            padding-left: 3rem !important;
            padding-right: 3rem !important;
        }
        .ps-md-7 {
            padding-right: 3rem !important;
            padding-left: inherit !important;
        }
        .pe-md-8 {
            padding-left: 4rem !important;
            padding-right: inherit !important;
        }
        .px-md-8 {
            padding-left: 4rem !important;
            padding-right: 4rem !important;
        }
        .ps-md-8 {
            padding-right: 4rem !important;
            padding-left: inherit !important;
        }
        .pe-md-9 {
            padding-left: 6rem !important;
            padding-right: inherit !important;
        }
        .px-md-9 {
            padding-left: 6rem !important;
            padding-right: 6rem !important;
        }
        .ps-md-9 {
            padding-right: 6rem !important;
            padding-left: 6rem !important;
        }
    }
    @media (min-width: 992px) {
        .pe-lg-0 {
            padding-left: 0 !important;
            padding-right: inherit !important;
        }
        .px-lg-0 {
            padding-left: 0 !important;
            padding-right: 0 !important;
        }
        .ps-lg-0 {
            padding-right: 0 !important;
            padding-left: inherit !important;
        }
        .pe-lg-1 {
            padding-left: 0.25rem !important;
            padding-right: inherit !important;
        }
        .px-lg-1 {
            padding-left: 0.25rem !important;
            padding-right: 0.25rem !important;
        }
        .ps-lg-1 {
            padding-right: 0.25rem !important;
            padding-left: inherit !important;
        }
        .pe-lg-2 {
            padding-left: 0.5rem !important;
            padding-right: inherit !important;
        }
        .px-lg-2 {
            padding-left: 0.5rem !important;
            padding-right: 0.5rem !important;
        }
        .ps-lg-2 {
            padding-right: 0.5rem !important;
            padding-left: inherit !important;
        }
        .px-lg-2 {
            padding-right: 0.5rem !important;
            padding-left: 0.5rem !important;
        }
        .pe-lg-3 {
            padding-left: 0.75rem !important;
            padding-right: inherit !important;
        }
        .px-lg-3 {
            padding-left: 0.75rem !important;
            padding-right: 0.75rem !important;
        }
        .ps-lg-3 {
            padding-right: 0.75rem !important;
            padding-left: inherit !important;
        }
        .pe-lg-4 {
            padding-left: 1rem !important;
            padding-right: inherit !important;
        }
        .px-lg-4 {
            padding-left: 1rem !important;
            padding-right: 1rem !important;
        }
        .ps-lg-4 {
            padding-right: 1rem !important;
            padding-left: inherit !important;
        }
        .pe-lg-5 {
            padding-left: 1.5rem !important;
            padding-right: inherit !important;
        }
        .px-lg-5 {
            padding-left: 1.5rem !important;
            padding-right: 1.5rem !important;
        }
        .ps-lg-5 {
            padding-right: 1.5rem !important;
            padding-left: inherit !important;
        }
        .pe-lg-6 {
            padding-left: 2rem !important;
            padding-right: inherit !important;
        }
        .px-lg-6 {
            padding-left: 2rem !important;
            padding-right: 2rem !important;
        }
        .ps-lg-6 {
            padding-right: 2rem !important;
            padding-left: inherit !important;
        }
        .pe-lg-7 {
            padding-left: 3rem !important;
            padding-right: inherit !important;
        }
        .px-lg-7 {
            padding-left: 3rem !important;
            padding-right: 3rem !important;
        }
        .ps-lg-7 {
            padding-right: 3rem !important;
            padding-left: inherit !important;
        }
        .pe-lg-8 {
            padding-left: 4rem !important;
            padding-right: inherit !important;
        }
        .px-lg-8 {
            padding-left: 4rem !important;
            padding-right: 4rem !important;
        }
        .ps-lg-8 {
            padding-right: 4rem !important;
            padding-left: inherit !important;
        }
        .pe-lg-9 {
            padding-left: 6rem !important;
            padding-right: inherit !important;
        }
        .px-lg-9 {
            padding-left: 6rem !important;
            padding-right: 6rem !important;
        }
        .ps-lg-9 {
            padding-right: 6rem !important;
            padding-left: onh !important;
        }
    }
    @media (min-width: 1280px) {
        .pe-xl-0 {
            padding-left: 0 !important;
            padding-right: inherit !important;
        }
        .px-xl-0 {
            padding-left: 0 !important;
            padding-right: 0 !important;
        }
        .ps-xl-0 {
            padding-right: 0 !important;
            padding-left: inherit !important;
        }
        .pe-xl-1 {
            padding-left: 0.25rem !important;
            padding-right: inherit !important;
        }
        .px-xl-1 {
            padding-left: 0.25rem !important;
            padding-right: 0.25rem !important;
        }
        .ps-xl-1 {
            padding-right: 0.25rem !important;
            padding-left: inherit !important;
        }
        .pe-xl-2 {
            padding-left: 0.5rem !important;
            padding-right: inherit !important;
        }
        .px-xl-2 {
            padding-left: 0.5rem !important;
            padding-right: 0.5rem !important;
        }
        .ps-xl-2 {
            padding-right: 0.5rem !important;
            padding-left: inherit !important;
        }
        .pe-xl-3 {
            padding-left: 0.75rem !important;
            padding-right: inherit !important;
        }
        .px-xl-3 {
            padding-left: 0.75rem !important;
            padding-right: 0.75rem !important;
        }
        .ps-xl-3 {
            padding-right: 0.75rem !important;
            padding-left: inherit !important;
        }
        .pe-xl-4 {
            padding-left: 1rem !important;
            padding-right: inherit !important;
        }
        .px-xl-4 {
            padding-left: 1rem !important;
            padding-right: 1rem !important;
        }
        .ps-xl-4 {
            padding-right: 1rem !important;
            padding-left: inherit !important;
        }
        .pe-xl-5 {
            padding-left: 1.5rem !important;
            padding-right: inherit !important;
        }
        .px-xl-5 {
            padding-left: 1.5rem !important;
            padding-right: 1.5rem !important;
        }
        .ps-xl-5 {
            padding-right: 1.5rem !important;
            padding-left: inherit !important;
        }
        .pe-xl-6 {
            padding-left: 2rem !important;
            padding-right: inherit !important;
        }
        .px-xl-6 {
            padding-left: 2rem !important;
            padding-right: 2rem !important;
        }
        .ps-xl-6,
        .px-xl-6 {
            padding-right: 2rem !important;
            padding-left: inherit !important;
        }
        .pe-xl-7 {
            padding-left: 3rem !important;
            padding-right: inherit !important;
        }
        .px-xl-7 {
            padding-left: 3rem !important;
            padding-right: 3rem !important;
        }
        .ps-xl-7 {
            padding-right: 3rem !important;
            padding-left: inherit !important;
        }
        .pe-xl-8 {
            padding-left: 4rem !important;
            padding-right: inherit !important;
        }
        .px-xl-8 {
            padding-left: 4rem !important;
            padding-right: 4rem !important;
        }
        .ps-xl-8 {
            padding-right: 4rem !important;
            padding-left: inherit !important;
        }
        .pe-xl-9 {
            padding-left: 6rem !important;
            padding-right: inherit !important;
        }
        .px-xl-9 {
            padding-left: 6rem !important;
            padding-right: 6rem !important;
        }
        .ps-xl-9 {
            padding-right: 6rem !important;
            padding-left: inherit !important;
        }
    }

    .text-start {
        text-align: right !important;
    }

    .text-end {
        text-align: left !important;
    }

    @media (min-width: 576px) {
        .text-sm-left {
            text-align: right !important;
        }

        .text-sm-right {
            text-align: left !important;
        }
    }

    @media (min-width: 768px) {
        .text-md-left {
            text-align: right !important;
        }

        .text-md-right {
            text-align: left !important;
        }
    }

    @media (min-width: 992px) {
        .text-lg-left {
            text-align: right !important;
        }

        .text-lg-right {
            text-align: left !important;
        }
    }

    @media (min-width: 1280px) {
        .text-xl-left {
            text-align: right !important;
        }

        .text-xl-right {
            text-align: left !important;
        }
    }

    .app-sidebar .mCSB_draggerContainer {
        left: -11px !important;
        right: inherit;
    }
    @media (min-width: 992px) {
        .app.sidenav-toggled {
            .app-content {
                margin-right: 0;
                margin-left: inherit;
            }
            .app-sidebar {
                right: 230px;
                left: inherit;
            }
        }
        .side-header {
            right: 0;
            left: inherit;
        }
        .sidebar-mini footer.footer {
            padding: 1.25rem 270px 1.25rem 1.25rem;
        }
        .sidebar-mini.sidenav-toggled footer.footer {
            padding: 1.25rem 1.25rem 1.25rem 80px;
        }
    }
    @media (max-width: 991px) {
        .app {
            .app-sidebar {
                right: -270px;
                left: inherit;
            }
            &.sidebar-mini {
                &.sidenav-toggled .side-menu .side-menu__icon {
                    margin-left: 8px;
                    margin-right: inherit;
                }
            }
            &.sidenav-toggled {
                .app-content {
                    margin-right: 0;
                    margin-left: inherit;
                }
                .app-sidebar {
                    right: 0;
                    left: inherit;
                }
            }
            &.sidebar-mini.sidebar-gone.sidenav-toggled .app-sidebar {
                right: 0;
                left: inherit;
            }
        }
    }
    @media (min-width: 992px) {
        .app-content {
            margin-right: 270px;
            margin-left: inherit;
        }
    }
    /*----- Componant: Top Navigation Bar ----- */

    @media (min-width: 992px) {
        .app-header {
            padding-left: 10px;
            padding-right: 285px;
        }
    }
    .app-search {
        margin-left: 15px;
        margin-right: inherit;
    }
    .app-search__input {
        padding-left: 30px;
        padding-right: inherit;
    }
    .app-search__button {
        left: 0;
        right: inherit;
    }
    .app-notification__icon {
        padding-left: 10px;
        padding-right: inherit;
    }
    .app-sidebar {
        right: 0;
        left: inherit;
        border-left: 1px solid $border;
        border-right: inherit;
        transition: right 0.2s ease, width 0.2s ease;
    }
    .app-sidebar__user-avatar {
        margin-left: 15px;
        margin-right: inherit;
    }
    .side-menu {
        padding: 0 0 0px 15px;
    }
    .side-menu__item.active {
        border-radius: 60px 0 0 60px;
    }
    .slide-menu li {
        .slide-item {
            &:before {
                margin-left: 8px;
                margin-right: inherit;
            }
        }
    }
    .side-menu .side-menu__icon {
        margin-left: 5px;
        margin-right: inherit;
    }
    .slide-menu {
        padding-right: 20px;
        margin-right: 26px;
        margin-left: inherit;
    }
    .slide-item {
        .icon {
            margin-left: 5px;
            margin-right: inherit;
        }
    }
    .angle {
        margin-left: 2px;
        margin-right: inherit;
    }
    @media (min-width: 992px) {
        .sidebar-mini.sidenav-toggled {
            .side-menu__item .side-menu__icon {
                margin-left: 0;
                margin-right: inherit;
            }
            .app-header.header {
                padding-right: 100px;
                padding-left: inherit;
            }
            .side-menu {
                padding: 0 0px 40px 00px;
            }
            .side-badge,
            .slide-menu open.slide-menu hide .side-badge {
                left: 20px;
                right: inherit;
            }
            .app-sidebar__user {
                padding: 12px 0px 12px 0;
            }
            .profile-img {
                left: 19px;
                right: inherit;
            }
            .app-content {
                margin-right: 80px;
                margin-left: inherit;
            }
            .app-sidebar {
                right: 0;
                left: inherit;
            }
            .slide-menu {
                border-bottom-right-radius: 4px;
            }
        }
        .app.sidebar-mini.sidenav-toggled .nav-badge {
            left: 28px;
            right: inherit;
        }
        .sidebar-mini.sidenav-toggled.sidenav-toggled-open {
            .side-menu__item {
                border-radius: 60px 0 0 60px;
            }
            .side-menu__icon {
                margin-left: 8px !important;
                margin-right: inherit;
            }
            .side-menu {
                padding: 0 0 0px 15px !important;
            }
        }
    }
    .dropdown-menu {
        &.dropdown-menu-right {
            right: auto;
            left: inherit;
        }
    }
    .app-breadcrumb {
        text-align: left;
        text-align: right;
    }

    .sidenav-toggled .main-wrapper .side-menu .side-menu__item {
        .nav-badge {
            right: 57px;
            left: inherit;
        }
    }
    .sidebar-mini.sidenav-toggled {
        .app-sidebar .side-header .header-brand-img {
            &.toggle-logo {
                margin-left: 0;
                margin-right: inherit;
            }
        }
    }
    .side-header {
        border-left: 1px solid $border;
        border-right: inherit;
    }
    .sidebar-mini.sidenav-toggled {
        .app-sidebar__toggle {
            padding-right: 10px;
            padding-left: inherit;
        }
    }
    .sidebar-navs .nav li:last-child a {
        margin-right: 0 !important;
        margin-left: inherit;
    }
    @media (min-width: 992px) {
        .sidenav-toggled .header-brand-img.light-logo {
            margin-right: 0;
            margin-left: inherit;
        }
    }
    .side-badge,
    .slide-menu open.slide-menu hide .side-badge {
        left: 28px;
        right: inherit;
    }

    /*-- Subslide ---*/

    .slide-menu a:before {
        margin-left: 10px;
        margin-right: inherit;
    }
    .sub-angle {
        float: left;
    }
    .sub-slide-menu {
        padding: 0 0px 15px 15px;
    }

    /*-- Subslide2 ---*/

    .sub-side-menu__item2 {
        padding: 8px 24px 8px 2px;
    }
    .sub-angle2 {
        float: left;
        margin-left: -9px;
        margin-right: inherit;
    }
    .sub-slide-menu2 {
        padding-right: 0;
        padding-left: inherit;
    }
    .sub-slide-item2 {
        padding: 8px 40px 8px 0px !important;
    }
    .dropdown-menu-end[data-bs-popper] {
        left: 0;
        right: auto;
    }
    .sidebar.sidebar-right {
        left: 0;
        right: initial;
        transform: translate(-100%, 0);
    }
    .legend {
        margin-left: 5px;
        margin-right: inherit;
    }
    .task-list li {
        padding-right: 55px;
        padding-left: inherit;
    }

    div.dataTables_wrapper div.dataTables_filter {
        text-align: left;
    }
    .select2-container--default .select2-selection--single .select2-selection__arrow {
        left: 1px !important;
        right: inherit !important;
    }
    .select2-container .select2-selection--single .select2-selection__rendered {
        padding-right: 8px;
        padding-left: 20px;
    }
    div.table-responsive > div.dataTables_wrapper > div.row > div[class^="col-"]:first-child {
        padding-right: 0;
        padding-left: inherit;
    }
    .fa-angle-right:before {
        content: "\f104";
    }
    .apexcharts-legend-marker {
        margin-left: 3px;
        margin-right: inherit;
    }
    @media (min-width: 992px) {
        &.sidebar-mini.sidenav-toggled .app-content {
            margin-right: 80px;
            margin-left: inherit;
        }
        &.sidebar-mini.sidenav-toggled .app-header.header {
            padding-right: 100px;
            padding-left: inherit;
        }
        &.sidebar-mini.sidenav-toggled .side-badge,
        &.sidebar-mini.sidenav-toggled .slide-menu open.slide-menu hide .side-badge {
            left: 20px;
            right: inherit;
        }
        &.horizontal {
            .main-sidemenu i.angle {
                left: -7px;
                right: inherit;
                transform: rotate(270deg);
            }
            .slide-menu li .slide-item:before {
                content: "\e92e";
                margin-left: 5px;
                margin-right: inherit;
            }
            .sub-slide .sub-slide-menu {
                right: 180px;
                left: inherit;
            }
            .sub-slide .sub-slide-menu2 {
                right: 180px;
                left: inherit;
            }
            .slide-menu a:before {
                content: "\e92e";
                margin-left: 5px;
                margin-right: inherit;
            }
            .sub-angle2 {
                margin-left: 11px;
                margin-right: inherit;
            }
            .slide-menu {
                margin-right: 0px;
                margin-left: inherit;
            }
        }
        &.sidebar-mini.sidenav-toggled.sidenav-toggled-open .side-menu__item {
            border-radius: 60px 0px 0px 60px;
        }
        &.sidebar-mini.sidenav-toggled.sidenav-toggled-open .side-menu {
            padding: 0 0px 0 15px !important;
        }
    }

    @media (max-width: 991px) {
        &.app.sidenav-toggled .app-sidebar {
            right: 0;
            left: inherit;
        }
        &.app .app-sidebar {
            right: -270px;
            left: inherit;
        }
        .responsive-navbar .header-search .input-group .input-group-text.btn {
            left: 6px;
            right: inherit;
        }
    }
    .sub-slide-item {
        padding: 8px 24px 8px 0px !important;
    }
    .input-group
        > :not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(.valid-feedback):not(.invalid-tooltip):not(.invalid-feedback) {
        margin-left: -1px;
        margin-right: inherit;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
        border-top-left-radius: 7px;
        border-bottom-left-radius: 7px;
    }
    .dropdown-icon {
        margin-left: 0.5rem;
        margin-right: -0.5rem;
    }

    @media (min-width: 992px) {
        &.icontext-menu.sidebar-mini.sidenav-toggled .side-badge {
            left: 28px;
            right: inherit;
        }
        &.icontext-menu.sidebar-mini.sidenav-toggled .app-header.header {
            padding-right: 130px;
            padding-left: inherit;
        }
        &.icontext-menu.sidebar-mini.sidenav-toggled .app-content {
            margin-right: 120px;
            margin-left: inherit;
        }
        &.sidebar-mini footer.footer {
            padding: 1.25rem 270px 1.25rem 1.25rem;
        }
        &.horizontal {
            .side-menu__item.active {
                border-radius: 0;
            }
            .side-menu__item:hover,
            .side-menu__item:focus {
                border-radius: 0;
            }
            .side-menu__item {
                border-radius: 0;
            }
            .side-menu__item.active:hover,
            .side-menu__item.active:focus {
                border-radius: 0px;
            }
        }
    }
    .alert .alert-inner--icon {
        float: right;
        margin-left: 5px;
    }
    .breadcrumb-1 ol,
    .breadcrumb-2 ol,
    .breadcrumb-6 ol {
        text-align: left;
    }
    .transfer-double-selected-list-main .transfer-double-selected-list-ul {
        padding-right: 7px;
        padding-left: inherit;
    }
    .checkbox-group label {
        padding-right: 25px;
        padding-left: inherit;
    }
    .checkbox-group label:before {
        right: 0;
        left: inherit;
    }
    .transfer-double-list-main
        .transfer-double-group-list-ul
        .transfer-double-group-list-li
        .transfer-double-group-list-li-ul {
        padding-right: 20px;
        padding-left: inherit;
    }
    .transfer-double-list-footer label {
        margin-right: 10px;
        margin-left: inherit;
    }
    .transfer-double-list-main .transfer-double-group-list-ul {
        padding-right: 7px;
        padding-left: inherit;
    }
    .select2-container--default .select2-selection--multiple .select2-selection__choice {
        float: right;
        margin-left: 5px;
        margin-right: inherit;
    }
    .iti__arrow {
        margin-right: 6px;
        margin-left: inherit;
    }
    .iti--allow-dropdown .iti__flag-container,
    .iti--separate-dial-code .iti__flag-container {
        left: auto;
        right: 0;
    }
    .iti__selected-flag {
        border-left: 1px solid #ecf0fa;
        border-right: inherit;
    }
    .iti__arrow {
        margin-right: 6px;
        margin-left: inherit;
    }
    .sw-theme-dots > ul.step-anchor:before {
        right: 20px;
        left: inherit;
    }
    .sw-theme-dots > ul.step-anchor > li > a:before {
        right: 37%;
        left: inherit;
    }
    .sw-theme-dots > ul.step-anchor > li > a:after {
        right: 35%;
        left: inherit;
    }
    .sw-theme-dots .step-content {
        text-align: right;
    }
    .transfer-double-selected-list-search-input {
        padding: 5px 8px 4px 0px;
    }
    .transfer-double-list-search-input {
        padding: 5px 8px 4px 0;
    }
    .transfer-double-content-left {
        float: right;
    }
    .transfer-double-content-middle {
        float: right;
    }
    .ms-choice > span {
        text-align: right;
    }
    @media screen and (max-width: 480px) {
        .sw-theme-dots > ul.step-anchor > li > a:before {
            right: -14px;
            left: 0;
            margin-left: 10px;
            margin-right: 0;
        }
        .sw-theme-dots > ul.step-anchor > li > a {
            text-align: right;
            margin-right: 0;
            margin-left: inherit;
        }
        .sw-theme-dots > ul.step-anchor:before {
            right: 19px;
            left: auto;
            margin-left: 10px;
            margin-right: auto;
        }
        .sw-theme-dots > ul.step-anchor > li {
            margin-left: auto;
            margin-right: 20px;
        }
        .sw-theme-dots > ul.step-anchor > li > a {
            text-align: right;
            margin-right: 0;
            margin-left: inherit;
        }
        .sw-theme-dots > ul.step-anchor > li > a:after {
            right: -36px;
            left: 0;
            margin-left: 10px;
            margin-right: 0;
        }
    }
    .iti--allow-dropdown input,
    .iti--allow-dropdown input[type="text"],
    .iti--allow-dropdown input[type="tel"],
    .iti--separate-dial-code input,
    .iti--separate-dial-code input[type="text"],
    .iti--separate-dial-code input[type="tel"] {
        padding-left: 6px;
        padding-right: 52px;
        margin-right: 0;
        margin-left: inherit;
    }

    .richText .richText-toolbar ul li {
        float: right;
    }
    .richText .richText-toolbar ul li a .richText-dropdown-outer .richText-dropdown .richText-dropdown-close {
        left: 4px;
        right: inherit;
    }
    .ql-snow .ql-picker:not(.ql-color-picker):not(.ql-icon-picker) svg {
        left: 6px;
        right: inherit;
    }
    .ql-editor {
        text-align: right;
    }
    .side-menu__item:hover,
    .side-menu__item:focus {
        border-radius: 60px 0 0 60px;
    }
    .side-menu__item {
        border-radius: 60px 0 0 60px;
    }
    .side-menu__item.active:hover,
    .side-menu__item.active:focus {
        border-radius: 60px 0 0 60px;
    }
    .cal1 .clndr .clndr-controls .clndr-control-button.rightalign {
        text-align: left;
    }
    .input-group-btn .btn {
        border-radius: 4px 0 0 4px;
    }
    &.dark-mode blockquote {
        border-right: 2px solid rgba(255, 255, 255, 0.1);
        border-left: inherit;
    }
    .dot-label {
        margin-left: 5px;
        margin-right: inherit;
    }
    // TREEVIEW //
    .tree li i {
        margin-left: 10px;
        margin-right: inherit;
        float: right;
        margin-top: 6px;
    }
    .tree ul {
        margin-right: 1em;
        margin-left: inherit;
    }
    .tree ul:before {
        border-right: 1px dotted #9eabc0;
        border-left: inherit;
    }
    .tree ul:before {
        right: -10px;
        left: inherit;
    }
    .tree ul li:before {
        right: -10px;
        left: inherit;
    }
    #fullscreenmodal .modal-header .btn-close {
        margin: -1rem auto -1rem 0;
    }
    .list-group .contact-icons {
        right: 70px;
        left: inherit;
    }
    .pull-right {
        float: left;
    }
    .form-select {
        padding: 0.5rem 0.75rem 0.5rem 1.75rem;
    }
    .input-group:not(.has-validation) > .dropdown-toggle:nth-last-child(n + 3),
    .input-group:not(.has-validation) > :not(:last-child):not(.dropdown-toggle):not(.dropdown-menu) {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
        border-top-right-radius: 7px;
        border-bottom-right-radius: 7px;
    }
    @media (max-width: 1279px) and (min-width: 992px) {
        .sw-theme-dots > ul.step-anchor > li > a:after {
            right: 37%;
            left: inherit;
        }
    }
    table.table-bordered.dataTable th:last-child,
    table.table-bordered.dataTable th:last-child,
    table.table-bordered.dataTable td:last-child,
    table.table-bordered.dataTable td:last-child {
        border-left-width: 1px;
    }
    @media (min-width: 1088px) {
        table.table-bordered.dataTable th:last-child,
        table.table-bordered.dataTable th:last-child,
        table.table-bordered.dataTable td:last-child,
        table.table-bordered.dataTable td:last-child {
            border-left-width: 0px;
        }
    }

    table.table-bordered th:last-child,
    table.table-bordered th:last-child,
    table.table-bordered td:last-child,
    table.table-bordered td:last-child {
        border-left-width: 1px;
    }

    #basic-datatable {
        table.table-bordered.dataTable th:last-child,
        table.table-bordered.dataTable th:last-child,
        table.table-bordered.dataTable td:last-child,
        table.table-bordered.dataTable td:last-child {
            border-left-width: 0px;
        }
    }
    .table-responsive.push {
        table.table-hover th:last-child,
        table.table-hover th:last-child,
        table.table-hover td:last-child,
        table.table-hover td:last-child {
            border-left-width: 2px;
        }
    }
    .app-header {
        transition: padding-right 0.3s ease;
    }
    @media (max-width: 380px) {
        .cal1 .clndr .clndr-controls .month {
            padding-right: 7px;
            padding-left: initial;
        }
    }
    .tag-avatar {
        border-radius: 3px 3px 0 0px;
        margin: 0 -0.5rem 0 0.5rem;
    }
    .tags > .tag:not(:last-child) {
        margin-left: 0.5rem;
        margin-right: inherit;
    }
    .ql-toolbar.ql-snow .ql-formats {
        margin-left: 25px;
        margin-right: inherit;
    }
    .dt-button-collection {
        .dropdown-menu {
            right: auto;
        }
    }
    .buttons-excel.buttons-html5 {
        border-top-right-radius: 0px !important;
        border-bottom-right-radius: 0px !important;
    }
    .buttons-pdf.buttons-html5 {
        border-top-right-radius: 0px !important;
        border-bottom-right-radius: 0px !important;
    }
    .ql-snow .ql-tooltip {
        margin-right: 20rem;
        margin-left: inherit;
    }

    // ----- CLOSED MENU -------//
    &.closed-leftmenu {
        @media (min-width: 768px) {
            &.sidebar-mini.sidenav-toggled {
                .app-content {
                    margin-right: 0px !important;
                    margin-left: inherit;
                }

                .app-header {
                    padding-right: 0px !important;
                    padding-left: inherit;
                }
            }
        }
    }

    // ----- ! CLOSED MENU -------//

    // ----- HOVER SUBMENU -------//

    &.hover-submenu {
        @media (min-width: 992px) {
            &.sidebar-mini.sidenav-toggled footer.footer {
                padding: 1.25rem 120px 1.25rem 1.25rem;
            }
            .sub-angle2 {
                margin-left: 4px;
                margin-right: inherit;
            }
            .sub-angle {
                margin-left: 15px;
                margin-right: inherit;
            }
            &.sidebar-mini.sidenav-toggled {
                .app-header.header {
                    padding-right: 130px;
                    padding-left: inherit;
                }
                .side-badge,
                .slide-menu open.slide-menu hide .side-badge {
                    left: 42px;
                    right: inherit;
                }
                .app-content {
                    margin-right: 120px;
                    margin-left: inherit;
                }
                .side-badge {
                    left: 32px;
                    right: inherit;
                }
                .slide-menu {
                    padding-right: 15px;
                    padding-left: inherit;
                    border-radius: 5px 0 0 5px;
                }
                .side-menu__icon {
                    margin-left: 0px !important;
                    margin-right: inherit;
                }
                &.sidenav-toggled-open {
                    .side-menu__icon {
                        margin-left: 0px !important;
                        margin-right: inherit;
                    }
                    .slide .slide-menu {
                        right: 94px;
                        left: inherit;
                    }
                }
            }
        }
    }

    // -----! HOVER SUBMENU -------//

    // ----- HOVER SUBMENU STYLE1 -------//

    &.hover-submenu1 {
        @media (min-width: 992px) {
            &.sidebar-mini.sidenav-toggled footer.footer {
                padding: 1.25rem 120px 1.25rem 1.25rem;
            }
            .sub-angle2 {
                margin-left: 4px;
                margin-right: inherit;
            }
            .sub-angle {
                margin-left: 15px;
                margin-right: inherit;
            }
            &.sidebar-mini.sidenav-toggled {
                .app-header.header {
                    padding-right: 100px;
                    padding-left: inherit;
                }
                .side-badge,
                .slide-menu open.slide-menu hide .side-badge {
                    left: 42px;
                    right: inherit;
                }
                .app-content {
                    margin-right: 80px;
                    margin-left: inherit;
                }
                .side-badge {
                    left: 20px;
                    right: inherit;
                }
                .slide-menu {
                    border-radius: 5px 0 0 5px;
                }
                .slide-item,
                .sub-side-menu__item {
                    padding-right: 17px;
                    padding-left: inherit;
                }
                .side-menu__icon {
                    margin-left: 0px !important;
                    margin-right: inherit;
                }
                &.sidenav-toggled-open {
                    .side-menu__icon {
                        margin-left: 0px !important;
                        margin-right: inherit;
                    }
                    .slide .slide-menu {
                        right: 54px;
                        left: inherit;
                    }
                }
            }
        }
    }

    // -----! HOVER SUBMENU STYLE1-------//

    /*# sourceMappingURL=style.css.map */
}

/* RTL */

.rtl {
    .demo_changer {
        transition: all 0.3s ease;
        left: -270px;
        right: auto !important;

        &.active {
            transition: all 0.3s ease;
            right: auto !important;
            left: 0;
        }

        .form_holder {
            float: left;
            border-right: 1px solid #e9edf4;
            border-left: inherit;
        }

        h4 {
            text-align: right;
        }
    }

    .onoffswitch2-label:before {
        left: 13px;
        right: inherit;
    }

    .onoffswitch2-checkbox:checked + .onoffswitch2-label:before {
        left: 0px;
        right: inherit;
    }

    &.transparent-mode .demo_changer .form_holder {
        border-right: 1px solid rgba(255, 255, 255, 0.1);
        border-left: 0px solid rgba(255, 255, 255, 0.1);
    }

    &.dark-mode .demo_changer .form_holder {
        border-right: 1px solid rgba(255, 255, 255, 0.1);
        border-left: 1px solid rgba(255, 255, 255, 0.1);
    }
    .demo_changer .demo-icon {
        float: right;
        border-radius: 0 5px 5px 0 !important;
    }
    .ps__rail-y {
        left: 0;
        right: auto !important;
    }
    .ps__thumb-y {
        left: 0px !important;
        right: auto;
    }
    .tag-addon {
        margin: 0 0.5rem 0 -0.5rem;
    }
    .page-item:not(:first-child) .page-link {
        margin-left: -1px;
        margin-right: inherit;
    }
    .tab_wrapper.right_side > ul li {
        border-left: 0;
        text-align: left;
    }
    .tab_wrapper.right_side > ul {
        float: left;
    }
    #tp3 {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
    }
    .custom-layout {
        left: 50px;
        right: inherit;
    }
    .apexcharts-tooltip-text-value,
    .apexcharts-tooltip-text-z-value {
        margin-right: 5px;
        margin-left: inherit;
    }
    .apexcharts-tooltip-marker {
        margin-left: 10px;
        margin-right: inherit;
    }
    .apexcharts-tooltip-series-group {
        text-align: right;
        justify-content: right;
    }
    .fa-angle-left:before {
        content: "\f105";
    }
    .fa-angle-double-left:before {
        content: "\f101";
    }
    .fa-angle-double-right:before {
        content: "\f100";
    }
    .product-label {
        float: left;
    }
    .list-icon::before {
        right: 10px;
        left: 0px;
    }

    .owl-carousel {
        .owl-item {
            float: right;
        }
        &.owl-rtl {
            direction: ltr;

            .owl-item {
                float: left;
            }
        }
    }

    .owl-carousel {
        .owl-video-play-icon {
            right: 50%;
            left: inherit;
            margin-right: -40px;
            margin-left: inherit;
        }
    }

    .owl-nav {
        .owl-prev {
            right: -25px;
            left: -1.5em;
        }
        .owl-next {
            left: -25px;
            right: inherit;
        }
    }
    .file-image-1 .icons {
        left: 7px;
        right: inherit;
        text-align: left;
    }
    .file-image .icons {
        left: 22px;
        right: inherit;
        text-align: left;
    }
}
