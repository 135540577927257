.about-us {
  padding: 5rem 5rem 5rem 6rem;
  background-color: hsl(0, 0%, 100%, 0.9);
}
.about-us-heading {
  text-align: center;
}
.about-us h6 {
  color: #008eb5;
  font-size: 17px;
}
.about-us h2 {
  font-size: 50px;
  font-weight: 900;
}
.about-us-container {
  display: flex;
}
.about-us-options-select {
  display: flex;
}
.about-us-container-inner2 {
  margin: 3rem 0rem 3rem 2rem;
  width: 60%;
}
.about-us-container-inner1 {
  width: 40%;
}
.about-us-container-inner2 h3 {
  font-size: 40px;
}
.about-us-options-select div {
  cursor: pointer;
  margin-right: 2rem;
}
.about-us-options {
  position: absolute;
  z-index: -1;
  opacity: 0;
  transition: opacity 1s ease;
}
.about-us-options-active {
  position: absolute;
  z-index: 1;
  opacity: 1;
  transition: opacity 1s ease;
  margin: 2rem 0;
}
.about-us-select {
  border-bottom: 0px solid #008eb5;
  transition: border-bottom 0.05s ease;
}
.about-us-select-active {
  border-bottom: 2px solid #008eb5;
  transition: border-bottom 0.05s ease;
}

.about-us-button button {
  padding: 0.2rem 1.7rem;
  border-radius: 7px;
  border: 2px solid var(--blueButton);
  background-color: var(--blueButton);
  color: white;
  font-size: 1.3rem;
  transition: 0.3s all ease;
  margin: 1rem 0;
}
.about-us-button button:hover {
  background-color: transparent;
  color: var(--blueButton);
}

@media (max-width: 1186px) {
  .about-us-container-inner1 {
    display: none;
  }
  .about-us-container-inner2 {
    margin: 3rem 0rem 3rem 0rem;
    width: 100%;
  }
}
@media (max-width: 700px) {
  .about-us {
    padding: 5rem 1rem 5rem 1rem;
    background-color: hsl(0, 0%, 100%, 0.9);
  }
  .about-us-container-inner2 h3 {
    font-size: 25px;
  }
}
