@import 'bootstrap/dist/css/bootstrap.css';
@import "./assets/scss/_variables.scss";
@import "./assets/css/style.css";

.card-wrapper{
    display: flex;
}
@media(max-width:600px) {
    .card-wrapper{
        display: block;
    }
}
:root{
    --blueButton:rgb(16, 126, 204);
}   


.custom-radio .custom-control-label::before {
    border-radius: 50%;
    background-color: gray;
}


