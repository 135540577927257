.task-cards-group {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 1rem;
}
.card-inner-div {
  display: flex;
}
.task-info-heading {
  width: 30%;
}
.task-cards-group > .card {
  margin: 1rem !important;
}
.card-inner-input {
  display: flex;
  justify-content: center;
  padding: 2px 1rem;
}
.card-inner-input div {
  width: 30%;
  margin: auto;
}
input[type="date"]:in-range::-webkit-datetime-edit-year-field,
input[type="date"]:in-range::-webkit-datetime-edit-month-field,
input[type="date"]:in-range::-webkit-datetime-edit-day-field,
input[type="date"]:in-range::-webkit-datetime-edit-text {
  color: rgb(185, 182, 182);
}
input[type="date"]::-webkit-calendar-picker-indicator {
  cursor: pointer;
  filter: invert(0.5);
}
.card-inner-input .form-select {
  width: 71%;
  border-radius: 8px;
  align-items: center;
  margin-left: 3.8rem;
  cursor: pointer;
}
.form-select {
  margin-left: 0.7rem !important;
  margin-right: 0.5rem !important;
}
.card-inner-div {
  margin: 4px 1.5rem;
}
.task-type-one .custom-controls-stacked,
.task-type-two .custom-controls-stacked,
.task-type-three .custom-controls-stacked,
.task-type-four .custom-controls-stacked {
  display: block;
}
.sidebar-icon {
  position: fixed;
  top: 33px;
  z-index: 10;
  font-size: 20px !important;
}

@media (max-width: 1403px) {
  .task-cards-group {
    display: block;
  }
}
@media (max-width: 993px) {
  .sidebar-icon {
    z-index: 1000;
    top: 40px;
  }
}
@media (max-width: 600px) {
  .task-cards-group > .card {
    margin: 2rem 0rem !important;
    width: 100% !important;
  }
  .task-cards-group {
    margin: 0 !important;
  }
  .card-inner-div {
    display: block;
  }
  .task-info-heading {
    width: 100%;
  }
}
