.card-blog-body {
  display: flex;
  height: 100%;
}
.card-blog-body .card-blog-image {
  width: 38%;

  object-fit: cover;
}
.card-blog-card {
  height: 400px;
}
/* To get the scroller on the react draft */
div.DraftEditor-root {
  /* border: 1px solid #c0c0c0; */
  height: 100%;
  width: auto;
  overflow-y: auto;
}

div.DraftEditor-editorContainer,
div.public-DraftEditor-content {
  height: 100%;
}
/* End */
.admin-card-blog-description {
  height: 60%;
  overflow-y: scroll;

  text-align: justify;
  font-size: 0.9rem;
  padding: 0.3rem;
}
.admin-card-blog-title {
  font-size: 1.1rem;
  font-weight: 400;
  height: 30%;
  overflow-y: scroll;
  text-align: justify;
  padding: 0.3rem;
}
.card-blog-body .card-blog-image img {
  height: 100%;
  /* width: inherit; */
  object-fit: cover;
  width: 100%;
}
.card-blog-details {
  width: 57%;
  margin-left: 10px;
  margin-right: 10px;
}
.blog-delete-and-edit {
  display: flex;
}
.blog-delete-and-edit > span:first-child {
  margin-right: 0.7rem;
}
