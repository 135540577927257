.home-blogs-artical {
  background-color: hsl(0deg 0% 87% / 90%);
  padding: 4rem 3.5rem;
}

.home-blog-container {
  display: grid;
  grid-template-columns: 33% 33% 33%;
  gap: 1rem;
}
.home-blog-section {
  /* width: 30%;
  margin: 2rem; */
  background-color: white;
  border-bottom: 4px solid transparent;
  cursor: pointer;
  overflow: hidden;
  transition: transform 0.8s ease;
  height: 37rem;
}
.home-blog-section img {
  height: 25rem;
  object-fit: cover;
  object-position: top;
}
.home-blog-section h3 {
  margin-top: 1rem;

  overflow: hidden;
  display: -webkit-box !important;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  max-height: 3.2rem;
  word-wrap: break-word;
}
.home-blog-section .blog-page-descripton {
  /* padding: 1.4rem 2rem; */
  overflow: hidden;
  display: -webkit-box !important;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  /* max-height: 7.2rem; */
  word-wrap: break-word;
}
.blog-page-details {
  padding: 0 1rem;
  word-wrap: break-word;
}
.home-blog-section:hover {
  border-bottom: 4px solid hsl(200, 100%, 40%);
  transform: translateY(-15px);
  transform-origin: end;
}
.home-blog-section img {
  width: 100%;
}

.home-blogs-artical button {
  display: block;
  margin: auto;
  border: 2px solid transparent;
  background-color: #0087ca;
  border-radius: 25px;
  color: white;
  padding: 0.5rem 2rem;
  font-size: 18px;
  margin-top: 2rem;
}
.parent {
  cursor: pointer;
}
.parent span {
  margin: 0 5px 0 20px;
}
.parent:hover > .child {
  animation-name: example;
  animation-duration: 1s;
  animation-timing-function: ease;
}
@keyframes example {
  0% {
    margin-left: 0px;
    color: white;
  }
  50% {
    margin-left: -20px;
  }
}
@media (max-width: 1043px) {
  .home-blog-container {
    display: block;
  }
  .home-blog-section {
    width: 95%;
    margin: 2rem auto;
  }
  .home-blogs-artical {
    padding: 0;
    padding-bottom: 2rem;
  }
  
}
