body {
  scroll-behavior: auto;
}
p,
ul {
  color: #555555;
}
.home-top {
  display: flex;
  height: 40rem;
}
.home-top-aside1 {
  /* width: 110%; */
  width: 50%;
  display: flex;
  justify-content: center;
  overflow: hidden;
  padding-left: 7rem;
  padding-top: 2.5rem;
}

.home-top-aside1 h1 {
  text-align: left;
}
.i-want-to-buy-button {
  text-align: center;
  width: 100%;
}
.i-want-to-buy-button button {
  padding: 0.9rem 1.7rem;
  border-radius: 7px;
  border: 2px solid var(--blueButton);
  background-color: var(--blueButton);
  color: white;
  font-size: 1.3rem;
  transition: 0.3s all ease;
}
.i-want-to-buy-button button:hover {
  background-color: transparent;
  color: var(--blueButton);
}
.home-top-aside2 {
  overflow: hidden;
  width: 50%;
  /* padding: 0; */

}
.home-top-aside2 .slick-slide {
  height: 40rem;
  width: inherit;
  margin: 0 0 11px 0;
}

.home-top-aside2 .slick-slide img {
  width: inherit;
  
float:right;
padding-right:7rem;
padding-top:2.5rem;
  /* object-fit: cover; */
}

/* the parent */
.home-top-aside2 .slick-list {
  margin: -10px -10px -10px -10px;
}
.home-top-title {
  padding: 3rem 2.5rem 2rem 0rem;
  text-align: end;
  font-size: 60px;
  font-weight: 900;
}
.home-top-title > span {
  color: var(--blueButton) !important;
}
.home-top-aside1 p {
  font-size: 17px;
  text-align: end;
  padding: 0 2.5rem 2rem 5rem;
}
.home-container .button {
  display: block;
  margin: auto;
  border: 2px solid transparent;
  background-color: #0087ca;
  border-radius: 25px;
  color: white;
  padding: 0.5rem 2rem;
  font-size: 18px;
  transition: 0.2s ease-out;
}
.home-container .button:hover,
.home-container .button::after {
  color: #0087ca;
  border: 2px solid #0087ca;
  background-color: white;
  transform: translateY(-3px);
  transform-origin: left;
  box-shadow: 5px 5px 10px gray;
}
.home-mid-section-1 {
  display: flex;
  height: 50rem;
  background-color: #fafafa;
  padding: 1rem 0;
}
.home-mid-aside-1,
.home-mid-aside-2 {
  width: 50%;
  margin: 0;
}
.home-mid-aside-1 img {
  width: 100% !important;
  height: 100% !important;
  object-fit: contain;
}
.home-mid-aside-2 {
  /* align-self: center; */
  padding: 0rem 6rem 0 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.home-mid-section-heading {
  font-size: 60px;
  padding: 0 0 3rem 0;
  margin-bottom: -1rem;
}
.home-mid-section-body {
  display: grid;
  grid-template-columns: 50% 50%;
  grid-template-rows: 50% 50%;
  gap: 1rem;
}
.home-mid-section-body .home-mid-section-body-image {
  width: 7rem;
  height: 8rem;
}
.home-mid-section-body > span {
  display: flex;
  flex-direction: column;
}
/* .home-mid-section-body > span >span{
  margin-bottom: 1rem;
} */
.home-mid-section-body-heading {
  font-size: 2rem;
  margin-bottom: 1rem;
}
.home-mid-section-body-paragraph {
  font-size: 1rem;
}
.home-mid-section-body .home-mid-section-body-image img {
  width: inherit;
  height: inherit;
}
/* .home-mid-section-list {
  display: flex;
}
.home-mid-aside-2 p{
 font-size: 17px;
 text-align: justify;
} */
.home-mid-section-list ul {
  margin: 10px 20px;
  font-size: 15px;
}
.home-mid-heading {
  font-size: 60px;
  text-align: center;
}
.home-mid-service-section {
  display: flex;
  justify-content: center;
}
.bulet-mar{
  margin-top: -12px;
}
.home-mid-service-item {
  width: 28%;
  margin: 1rem 0.5rem;
  background-color: white;
  border-radius: 10px;
}
.home-mid-service-item:hover {
  background-color: black;
  color: white;
  -webkit-transition: background-color 500ms linear;
  -ms-transition: background-color 500ms linear;
  transition: background-color 500ms linear;
}
.home-mid-service-item img {
  transition: 0.4s ease-out;
}
.home-mid-service-item img:hover {
  transform: scale(1.08);
  transform-origin: center;
}
.home-mid-service-item h3 {
  margin: 0 2rem 1rem 2rem;
}
.home-mid-service-item ul {
  margin: 0 2rem 2rem 2rem;
  line-height: 35px;
  font-size: 16px;
}
.home-mid-section2 {
  display: flex;
}
.home-mid-section-aside {
  width: 50%;
  background: linear-gradient(
    90deg,
    rgb(206, 228, 250) 0%,
    rgb(212, 241, 255) 56%,
    rgb(199, 255, 249) 100%
  );
}
.downPadding-home-mid {
  padding-bottom: 1rem;
}
.home-mid-section-aside img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}
.home-mid-section-aside .home-top-title {
  padding: 4rem 5rem 2rem 2.5rem !important;
  text-align: start !important;
}
.home-mid-section-1,
.home-mid-section-2,
.home-mid-section-3 {
  /* margin-bottom: 1.2rem; */
  display: flex !important;
}
.home-mid-section-aside-body {
  padding: 0 4rem;
}
.home-mid-section-list-number {
  margin-right: 2rem;
  padding-top: 1rem;
}
.home-mid-section-aside-heading {
  font-weight: bold;
  font-size: 1.2rem;
  margin-bottom: 0.7rem;
}
.home-mid-section-list-number > span {
  background-color: #ececed;
  padding: 0.6rem 0.8rem;
  border-radius: 1.4rem;
}
.home-mid-section-aside-paragraph {
  font-size: 1rem;
  font-weight: 400;
}
.home-mid-section-3 ul {
  color: black;
  margin-top: 1rem;
}
.home-mid-section-3 ul li {
  margin-bottom: 0.7rem;
}
.home-mid-section-3 ul li::before {
  content: "\2022";
  color: black;
  font-weight: bold;
  display: inline-block;
  width: 1em;
  margin-left: 1em;
}
/* @import "~slick-carousel/slick/slick.css"; */
@import "~slick-carousel/slick/slick-theme.css";

.home-bottom-testimonials-1 {
  padding: 4rem 1rem;
  text-align: center;
  /* margin-bottom: 2rem; */
  background-color: #ececed;
}
.home-bottom-testimonials-1 button {
  color: #adacaf;
  width: 40px;
  height: 40px;
}
.home-bottom-testimonials-1 button:hover {
  color: #959596;
}
.home-bottom-testimonials-1 .slick-prev:focus,
.home-bottom-testimonials-1 .slick-next:focus {
  color: #959596;
}
.home-bottom-testimonials-1 img {
  margin: 1rem auto;
  width: 50%;
  border-radius: 50%;
}

@media (max-width: 1462px) {
  .home-top-title {
    /* padding: 2rem; */
    text-align: end;
    font-size: 50px;
    font-weight: 900;
  }
  .home-top-aside1 p {
    padding: 0 2rem 2rem 2rem;
  }
  .home-mid-section-aside h2 {
    text-align: center;
    margin: 1rem;
  }
  .home-mid-section-aside p {
    font-size: 17px;
    padding: 0 4rem 2rem 4rem;
  }
}

@media (max-width: 1300px) {
  .home-top-title {
    text-align: end;
    font-size: 40px;
    font-weight: 900;
  }
  .home-top-aside1 p {
    padding: 0 2rem 2rem 2rem;
  }
  .home-mid-service-item {
    width: 31%;
    margin: 1rem;
  }
  .home-mid-section-heading {
    padding: 2rem 0;
    text-align: center;
  }
  .home-mid-section-body {
    display: grid;
    grid-template-columns: 50% 50%;
    grid-template-rows: 50% 50%;
    gap: 0.3rem;
  }
  .home-mid-section-body-heading {
    font-size: 1.4rem;
  }
  .home-mid-section-body-paragraph {
    font-size: 0.9rem;
  }
}
@media (max-width: 1043px) {
  .home-top {
    height: 30rem;
    margin-top: -10px;
    background: linear-gradient(
      90deg,
      rgba(0, 36, 10, 1) 0%,
      rgba(9, 36, 121, 0.9808298319327731) 0%,
      rgba(0, 255, 214, 0.5998774509803921) 100%
    );
  }
  .home-mid-section-body {
    /* display: block; */
    text-align: center;
  }
  .home-mid-section-body-image {
    display: block;
    width: 100% !important;
    text-align: center !important;
  }
  .home-mid-section-body-image img {
    object-fit: contain;
  }
  .home-top-aside1 {
    width: 100%;
    position: absolute;
    z-index: 1;
  }
  .home-top-aside2 .slick-slide {
    margin: 0;
  }
  .home-top-aside2 .slick-list {
    margin: 0;
  }
  .home-top-aside2 {
    display: none;
  }
  .home-top-title span {
    color: aqua !important;
  }
  .home-top-title {
    /* margin: 3rem; */

    text-align: center;
    font-size: 50px;
    font-weight: 900;
  }
  .home-top-aside1 p {
    text-align: center;
    padding: 2rem 4rem;
  }
  .home-mid-section-1 {
    display: block;
    padding-bottom: 3rem;
  }
  .home-mid-aside-1,
  .home-mid-aside-2 {
    width: 100%;
    padding: 0rem 5rem;
    margin: 0;
  }
  .home-mid-aside-1 {
    display: none;
  }
  .home-mid-service-section {
    display: block;
  }
  .home-mid-service-item {
    margin: auto;
    width: 80%;
    justify-content: center;
    text-align: center;
  }
  .home-mid-section2 {
    display: block;
    padding-bottom: 2rem;
  }
  .home-mid-section-aside {
    width: 100%;
  }
}
@media (max-width: 600px) {
  .home-top-title {
    font-size: 30px;
    /* margin: 5rem auto 2rem auto; */
  }
  .i-want-to-buy-button button {
    font-size: 0.9rem;
  }
  .home-top-aside1 {
    padding-left: 2rem;
    letter-spacing: 1px;
  }
  .home-top {
    padding: 0;
  }
  .home-top-title span {
    color: aqua !important;
  }
  .home-top-aside1 p {
    padding: 1rem;
  }
  .home-mid-service-item h3 {
    margin: 1rem;
  }
  .home-mid-aside-2 {
    padding: 0 2rem;
  }
  .home-mid-section-list ul {
    margin: 5px;
  }
  .home-mid-heading {
    font-size: 40px;
  }
  .home-mid-service-item ul {
    margin: 1rem;
    line-height: 28px;
    font-size: 14px;
  }
  .home-mid-section-aside p {
    padding: 1rem;
  }
  .home-mid-section-aside-body {
    padding: 0;
    margin: 0 10px;
  }
  .home-mid-section-list-number {
    margin-right: 1rem;
  }
}
@media (min-width: 300px) and (max-width: 430px) {
  .home-mid-section-body {
    display: block;
    text-align: center;
  }
  .home-mid-section-aside .home-top-title {
    padding: 4rem 0rem 2rem 0rem !important;
    text-align: center !important;
  }
  .home-mid-section-body > span {
    margin-bottom: 1rem;
  }
  .home-mid-section-heading {
    font-size: 40px;
  }
  .home-mid-section-body .home-mid-section-body-image {
    width: 100%;
  }
  .home-mid-section-1 {
    height: 100%;
  }
  .home-mid-section-body .home-mid-section-body-image img {
    text-align: center;
    display: inline;
    width: 7rem;
    height: 8rem;
  }
  .home-mid-section-aside-body {
    padding: 0;
    margin: 0 10px;
  }
}
@media screen and (max-width: 1043px) {
 .d-none-tap{
  display: none !important;
 }
}
