.modal-container-card {
    display: flex;
}
.modal-header-content {
    font-size: 25px;
    margin-left: 2rem;
}
.modal-header-content span:first-child {
   margin-right: 2rem;
}
@media (max-width: 1000px) {
    .modal-container-card {
        display: block;
    }
}