.property-manager-card {
    width: 50% !important;
}
.property-manager-header {
    margin: 1rem 5rem;
}
@media (max-width: 1100px){
    .property-manager-card {
        width: 70% !important;
    }
}
@media (max-width: 991px){
    .property-manager-card {
        position:  relative !important;
        top: 3rem !important;
    }
    .property-manager-header {
        margin: 1rem 2rem;
    }
}
@media (max-width: 600px){
    .property-manager-card {
        width: 90% !important;
    }
    .property-manager-header {
        margin: 1rem 0rem;
    }
}