/* The switch - the box around the slider */
.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}
/* for edit button */
.pencil {
  
  margin-top: -255%;
  margin-left: 63%;
  height: 33px;
}

.mx{
  max-height: 65px;
}
.h11{
margin-top: 12px;
font-size: 20px;
}
.btttn{
  margin-top:"12px";
  margin-bottom:"19px";
  }
/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

a:hover {
  opacity: 0.9;
}

input:checked + .slider {
  background-color: #2196f3;
}

input:focus + .slider {
  box-shadow: 0 0 1px #2196f3;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

.selectable-button:hover {
  transition: 0.2s;
}
.selectable-button:hover {
  transform: translate(0, -10px);
  opacity: 0.9;
}

/* The switch - the box around the slider */
.switch {
  position: relative;
  display: inline-block;
  width: 100%;
  height: 50px;
}

/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 10px 0;
  background-color: #d8dee3;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}

.slider:before {
  /* position: absolute; */
  text-align: center;
  /* padding-top: 6px; */
  top: -4px;
  height: 10px;
  width: 100%;
  left: 0px;
  bottom: 0px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  /* content: "Not Completed"; */
}

input:checked + .slider {
  background-color: rgb(149 169 167);
}

input:focus + .slider {
  box-shadow: 0 0 1px rgb(98, 89, 202);
}

input:checked + .slider:before {
  -webkit-transform: translateY(44px);
  -ms-transform: translateY(44px);
  transform: translateY(44px);
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  /* content: "In Progess"; */
}

.application-dashboard-container {
  max-width: 50%;
}
.all-card-style {
  padding: 1.7rem;
  border-radius: 7px;

  background: #017697;
  /* opacity: 0.5;
  
  filter: blur(10px); */
}

.all-card-style > div span {
  text-decoration: underline rgb(181 237 253);
  padding-left: 2rem !important;
  color: white;
  font-weight: 400;
  text-transform: capitalize;
}
.all-card-style > div span,
.all-card-style > div a {
  /* border: 1px solid red; */
  font-size: 1.4rem;
  padding: 0.5rem 0;
}
.all-card-style > div a {
  /* box-shadow: 4px 4px 0px 0px #b5d7ef, -4px -4px 0px 0px #e8e9eb; */
  cursor: pointer;
  transition: 0.3s all ease;
  border-radius: 3px;

  /* color: white !important; */
  font-weight: 300;
}
.all-card-style > div a:hover {
  /* box-shadow:  0 0px 3px 22px #e4e0e0; */
  /* box-shadow: -4px -4px 0px 0px #b5d7ef, 4px 4px 0px 0px #e8e9eb; */
  color: white !important;
  background-color: #282b33 !important;
  cursor: pointer;
}
.application-dashboard-last-div {
  margin-bottom: 2rem !important;
}
@media (max-width: 1000px) {
  .application-dashboard-container {
    display: block;
  }
}

@media (min-width: 768px) and (max-width: 1000px) {
  .all-card-style {
    padding: 1rem;
  }
  .all-card-style > div span,
  .all-card-style > div a {
    font-size: 1.1rem;
  }
  .user-dashboard-landlord-insurance {
    padding: 1rem !important;
    font-size: 1.1rem !important;
  }
  .user-dashboard-building-insurance {
    padding: 1rem !important;
    font-size: 1.1rem !important;
  }
}
@media (min-width: 300px) and (max-width: 767px) {
  .application-dashboard-container {
    max-width: 90% !important;
    margin-left: 0px !important;
    margin-right: 0px !important;
  }
}
@media (min-width: 300px) and (max-width: 499px) {
  .all-card-style {
    padding: 0.8rem;
  }
  .all-card-style > div span,
  .all-card-style > div a {
    font-size: 0.9rem;
  }
  .user-dashboard-landlord-insurance {
    padding: 0.8rem !important;
    font-size: 0.9rem !important;
  }
  .user-dashboard-building-insurance {
    padding: 0.8rem !important;
    font-size: 0.9rem !important;
  }
}
