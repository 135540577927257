.need-help {
    height: max-content;
    background-image: url("../../assets/images/background/background-need-help.jpeg");
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
  }
  .need-help-container {
    background-image: linear-gradient(
      to right,
      hsl(0, 1%, 69%, 0.9),
      hsl(0, 0%, 100%, 0)
    );
    padding: 5rem 0rem 7rem 5rem;
  }
  .need-help-heading {
    padding: 1rem 1rem 0rem 1rem;
  }
  .need-help-container h6 {
    font-size: 17px;
    color: #008eb5;
  }
  .need-help-container h3 {
    font-size: 50px;
    font-weight: 900;
  }
  .need-help-container form {
    width: 50%;
  }
  .need-help-form-group {
    display: flex;
  }
  .need-help-form-group .form-label {
    font-size: 18px;
    display: block;
    color: white;
  }
  .need-help-form-group input,
  .need-help-form-group textarea {
    font-size: 18px;
    padding: 30px 10px;
  }
  .need-help-form-group input {
    /* width: 100%; */
  }
  .need-help-form-list {
    margin: 0.5rem 0.5rem;
    width: 100%;
  }
  .need-help-btn {
    margin: 1rem 0.5rem;
    font-size: 17px !important;
  }
  
  @media (max-width: 1200px) {
    .need-help-container form {
      width: 60%;
    }
  }
  @media (max-width: 780px) {
    .need-help-container form {
      width: 80%;
    }
    .need-help-container {
      padding: 5rem 0rem 7rem 0rem;
    }
  }
  @media (min-width: 300px) and (max-width: 500px) {
    .need-help-container form {
      width: 100%;
    }
    .need-help-form-group {
      display: block;
      padding: 0rem 0.5rem;
    }
    .need-help-form-list {
      width: 100%;
      margin: 0;
    }
  }
  