.faq-main-heading {
  color: var(--blueButton);
  text-align: center;
  font-size: 3rem;
}
.faq-second-main-heading {
  color: var(--blueButton);
  text-align: center;
}
.faq-body {
  padding: 5rem 4rem;
}
.faq-bold-heading {
  font-weight: bold;
  font-size: 1.4rem;
  text-align: center;
  margin: 2rem 0;
}
.qaBody {
  margin-bottom: 1.7rem;
}
.qaBody ul {
  margin-left: 3rem;
}
.qaBody ul li {
  margin: 0.5rem 0;
}
.faq-question,
.faq-answer {
  font-size: 1.3rem;
  margin-bottom: 0.5rem;
}
.faq-question span {
  color: var(--blueButton);
  padding: 0 0.5rem;
  border-left: 2px solid var(--blueButton);
}
.faq-answer > span {
  padding: 0 0.5rem;
  border-left: 2px solid black;
}

@media (min-width: 300px) and (max-width: 500px) {
  .faq-main-heading {
    margin-top: 3rem;
  }
  .faq-body {
    padding: 5rem 0rem;
  }
}
