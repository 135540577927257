
object, embed, video {
  border: 0 none;
  max-width: 100%;
}

/* ================== Desktop Main Menu CSS ================== */

.horizontalMenucontainer {
  background-size: cover;
  overflow: hidden;
  background-attachment: fixed;
  background-position: 50% 0;
  background-repeat: no-repeat;
}

.horizontal-main {
  width: 100%;
  position: relative;
}

.horizontal-mainwrapper {
  margin: 0 auto;
}

.desktoplogo {
  padding: 0.75rem;
  margin: 0;
  float: left;

  img {
    width: 100%;
  }
}

/* Desktop Search Bar */

.horizontalMenu-list > li.rightmenu:after {
  content: "";
  display: table;
}

/*= Desktop Half Menu CSS =*/

/*= Desktop HTML Form Menu CSS =*/

/* ================== Desktop Extra CSS ================== */

.horizontal-header {
  display: none;
}

.horizontal-overlapbg {
  opacity: 0;
  visibility: hidden;
}

.hometext {
  display: none;
}

/*==============================================================================
                              Start Mobile CSS
===============================================================================*/

/* ================== Mobile Menu Change Brake Point ================== */

@media only screen and (max-width: 991px) {
  /* ================== Mobile Base CSS ================== */

  body.active {
    overflow: hidden;
  }

  /* ================== Mobile Main Menu CSS ================== */

  .desktoplogo {
    display: none;
  }

  .horizontalMenucontainer {
    background-attachment: local;
    background-position: 33% 0%;
  }

  /* ================== Mobile Slide Down Links CSS ================== */

  /* ================== Mobile Header CSS ================== */

  /* Mobile Search Bar*/

  .callusbtn {
    color: #a9a9a9;
    font-size: 21px;
    position: absolute;
    right: 5px;
    top: 0px;
    transition: all 0.4s ease-in-out 0s;
    z-index: 102;
    padding: 11px 14px;

    .fa {
      vertical-align: top;
      margin-top: 4px;
    }

    &:hover .fa {
      color: #a9a9a9;
    }
  }

  /* Mobile Toggle Menu icon (X ICON) */

  .animated-arrow {
    position: absolute;
    left: 0;
    top: 0;
    z-index: 9;
    -webkit-transition: all 0.4s ease-in-out;
    -moz-transition: all 0.4s ease-in-out;
    -o-transition: all 0.4s ease-in-out;
    -ms-transition: all 0.4s ease-in-out;
    transition: all 0.4s ease-in-out;
    cursor: pointer;
    padding: 16px 35px 16px 0px;
    margin: 11px 0 0 15px;

    span {
      cursor: pointer;
      height: 2px;
      width: 17px;
      background: #76839a;
      position: absolute;
      display: block;
      content: '';

      &:before, &:after {
        cursor: pointer;
        height: 2px;
        width: 17px;
        background: #76839a;
        position: absolute;
        display: block;
        content: '';
      }

      &:before {
        top: -7px;
        width: 23px;
      }

      &:after {
        bottom: -7px;
        width: 17px;
      }

      transition: all 500ms ease-in-out;

      &:before, &:after {
        transition: all 500ms ease-in-out;
      }
    }
  }

  .active .animated-arrow {
    span {
      &:after {
        width: 23px;
        top: -3px;
      }

      background-color: transparent;

      &:before {
        top: -3px;
      }
    }

    &.active span:after {
      top: -3px;
    }

    span {
      &:before {
        transform: rotate(45deg);
        -moz-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
        -o-transform: rotate(45deg);
        -webkit-transform: rotate(45deg);
        bottom: 0px;
      }

      &:after {
        transform: rotate(-45deg);
        -moz-transform: rotate(-45deg);
        -ms-transform: rotate(-45deg);
        -o-transform: rotate(-45deg);
        -webkit-transform: rotate(-45deg);
      }
    }
  }

  /* ================== Mobile Overlay/Drawer CSS ================== */

  .horizontal-overlapbg {
    right: 0;
    width: calc(100% - 240px);
    height: 100vh;
    min-height: 100%;
    position: fixed;
    top: 0;
    opacity: 0;
    visibility: hidden;
    background-color: rgba(0, 0, 0, 0.45);
    cursor: pointer;
  }

  .horizontalMenucontainer {
    -webkit-transition: all 0.25s ease-in-out;
    -moz-transition: all 0.25s ease-in-out;
    -o-transition: all 0.25s ease-in-out;
    -ms-transition: all 0.25s ease-in-out;
    transition: all 0.25s ease-in-out;
  }

  .active {
    .horizontalMenucontainer {
      margin-left: 240px;
      -webkit-transition: all 0.25s ease-in-out;
      -moz-transition: all 0.25s ease-in-out;
      -o-transition: all 0.25s ease-in-out;
      -ms-transition: all 0.25s ease-in-out;
      transition: all 0.25s ease-in-out;
    }
  }

  /* ================== Mobile Sub Menu Expander Arrows  ================== */

  /*End Media Query*/
}

@media (min-width: 992px){
  .main-sidemenu .slide-left, .main-sidemenu .slide-right {
    background: #fff;
    border: 1px solid #e9edf4;
  }
  .main-sidemenu .slide-left {
    left: 23px;
  }
  .main-sidemenu .slide-left, .main-sidemenu .slide-right {
    position: absolute;
    top: 9px;
    padding: 6px;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1;
    cursor: pointer;
    border: 1px solid #e9edf4;
    border-radius: 50px;
  }
  .main-sidemenu .slide-right {
    right: 20px;
  }
  .main-sidemenu .slide-left svg, .main-sidemenu .slide-right svg {
    fill: #282f53;
  }
  .horizontal .horizontal-main.ps {
    overflow: visible !important;
  }
  .horizontal .app-sidebar {
    transition: none;
  }
  .horizontal .horizontal-main {
    position: relative;
    bottom: initial;
  }
  .horizontal .horizontal-main {
    z-index: 7 !important;
  }
  .horizontal-main {
    position: relative;
    margin: 0 auto;
  }
  .horizontal .side-header {
    display: none;
  }
  .horizontal .main-sidemenu {
    overflow: hidden;
  }
  .horizontal .main-sidemenu {
    margin-top: 0;
  }
  .horizontal .hor-header .container, .horizontal .horizontal-main .container, .horizontal .main-content.hor-content .container {
    max-width: 85% !important;
    padding: 0;
  }
  .horizontal .side-menu {
    display: flex;
    overflow: hidden;
    padding: 0;
  }
  .horizontal .app-sidebar.horizontal-main .side-menu .sub-category {
    display: none;
  }
  .horizontal .slide {
    margin: 0 3px;
  }
  .horizontal .side-menu .slide .side-menu__item.active {
    border-right: none;
  }
  .horizontal .side-menu>li>a.active {
    color: $primary-1;
  }
  .horizontal .side-menu>li>a {
    display: flex;
    text-decoration: none;
    position: relative;
    color: #7b8191;
    padding: 16px 16px !important;
    font-weight: 400;
  }
  .horizontal .app-sidebar__toggle {
    display: none;
  }
  .horizontal .logo-horizontal {
    display: block;
  }
  .horizontal .logo-horizontal .header-brand-img.light-logo1 {
    display: block;
  }
  .horizontal .logo-horizontal .header-brand-img.desktop-logo {
    display: none;
  }
  .horizontal .app-sidebar.horizontal-main .side-menu .side-badge {
    display: none;
  }
  .horizontal .side-menu__item.active {
    background: none;
    border-radius: 0;
    box-shadow: none;
  }
  .horizontal .side-menu__item.active .side-menu__icon {
    color: $primary-1 !important;
  }
  .horizontal .side-menu__item.active .side-menu__label {
    color: $primary-1 !important;
  }
  .horizontal .main-sidemenu i.angle {
    position: relative;
    top: 2px;
    right: -7px;
    transform: rotate( 90deg);
    font-size: 15px;
  }
  .horizontal .horizontal-main .slide .slide-menu, .horizontal .horizontal-main .slide .sub-slide-menu, .horizontal .horizontal-main .slide .sub-slide-menu2 {
    width: 190px;
    position: absolute;
    background-color: #fff;
    z-index: 9999;
    border-radius: 5px;
    padding: 5px;
    min-width: fit-content;
    box-shadow: 0 10px 15px 0 rgb(104 113 123 / 30%) !important;
    border: 1px solid #e7eaf3;
  }
  .horizontal{
    .slide-menu li .slide-item:before {
      content: '\e92f';
      margin-right: 5px;
      position: relative;
      font-family: 'feather' !important;
      color: $black;
      font-size: 12px;
    }
    .sub-slide .sub-slide-menu {
      position: absolute;
      background-color: #fff;
      z-index: 9999;
      box-shadow: 5px 5px 5px #b9b9b9;
      border-radius: 5px;
      padding: 0px;
      min-width: fit-content;
      border: 1px solid #f0f0f8;
      left: 180px;
      top: 13px;
    }
    .sub-slide .sub-slide-menu2 {
      position: absolute;
      background-color: #fff;
      z-index: 9999;
      box-shadow: 5px 5px 5px #b9b9b9;
      border-radius: 5px;
      padding: 0px;
      min-width: fit-content;
      border: 1px solid #f0f0f8;
      left: 180px;
      top: 13px;
    }
    .sub-slide-menu .sub-slide-item2 {
      padding: 8px 20px 8px 20px !important;
    }
    .sub-angle2 {
      margin-right: 11px;
    }
    .slide-menu a:before {
      content: "\e92f";
      margin-right: 5px;
      position: relative;
      font-family: "feather" !important;
      color: #000;
      font-size: 12px;
    }
    &.horizontal-hover .slide:hover ul.slide-menu {
      display: block !important;
    }
    &.horizontal-hover .sub-slide:hover .sub-slide-menu {
      display: block !important;
    }
    &.horizontal-hover .sub-slide2:hover .sub-slide-menu2 {
      display: block !important;
    }
  }
  .responsive-logo {
    display: none;
  }
}
.app-sidebar.horizontal-main {
  padding-top: 0px;
}
.logo-horizontal {
  display: none;
}

@media (max-width: 991.98px){
  .header-brand-img {
    margin-left: 1.5rem;
    margin: 0px auto;
  }
  .horizontal .app-sidebar.horizontal-main {
    left: -300px;
  }
  .horizontal .app-sidebar {
    width: 270px;
  }
}

@media (max-width:991px){
  .horizontal{
    .header.hor-header {
      position: fixed;
      border-bottom: 1px solid #e9edf4;
      width: 100%;
      z-index: 999;
      top: 0;
    }
  }
  .horizontal.app .main-sidemenu .slide-left, .horizontal.app .main-sidemenu .slide-right {
    display: none !important;
  }
}

@media(min-width:992px){
  .horizontal .main-sidemenu .slide.is-expanded i.angle {
    transform: rotate(-90deg);
    position: relative;
    top: 0px;
    font-size: 15px;
  }
  .horizontal .header.fixed-header.hor-header {
    position: relative;
  }
  .horizontal .fixed-header {
    position: fixed;
  }
  .horizontal.scrollable-layout .horizontal-main {
    position: relative;
  }
  .horizontal{
    .side-menu__item:hover, .side-menu__item:focus {
      border-radius: 0;
    }
    .side-menu__item{
      border-radius: 0;
    }
    .side-menu__item.active:hover, .side-menu__item.active:focus {
      border-radius: 0 ;
      background: #f9fbfd !important;
      box-shadow: none;
    }
    .side-menu__item.active:hover, .side-menu__item.active:focus {
      text-decoration: none;
      background: none !important;
      border-radius: 0;
      box-shadow: none;
    }
    .side-menu__item.active:hover .side-menu__icon {
      color: $primary-1 !important;
    }
    .hor-rightangle{
      display: block !important;
    }
    .slide-menu {
      margin-left: 0px;
    }
  }
}
.scrollable-layout .app-sidebar {
  position: absolute;
}
.horizontal .leftmenu-styles {
  display: none;
}
