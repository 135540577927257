.blogPageImage {
  background-image: linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.3)),
    url("https://images.ctfassets.net/o2gysnvd04sg/2un1kwi7kgGAAkYUMaWs6a/ddf8a8e25e81ab1b933dd800cf86b47d/australian-family-house.jpg");
  min-height: 50vh;
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  padding-left: 6rem !important;
  display: flex;
  align-items: center;
  object-fit: cover;
}
.blogPageImage > span {
  border-left: 2px solid var(--cream);
  font-size: 3rem;
  padding-left: 1rem !important;
  font-weight: 700;
  color: white;
}

.blog-page {
  background-color: hsl(0, 0%, 100%, 0.7);
  padding-bottom: 3rem;
}
.blog-page {
  background-color: white !important;
}

.blog-heading {
  text-align: center;
  padding: 30px 0 0px 0;
}

.blog-heading h6 {
  color: #008eb5;
  font-size: 17px;
}

.blog-heading h2 {
  padding-bottom: 10px;
  font-size: 50px;
  font-weight: 900;
}

.blog-page .card-blog {
  /* margin: 2rem 5.5rem; */
  /* padding: 20px; */
  height: 32rem;
  padding-bottom: 2px;
  background-color: hsl(210, 6%, 94%, 0.8);
  /* border-radius: 5px; */
}
.blog-page-single-card {
  border-radius: 7px !important;
  cursor: pointer;
}
.blog-page .card-blog-title {
  text-align: center;
  color: rgb(66, 66, 66);
  font-weight: 400;
  padding: 10px;
  margin-top: 12px;
  word-wrap: break-word;
}
.blog-img-section {
  margin: auto;
  text-align: center;
  height: 20rem;
  width: 100%;
}
.blog-img-section img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.blog-page-single-card {
  width: 100%;
  height: 36rem;
}
.blog-page .card-blog-information {
  margin: 2px;
  padding: 10px;
  margin-top: 7px;
  text-align: justify;
  height: 11rem;
  font-size: 1rem;
  font-weight: 400;
  overflow-y: scroll;
}
.blog-page-main-card {
  margin: 0 5.9rem;
}
.blog-page-card {
  display: grid;
  overflow: hidden;
  grid-template-columns: 33% 33% 33%;
  gap: 1rem;
}
.how-to-image {
  margin: 1rem 1rem 1rem 0;
}
.how-to-image,
.how-to-about {
  width: 50%;
}
.how-to-image img {
  height: 100%;
  object-fit: cover;
}
.how-to-about {
  padding-top: 2rem;
  padding-bottom: 1rem;
}
.how-to-about h2 {
  font-size: 2.3rem;
}

.how-to-about > p {
  font-size: 1.1rem;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  /* max-height: 5.2rem; */
  /* margin-bottom: -0.5rem; */
}
.how-to-about button {
  transition: 0.3s all ease;
  border: 1px solid var(--blueButton);
  padding: 1rem 2rem;
  font-size: 1.2rem;
  color: var(--blueButton);
}
.how-to-about button:hover {
  background: var(--blueButton);
  color: white;
}
.how-to-image img {
  border-radius: 8px;
  width: 100%;
  object-fit: cover;
}
.card-blog-description {
  overflow: hidden !important;
  display: -webkit-box;

  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  max-height:6rem;
  word-wrap: break-word;
}
.card-blog-title {
  /* overflow: hidden; */
  /* display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  max-height: 3.2rem;
  word-wrap: break-word; */
}
.blog-page-how-to-buy {
  display: flex;
  margin-bottom: 1rem;
  height: 30rem;
}

.card-blog {
  height: 100%;
  overflow: hidden;
  border-radius: 8px;
}
@media (max-width: 1308px) {
  .how-to-about {
    padding-top: 1rem;
  }
  .how-to-about h2 {
    font-size: 2.3rem;
  }
}
@media (max-width: 950px) {
  .blog-page-card {
    display: grid;
    grid-template-columns: 50% 50%;
    gap: 1rem;
  }
  .blog-page-how-to-buy {
    display: block;
    height: 40rem;
  }
  .how-to-image,
  .how-to-about {
    width: 100%;
    text-align: justify;
  }
  .how-to-image {
    height: 60%;
    margin: 0;
  }
  .how-to-image img {
    height: 100%;
  }
  .blogPageImage {
    padding: 2rem 0rem !important;
  }
  .blogPageImage > span {
    padding-top: 1rem;
  }
  .blog-page-how-to-buy {
    margin-bottom: 2rem;
  }
  .blog-page-how-to-buy {
    margin-bottom: 4rem;
  }
}
@media (max-width: 650px) {
  .blog-page-card {
    display: block;
  }
  .blog-page-single-card {
    margin-bottom: 1rem;
  }
  .blog-page-main-card {
    margin: 0 2.9rem;
  }

  .blog-page-how-to-buy {
    height: 45rem !important;
  }
  .how-to-about h2 {
    font-size: 2rem;
  }
}
@media (max-width: 450px) {
  .blog-page-main-card {
    margin: 0 1.1rem;
  }
  .blog-page-how-to-buy {
    height: 40rem !important;
  }
  .how-to-about h2 {
    font-size: 1.8rem;
  }
}


.pagination{
  padding-top: 2px;
  list-style: none;
  display: flex;
  justify-content: center !important;
  align-items: center !important;
  margin-bottom: 2rem;
  font-size: 1.2rem;
  gap: 8px;
}
.pagination .page-num{
  padding:1px 11px;
  cursor: pointer;
  border-radius: 3px;
  
  

}
.page-num{
  text-decoration: none;
}
.pagination .page-num:hover{
  background-color: #715345;
  color:#ffffff;
  border-radius: 50%;
}
.pagination .active{
  background-color:  #fcf4f1;
  border-radius: 50%;
}
.react-pagination{
  background-color: #dfcbc2;
  width: fit-content;
  bottom: 0;
  /* height: 5px; */
  margin: 0 auto 0 auto;
  border-radius: 15px;
}





.afor{
  text-decoration: none;
    color: #000;
    margin-right: 30px;
    display: block;

}


.afor:hover{
  
    color: #000;
}
tbody, td, tfoot, th, thead, tr {
  border-color: inherit;
  border-style: solid;
  border: 1px solid black !important;
  border-width: 1px !important;
  padding: 3px 6px !important;
 margin: auto;
}
