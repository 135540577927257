.howToBuyBlogPageAfterImage {
  background-image: linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.3)),
    url("https://lendicdn.net/5ff3bc30676fbee66f26d0de4e94c126/71ccb7a35a452ea8153b6d920f9f190e/images/Hero-desktop.jpg");
  min-height: 50vh;
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  padding-left: 6rem !important;
  display: flex;
  align-items: center;
}
.howToBuyBlogPageAfterImage > span {
  border-left: 2px solid var(--cream);
  font-size: 3rem;
  padding-left: 1rem !important;
  font-weight: 700;
  color: white;
}

.howToBuy-main-blog-after {
  display: flex;
  justify-content: center;
  align-items: center;
}
.howToBuy-blog-after {
  padding: 2rem 0;
  width: 60%;
  height: 100%;
}
.howToBuy-blog-after-title {
  font-size: 2rem;
  word-wrap: break-word;
  margin-bottom: 2rem;
  line-height: 1;
}
.howToBuy-blog-after-image {
  width: 100%;
  height: 40rem;
  border-radius: 8px;
  overflow: hidden;
}
.howToBuy-blog-after-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: top;
}
.howToBuy-blog-after-description {
  word-wrap: break-word;
  font-size: 1.2rem;
  font-weight: 400;
  margin-top: 2rem;
  text-align: justify;
}
.howTobuy-points-heading {
  display: flex;
}
.howTobuy-points-heading > span {
  margin-right: 1rem;
}
.howToBuy-key-points li::before {
  content: "\2022";
  color: black;
  font-weight: bold;
  display: inline-block;
  width: 1em;
  margin-left: 1em;
}
@media (max-width: 1268px) {
  .howToBuy-main-blog-after {
    display: block;
    padding: 0 2rem;
  }
  .howToBuy-blog-after {
    width: 100%;
    height: 100%;
  }
}
@media (min-width: 300px) and (max-width: 500px) {
  .howToBuy-blog-after {
    padding: 4rem 0;
    width: 100%;
    height: 100%;
  }
}
