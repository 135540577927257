.contact-us {
  height: max-content;
  background-image: url(https://themes.hibootstrap.com/levix/wp-content/uploads/2022/02/let-contact-bg-2.jpg);
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
}
.contact-us-container {
  background-image: linear-gradient(
    to right,
    hsl(0, 1%, 69%, 0.7),
    hsl(0, 0%, 100%, 0)
  );
  padding: 5rem 0rem 7rem 5rem;
}
.contact-us-heading {
  padding: 1rem 1rem 0rem 1rem;
}
.contact-us-container h6 {
  font-size: 17px;
  color: #008eb5;
}
.contact-us-container h3 {
  font-size: 50px;
  font-weight: 900;
}
.contact-us-container form {
  width: 50%;
}
.contact-us-form-group {
  display: flex;
}
.contact-us-form-group .form-label {
  font-size: 18px;
  display: block;
  color: white;
}
.contact-us-form-group input,
.contact-us-form-group textarea {
  font-size: 18px;
  padding: 30px 10px;
}
.contact-us-form-group input {
  width: 100%;
}
.contact-us-form-list {
  margin: 0.5rem 0.5rem;
  width: 100%;
}
.contact-us-btn {
  margin: 1rem 0.5rem;
  font-size: 17px !important;
}

@media (max-width: 1200px) {
  .contact-us-container form {
    width: 60%;
  }
}
@media (max-width: 780px) {
  .contact-us-container form {
    width: 80%;
  }
  .contact-us-container {
    padding: 5rem 0rem 7rem 0rem;
  }
}
@media (min-width: 300px) and (max-width: 500px) {
  .contact-us-container form {
    width: 100%;
  }
  .contact-us-form-group {
    display: block;
    padding: 0rem 0.5rem;
  }
  .contact-us-form-list {
    width: 100%;
    margin: 0;
  }
}
